define('viewmodels/pages/privacy-page',['require','lodash','viewmodels/mixins/static-page','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var StaticPageMixin = require('viewmodels/mixins/static-page');
    var BaseViewModel = require('viewmodels/base');

    var PrivacyPageViewModel = function() {
        var self = this;
        _.extend(self, new StaticPageMixin());
    };

    PrivacyPageViewModel.prototype = new BaseViewModel();
    return new PrivacyPageViewModel();
});

