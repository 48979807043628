define('viewmodels/dialogs/landing-feedback-dialog',['require','knockout','services/state-container','viewmodels/base','plugins/dialog'],function(require) {
    var ko = require('knockout');
    var state = require('services/state-container');
    var BaseViewModel = require('viewmodels/base');
    var dialog = require('plugins/dialog');

    var LandingFeedbackDialogViewModel = function() {
        var self = this;
        self.context = ko.observable();

        self.password = ko.observable(state.user().oldPassword());
        self.go = function() {
            dialog.close(this);
        };

        self.activate = function(context) {
            self.context(context);
        };
    };

    LandingFeedbackDialogViewModel.prototype = new BaseViewModel();
    return LandingFeedbackDialogViewModel;
});

