define('viewmodels/components/filter-options',['require','knockout','jquery','lodash','durandal/app','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var _ = require('lodash');
    var app = require('durandal/app');
    var BaseViewModel = require('viewmodels/base');

    var FilterOptionsViewModel = function(config) {
        var self = this;

        self.filterOptions = _.map(config.options, function(option, key) {
            return {
                key: key,
                name: option.name,
                cssIcon: 'fa fa-2x fa-' + option.icon
            };
        });
        self.shouldShow = config.shouldShow;
        self.cssMenu = config.isOnLeftSide ? 'left-filter-options' : 'right-filter-options';
        self.currentFilterName = ko.observable(config.options[config.defaultOption].name);

        self.isOpen = ko.observable(false);

        self.close = function() {
            self.isOpen(false);
        };

        self.toggle = function() {
            self.isOpen(!self.isOpen());
            app.trigger('modal:open', self);
        };

        var modalOpenSubscription = app.on('modal:open').then(function(vm) {
            if (vm !== self) self.close();
        });

        var setFilter = function(filterKey) {
            self.isOpen(false);
            app.trigger(config.name + ':filter', filterKey);
            self.currentFilterName(config.options[filterKey].name);
        };

        self.selectOption = function(option) {
            setFilter(option.key);
        };

        self.deactivate = function() {
            modalOpenSubscription.off();
        };
    };

    FilterOptionsViewModel.prototype = new BaseViewModel();
    return FilterOptionsViewModel;
});

