define('viewmodels/components/lead-details',['require','jquery','lodash','knockout','toastr','plugins/router','durandal/app','plugins/dialog','services/state-container','utils/formatting','models/offer','services/customer','services/vendor','viewmodels/components/lead-form-data','models/woop','text!settings.json','viewmodels/base','viewmodels/mixins/busy'],function(require) {
    var $ = require('jquery');
    var _ = require('lodash');
    var ko = require('knockout');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var dialog = require('plugins/dialog');
    var state = require('services/state-container');
    var formatting = require('utils/formatting');
    var Offer = require('models/offer');
    var customerService = require('services/customer');
    var vendorService = require('services/vendor');
    var LeadFormDataViewModel = require('viewmodels/components/lead-form-data');
    var Woop = require('models/woop');

    var settings = JSON.parse(require('text!settings.json'));
    var BaseViewModel = require('viewmodels/base');

    var BusyMixin = require('viewmodels/mixins/busy');

    var LeadDetailsViewModel = function(pattern, match) {
        var self = this;
        _.assign(self, new BusyMixin());
        self.lead = match;
        self.formData = ko.observable(new LeadFormDataViewModel(self.lead, true, false, true));
        self.showFormData = !!self.lead.formData();

        self.checkedFields = ko.observable([]);
        if (self.showFormData) {
            self.checkedFields(self.formData().export().filter(function(x) {
                return x.type === 'header' || x.value
            }));
        }

        self.offer = ko.validatedObservable(Offer.fromLead(self.lead));
        self.offer().price(formatting.priceValueString(self.offer().price()));
        var matchingKeywords = match.getMatchingKeywords(pattern);

        // Lead
        self.name = self.lead.name;
        self.leadText = ko.pureComputed(function() {
            return formatting.highlightKeywords(self.lead.keywords(), matchingKeywords, { open: '<em>', close: '</em>' });
        });
        self.numberOfMatchingKeywords = ko.observable(matchingKeywords.length);
        self.matchingPercentage = ko.observable(match.matchingPercentage());
        self.matchingPercentageStr = ko.pureComputed(function() {
            return formatting.percentage(self.matchingPercentage());
        });
        self.emailAddressAvailable = ko.observable(true);
        self.phoneNumberAvailable = ko.observable(match.phoneNumberAvailable());

        // Offer
        self.images = ko.observableArray([]);
        self.canAddImage = ko.pureComputed(function() {
            return self.images().length < 4;
        });
        self.isReplyVisible = ko.observable(false);
        self.getPicNumber = function() {
            var usedNumbers = _.map(self.images(), function(image) {
                return _.parseInt(image.picNumber);
            });
            var freeNumbers = _.difference([1, 2, 3, 4], usedNumbers);
            return _.first(freeNumbers);
        };
        self.showReply = function() {
            ga('send', 'event', 'Offer', 'Open reply field', self.lead.id());
            self.isReplyVisible(true);
            vendorService.initOfferPicUpload('offer-pics-dropzone', 'add-offer-pic', self.getPicNumber, function(err, result) {
                if (err) return toastr.error(err, 'Fout');
                self.images.push(result);
            });
        };
        self.removeImage = function(image) {
            self.images.remove(image);
        };


        // Credits
        self.creditsRemaining = state.user().vendor().credits;
        self.addCredits = function() {
            ga('send', 'event', 'User', 'Open purchase credits window', 'Lead details');
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/purchase-credits-dialog', {leadId: match.id(), patternId: pattern.id()});
        };
        self.canMakeOffer = ko.pureComputed(function() {
            return self.creditsRemaining() > 0;
        });
        self.canSendOffer = ko.pureComputed(function() {
            return !self.isBusy();
        });

        self.makeOffer = function() {
            self.offer().isValidating(true);
            if (self.offer.isValid()) {
                ga('send', 'event', 'Offer', 'Make offer', self.lead.id());
                state.user().vendor().purchaseOffer();

                // Create a final offer DTO by merging the offer DTO with an object specifying which of the image slots (1,2,3,4) are used.
                self.isBusy(true);
                var imagesDTO = _.reduce(self.images(), function(result, image) {
                    result['pic' + image.picNumber] = true;
                    return result;
                }, { pic1: false, pic2: false, pic3: false, pic4: false });
                var dto = _.merge(self.offer().toDTO(), imagesDTO);

                vendorService.addOffer(dto, function(err, result) {
                    self.isBusy(false);
                    if (err) {
                        state.user().vendor().undoPurchaseOffer();
                        return toastr.error(err, 'Fout');
                    }
                    toastr.success('Je aanbod is geplaatst', 'Opgeslagen');
                    router.navigate('dashboard');
                });
            }
            else {
                toastr.error('Niet alle velden zijn juist ingevuld', 'Fout');
            }
     	};
    };

    LeadDetailsViewModel.prototype = new BaseViewModel();
    return LeadDetailsViewModel;
});

