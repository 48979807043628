define('viewmodels/pages/profile-page',['require','knockout','lodash','toastr','plugins/router','durandal/app','utils/formatting','services/user','services/customer','services/vendor','services/state-container','viewmodels/components/offers-overview','viewmodels/pages/not-supported-mobile-page','viewmodels/mixins/profile-pic','viewmodels/mixins/busy','models/user','text!settings.json','plugins/dialog','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var formatting = require('utils/formatting');
    var userService = require('services/user');
    var customerService = require('services/customer');
    var vendorService = require('services/vendor');
    var state = require('services/state-container');
    var OffersOverviewViewModel = require('viewmodels/components/offers-overview');
    var NotSupportedMobilePageViewModel = require('viewmodels/pages/not-supported-mobile-page');
    var ProfilePicMixin = require('viewmodels/mixins/profile-pic');
    var BusyMixin = require('viewmodels/mixins/busy');
    var User = require('models/user');
    var settings = JSON.parse(require('text!settings.json'));
    var dialog = require('plugins/dialog');
    var BaseViewModel = require('viewmodels/base');

    var TransactionViewModel = function(transaction) {
        var self = this;
        _.assign(self, transaction);
        self.timeString = formatting.time(self.timestamp());
        self.dateString = formatting.plainDate(self.timestamp());
        self.priceString = formatting.price(self.price());
        self.statusString =
            (self.status() === 'open'     ? 'Verwerken...' :
            (self.status() === 'complete' ? 'Betaald' :
            (self.status() === 'aborted'  ? 'Mislukt' :
            '')));
    };

    TransactionViewModel.create = function(transaction) {
        return new TransactionViewModel(transaction);
    };

    var ProfilePageViewModel = function() {

        var self = this;
        _.assign(self, new BusyMixin());
        _.assign(self, new ProfilePicMixin());
        self.notSupportedMobile = new NotSupportedMobilePageViewModel();

        self.user = ko.validatedObservable(User.clone(state.user()));
        self.isPasswordFormVisible = ko.observable(state.user().isNew());

        self.userUpgradedSubscription = app.on('user:upgraded').then(function() {
            self.user().fromUser(state.user());
        });

        self.showPasswordForm = function() {
            self.isPasswordFormVisible(true);
        };

        self.showOldPassword = ko.computed(function() {
            return !state.isSaunaSession();
        });

        self.isEmailAddressChanged = ko.pureComputed(function() {
            return self.user().emailAddress() !== state.user().emailAddress();
        });

        self.phoneNumber = self.user().phoneNumber;
        self.mode = state.shellMode;
        self.isProfileCompleted = ko.pureComputed(function() {
            return state.user().profileCompletion() >= 1.0;
        });
        self.profileCompletionString = ko.pureComputed(function() {
            return formatting.percentageFromFraction(state.user().profileCompletion());
        });

        self.finishProfile = function() {
            var profileSection = document.getElementById('profile-section');
            profileSection.scrollIntoView();
        };

        self.showAllOffers = function() {
            router.navigate('offers');
        };

        self.updatePhoneNumber = function() {
            self.user().isValidating(true);
            if (self.user().phoneNumber.isValid()) {
                self.isBusy(true);
                customerService.updatePhoneNumber(self.phoneNumber(), function(err, result) {
                    self.isBusy(false);
                    if (err) return toastr.error(err, 'Fout');

                    self.user(User.clone(state.user()));
                    self.user().isValidating(true);
                    ga('send', 'event', 'User', 'Save phone number');
                    toastr.success('Telefoonnummer is aangepast', 'Opgeslagen');
                });
            }
            else {
                toastr.error('Er is een ongeldig telefoonnummer ingevuld', 'Fout');
            }
        };

        self.signIn = function() {
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/sign-in-dialog');
        };

        self.savePassword = function() {
            var isNew = state.user().isNew();
            var passwordValidation = state.user().validatePassword(self.user().oldPassword, self.user().password, self.user().repeatPassword);
            if (passwordValidation.isOk) {
                self.isBusy(true);
                userService.updatePassword(self.user().oldPassword(), self.user().password(), function(err, result) {
                    self.isBusy(false);
                    if (err) return toastr.error(err, 'Fout');
                    state.isSaunaSession(false);

                    if (isNew) ga('send', 'event', 'New user', 'Save password');
                    self.user().fromUser(state.user());
                    self.isPasswordFormVisible(false);
                    toastr.success('Je wachtwoord is opgeslagen', 'Opgeslagen');
                });
            }
            else {
                toastr.error(passwordValidation.errorMessage, 'Fout');
            }
        };

        self.saveCustomerProfile = function() {

            var saveOk = function() {
                self.isBusy(true);
                customerService.updateProfile(self.user().toDTO(), function(err, result) {
                    self.isBusy(false);
                    if (err) return toastr.error(err, 'Fout');

                    ga('send', 'event', 'User', 'Save customer profile');
                    state.user().fromUser(self.user());
                    toastr.success('Je profiel is nu bijgewerkt', 'Opgeslagen');
                });
            };

            self.user().isValidating(true);
            if (self.user.isValid()) {
                if (self.isEmailAddressChanged()) {
                    var dialogOptions = {
                        title: 'E-mail veranderen',
                        text: 'Weet je zeker dat je je e-mailadres wilt veranderen?',
                        confirmButton: 'Veranderen',
                        cancelButton: 'Annuleren',
                        hasCancelButton: true,
                        callback: saveOk
                    };
                    dialog.getContext().blockoutOpacity = 0.66;
                    dialog.show('viewmodels/dialogs/confirmation-dialog', dialogOptions);
                }
                else {
                    saveOk();
                }
            }
            else {
                toastr.error('Niet alle velden zijn juist ingevuld', 'Fout');
            }
        };

        self.saveVendorProfile = function() {
            self.user().vendor().isValidating(true);
            if (self.user().vendor.isValid()) {
                self.isBusy(true);
                vendorService.updateProfile(self.user().vendor().toDTO(), function(err, result) {
                    self.isBusy(false);
                    if (err) return toastr.error(err, 'Fout');

                    ga('send', 'event', 'User', 'Save vendor profile');
                    state.user().vendor().fromVendor(self.user().vendor());
                    toastr.success('Je bedrijfsprofiel is nu bijgewerkt', 'Opgeslagen');
                });
            }
            else {
                toastr.error('Niet alle velden zijn juist ingevuld', 'Fout');
            }
        };

        self.upgradeToVendor = function() {
            self.isBusy(true);
            userService.upgradeToVendor(function(err, result) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');

                ga('send', 'event', 'User', 'Upgrade to vendor', 'Blue button on profile page');
                self.user().upgradeToVendor();
                state.shellMode('vendor');
                toastr.success('Je bent nu ook een verkoper', 'Opgeslagen');
            });
        };

        self.hasOffers = ko.pureComputed(function() {
            if (self.mode() === 'customer') return state.offers().length > 0;
            if (self.mode() === 'vendor') return state.sentOffers().length > 0;
        });

        self.newWoop = function() {
            router.navigate('new-woop');
        };

        self.newPattern = function() {
            router.navigate('patterns');
        };

        self.keydown = function(sender, e) {
            if (e.keyCode === 13) self.updatePhoneNumber();
            return true;
        };

        self.offersOverview = new OffersOverviewViewModel();
        self.offersOverview.limit(settings.dashboardOffersLimit);

        self.stats = {
            searches: ko.observableArray([
                { key: 'Totaal', value: 1000 },
                { key: 'Huidig', value: 20 },
                { key: 'Zoekopdr:aanbieding', value:'1:2,2' },
                { key: 'Gearchiveerd', value: 740 },
            ]),
            offers: ko.observableArray([
                { key: 'Totaal', value: 1000 },
                { key: 'Huidig', value: 20 },
                { key: 'Sinds laatste login', value: 1339 },
                { key: 'Geaccepteerd', value: 1339 },
            ]),
            woopan: ko.observableArray([
                { key: 'Profiel volledig', value: '65%' },
                { key: 'Woop-niveau', value: 14 },
                { key: 'Achievements', value: 15 },
                { key: 'Profiel bekeken', value: '421x' },
            ])
        };

        self.attached = function() {
            customerService.initProfilePicUpload('profile-pic', 'add-profile-pic-button', function(err, result) {
                if (err) return toastr.error(err, 'Fout');
                app.trigger('customer:profile-pic-updated', result.uri);
                toastr.success('Profielfoto is bijgewerkt');
            });
        };

        self.activate = function() {
            state.shellMode('customer');
            if (!state.isLoggedIn()) self.signIn();
            self.transactions = ko.pureComputed(function() {
                if (self.mode() === 'customer') return [];
                if (!state.user().isVendor()) return [];

                var cutoffDate = new Date(new Date() - 1000 * 60 * 60 * settings.numberOfHoursToKeepOpenTransactions);
                var relevantTransactions = _.filter(state.user().vendor().transactions(), function(transaction) {
                    return transaction.timestamp() > cutoffDate;
                });
                return _.map(relevantTransactions, TransactionViewModel.create);
            });
            self.showPayment = ko.pureComputed(function() {
                return self.transactions().length > 0;
            });
            self.user().isValidating(false);
            state.selectedWoop(null);
            state.selectedPattern(null);
        };

        self.deactivate = function() {
            self.disposeProfilePicListener();
            self.userUpgradedSubscription.off();
        };

        self.compositionComplete = function() {
            setTimeout(function() { $('#email').focus(); }, 100);
        };
    };

    ProfilePageViewModel.prototype = new BaseViewModel();
    return ProfilePageViewModel;
});

