define('viewmodels/pages/not-found-page',['require','knockout','plugins/router','durandal/app','services/state-container','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var state = require('services/state-container');
    var BaseViewModel = require('viewmodels/base');

    var NotFoundPageViewModel = function() {
        var self = this;
        self.isFront = true;

        self.isLoggedIn = state.isLoggedIn;

        self.goHome = function() {
            router.navigate('/');
        };

        self.goToDashboard = function() {
            router.navigate('dashboard');
        };

        self.activate = function() {
            state.selectedWoop(null);
            state.selectedPattern(null);
        };
    };

    NotFoundPageViewModel.prototype = new BaseViewModel();
    return new NotFoundPageViewModel();
});

