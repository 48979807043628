define('viewmodels/dialogs/confirmation-dialog',['require','knockout','durandal/app','lodash','plugins/dialog','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var app = require('durandal/app');
    var _ = require('lodash');
    var dialog = require('plugins/dialog');
    var BaseViewModel = require('viewmodels/base');

    var ConfirmationDialog = function() {
        var self = this;
        this.autoclose = true;

        self.hasCancelDialog = ko.observable(true);

        self.activate = function(options) {
            _.assign(self, options);
        };

        self.confirm = function() {
            self.callback();
            dialog.close(self);
        };

        self.deactivate = function() {
            self.closeDialogSubscription.off();
        };

        self.close = function() {
            dialog.close(self);
        };

        self.closeDialogSubscription = app.on('dialogs:close').then(function() {
            dialog.close(self);
        });

        self.blockoutClass = 'brown';
    };

    ConfirmationDialog.prototype = new BaseViewModel();
    return ConfirmationDialog;
});

