define('utils/string',['require','lodash'],function(require) {
    var _ = require('lodash');
    
    var StringUtils = function() {
        var self = this;
        
        self.isEmpty = function(str) {
            if (_.isNull(str)) return true;
            if (_.isUndefined(str)) return true;
            if (!_.isString(str)) return true;
            if (str.trim() === '') return true;
            return false;
        };
    };
    
    return new StringUtils();
});
