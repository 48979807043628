define('models/bike-form',['require'],function(require) {

    var currentSection = null;

    const header = function(text) {
        currentSection = text;
        return { type: 'header', text: text };
    };

    const check = function(desc, info) {
        var res = { type: 'check', desc: desc, key: currentSection + '--' + desc, info: info || '' };
        return res;
    };

    return [
        [
            header('Merk'),
            check('Gazelle'),
            check('Batavus'),
            check('Sparta'),
            check('Cortina'),
            check('Koga'),
            check('Kalkhoff'),
            check('Pegasus'),
            check('Cube'),
            check('Babboe'),
            check('Stromer'),
            check('Union'),
            check('BSP'),
            check('Brompton'),
            check('Stella'),
            check('Onbelangrijk'),
            check('Adviseer mij'),

            header('Model'),
            check('Dames'),
            check('Heren'),
            check('Lage'),
            check('Unisex'),
            check('Onbelangrijk'),
            check('Adviseer mij'),            

            header('Motor locatie'),
            check('Midden'),
            check('Voorwiel'),
            check('Achterwiel'),
            check('Onbelangrijk'),
            check('Adviseer mij'),            

            header('Type elektrische fiets'),
            check('Elektrische stadsfiets'),
            check('Elektrische hybride'),
            check('Elektrische transportfiets'),
            check('Speed Pedelec'),
            check('Elektrische bakfiets'),
            check('Elektrische moederfiets'),
            check('Elektrische vouwfiets'),
            check('Adviseer mij'),            

            header('Lichaamslengte'),
            check('165 - 174 cm'),
            check('175 - 184 cm'),
            check('155 - 164 cm'),
            check('185 - 194 cm'),
            check('145 - 154 cm'),
            check('195 - 204 cm'),
            check('205 - 214 cm'),

            header('Aantal versnellingen'),
            check('Traploos'),
            check('2'),
            check('3'),
            check('5'),
            check('6'),
            check('7'),
            check('8'),
            check('9'),
            check('10'),
            check('11'),
            check('12'),
            check('Adviseer mij'),            

            header('Actieradius'),
            check('30 - 55 km'),
            check('30 - 90 km'),
            check('40 - 60 km'),
            check('40 - 110 km'),
            check('50 - 80 km'),
            check('50 - 150 km'),
            check('60 - 80 km'),
            check('60 - 180 km'),
            check('70 - 110 km'),
            check('80 - 150 km'),
            check('100 - 170 km'),
            check('Tot 70 km'),
            check('Meer dan 110 km'),
            check('375 km'),            
            check('Adviseer mij'),            
        ],
        [
            header('Motor techniek'),
            check('Bosch Active Plus'),
            check('Bosch Performance'),
            check('Bafang'),
            check('Bosch Active'),
            check('E-Motion'),
            check('Bosch Performance Gen3'),
            check('Shimano STEPS E6100'),
            check('Shimano STEPS E5000'),
            check('Bosch Performance CX Gen4'),
            check('Babboe GWA motor'),
            check('Bosch Performance CX'),
            check('Cortina SportDrive'),
            check('Dapu MD250S'),
            check('Yamaha'),
            check('Schimano STEPS E8000'),
            check('Stromer CYRO Drive II'),
            check('Stromer SYNO Drive II'),
            check('Bosch Performance Speed'),
            check('Shimano STEPS'),
            check('Stromer SYNO Sport'),
            check('Brompton borstelloze DC front motor'),
            check('E-Motion middenmotor'),
            check('Ecomo'),
            check('Stromer CYRO Drive IG'),
            check('Bosch Cargo Line Gen4'),
            check('Panasonic'),
            check('Adviseer mij'),

            header('Type rit'),
            check('Recreatief gebruik'),
            check('Dagelijks korte ritten'),
            check('Woon-werkverkeer'),
            check('Combinatie van bovenstaande'),
            
            header('Accu plaats'),
            check('Bagagedrager'),
            check('Frame'),
            check('Voordrager'),
            check('Adviseer mij'),

            header('Kleurgroep'),
            check('Zwart'),
            check('Grijs'),
            check('Blauw'),
            check('Groen'),
            check('Bruin'),
            check('Rood'),
            check('Wit'),
            check('Oranje'),
            check('Paars'),
            check('Beige'),
            check('Roze'),
            check('Geel'),
            check('Onbelangrijk'),

            header('Type aandrijving'),
            check('Ketting'),
            check('Belt drive'),
            check('Adviseer mij'),

            header('Prijsklasse'),
            check('€ 0 - € 699'),
            check('€ 700 - € 1249'),
            check('€ 1250 - € 1749'),
            check('€ 1750 - € 1999'),
            check('€ 2000 - € 2499'),
            check('€ 2500 - € 2999'),
            check('€ 3000 - € 3499'),
            check('€ 3500 - € 3900'),
            check('€ 4000 - € 4999'),
            check('€ 5000 - € 5999'),
            check('€ 6000 - € 7999'),
            check('€ 8000 - € 9999'),
        ]
    ];
});
