define('viewmodels/pages/landing-page',['require','knockout','lodash','plugins/router','durandal/app','plugins/dialog','toastr','plugins/http','utils/parsing','services/state-container','utils/string','services/customer','services/user','models/woop','utils/formatting','viewmodels/mixins/busy','viewmodels/components/panda','viewmodels/components/lead-form-data','viewmodels/components/match-options','viewmodels/components/benefits','text!settings.json','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var dialog = require('plugins/dialog');
    var toastr = require('toastr');
    var http = require('plugins/http');
    var parsing = require('utils/parsing');
    var state = require('services/state-container');
    var stringUtils = require('utils/string');
    var customerService = require('services/customer');
    var userService = require('services/user');
    var Woop = require('models/woop');
    var formatting = require('utils/formatting');
    var BusyMixin = require('viewmodels/mixins/busy');
    var PandaViewModel = require('viewmodels/components/panda');
    var LeadFormDataViewModel = require('viewmodels/components/lead-form-data');
    var MatchOptionsViewModel = require('viewmodels/components/match-options');
    var BenefitsViewModel = require('viewmodels/components/benefits');

    var settings = JSON.parse(require('text!settings.json'));
    var BaseViewModel = require('viewmodels/base');

    var LandingPageViewModel = function() {

        var self = this;
        _.assign(self, new BusyMixin());
        self.isFront = false;
        self.isResponsive = true;
        self.opensDialog = false;
        self.benefits = new BenefitsViewModel();
        self.benefitsMobile = new BenefitsViewModel(true);
        self.isSaving = ko.observable(false);
        
        self.showNav = ko.observable(true);
        self.showBuyForm = ko.observable(false);
        self.showSellForm = ko.observable(false);

        self.woop = ko.validatedObservable(new Woop());

        self.currentPage = ko.observable(1);

        self.targetKeyword = ko.observable(null);
        self.emailAddress = ko.observable(state.isLoggedIn() ? state.user().emailAddress() : '');
        self.name = ko.observable(state.isLoggedIn() ? state.user().firstName() + ' ' + state.user().lastName() : '');
        self.phoneNumber = ko.observable(state.isLoggedIn() ? state.user().phoneNumber() : '');
        self.maxPrice = ko.observable('');
        self.keywords = ko.observable();
        self.pattern = ko.observable();
        self.showWarning = ko.observable(false);

        const isMobile = window.matchMedia('(max-width: 800px)').matches;

        self.panda = new PandaViewModel(self.keywords, self.targetKeyword, false);
        self.formData = ko.observable(new LeadFormDataViewModel(null, isMobile, true));
        self.matchOptions = new MatchOptionsViewModel(self.woop());

        self.isShowingSignInDialog = ko.observable(false);
        self.isVisible = ko.pureComputed(function(){
            if (state.isLoggedIn()) return false;
            if (self.isShowingSignInDialog()) return false;
            return true;
        });

        self.moreInfo = function() {
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/reverse-ad-info-dialog');
        };

        self.postWoop = function() {

            self.showWarning(false);

            if (!self.canPostWoop()) {
                self.showWarning(true);
                return;
            }

            self.isSaving(true);
            var password = userService.generateRandomPassword();

            userService.registerCustomer(
                self.emailAddress(), 
                password, password, 
                self.keywords(), 
                self.targetKeyword(), 
                undefined, 
                formatting.priceValue(self.maxPrice()),
                function(err) {
                    if (err) {
                        self.isSaving(false);
                        return toastr.error(err);
                    }

                    if (self.phoneNumber() || self.name()) {
                        const profile = {
                            name: self.name(),
                            phone: self.phoneNumber()
                        };
                        customerService.updateProfile(profile, _.noop);
                    }

                    state.shellMode('customer');
                    router.navigate('profile');
                    
                    dialog.getContext().blockoutOpacity = 0.66;
                    dialog.show('viewmodels/dialogs/landing-feedback-dialog', 'customer');                 
                }
            );
        };

        self.canPostWoop = ko.pureComputed(function() {
            var isSaving = self.isSaving();
            var isEmpty = stringUtils.isEmpty(self.keywords());
            var hasEmail = !stringUtils.isEmpty(self.emailAddress());
            return !isSaving && !isEmpty && hasEmail;
        });

        self.postPattern = function() {

            self.showWarning(false);

            if (!self.canPostPattern()) {
                self.showWarning(true);
                return;
            }

            self.isSaving(true);
            var password = userService.generateRandomPassword();
            userService.registerVendor(
                self.emailAddress(), 
                password, password, 
                self.pattern(), 
                self.targetKeyword(), 
                function(err) {
                    if (err) {
                        self.isSaving(false);
                        return toastr.error(err);
                    }

                    if (self.phoneNumber() || self.name()) {
                        const profile = {
                            name: self.name(),
                            phone: self.phoneNumber()
                        };
                        customerService.updateProfile(profile, _.noop);                        
                    }

                    state.shellMode('customer');
                    router.navigate('profile');
                    
                    dialog.getContext().blockoutOpacity = 0.66;
                    dialog.show('viewmodels/dialogs/landing-feedback-dialog', 'vendor');                       

                }
            );
        };

        self.canPostPattern = ko.pureComputed(function() {
            var isSaving = self.isSaving();
            var isEmpty = stringUtils.isEmpty(self.pattern());
            var hasEmail = !stringUtils.isEmpty(self.emailAddress());
            return !isSaving && !isEmpty && hasEmail;
        });

        self.goToBuyForm = function() {
            self.showNav(false);
            self.showBuyForm(true);
        };
        
        self.goToSellForm = function() {
            self.showNav(false);
            self.showSellForm(true);
        };

        self.goToSauna = function() {
            window.open('/#sauna', '_blank');
        };

        self.goToBike = function() {
            window.open('/#elektrische-fiets', '_blank');
        };

        self.goToDormer = function() {
            window.open('/#dakkapel', '_blank');
        };

        self.goToAirco = function() {
            window.open('/#airco', '_blank');
        };

        // self.postNewWoopStep2 = function(keywords) {

        //     keywords = keywords || 'Sauna';

        //     ga('send', 'event', 'Sauna landing', 'click', 'Sauna form step 1 completed', keywords);

        //     self.keywords(keywords);
        //     state.woops([]);
        //     state.firstWoop.keywords(self.keywords());
        //     state.firstWoop.targetKeyword(self.targetKeyword());
        //     state.woops.push(Woop.createNew(self.keywords(), self.targetKeyword(), self.formData().serialized()));

        //     if (!stringUtils.isEmpty(self.keywords())) {
        //         var woop = { 
        //             keywords: self.keywords(), 
        //             targetKeyword: self.targetKeyword(), 
        //             formData: self.formData().serialized(),
        //             maximumPrice: formatting.priceValue(self.maxPrice()),
        //         };
        //         customerService.addWoop(woop, function(err, woopId) {
        //             if (err) return toastr.error(err);
        //             toastr.success('Nieuwe woop is geplaatst');

        //             if (self.phoneNumber()) {
        //                 customerService.updatePhoneNumber(self.phoneNumber(), _.noop);
        //             }

        //             state.shellMode('customer');
        //             router.navigate('profile');
                    
        //             dialog.getContext().blockoutOpacity = 0.66;
        //             dialog.show('viewmodels/dialogs/landing-feeback-dialog');

        //         });

        //         self.keywords(null);
        //     }
        // }

        self.activate = function() {
            state.shellMode('landing');

            // if (state.isLoggedIn()) {
            //     state.shellMode('customer');
            //     router.navigate('dashboard');   
            //     state.isSaunaSession(false);
            // }

            var context = router.activeInstruction();
            var params = context.params[1]
            if (params && params.src && params.access_token) {
                if (params.src === 'forgotpass') return self.forgotPassword(params.access_token);
            }
        };

    };

    LandingPageViewModel.prototype = new BaseViewModel();
    return LandingPageViewModel;
});

