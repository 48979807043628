define('viewmodels/base',['require','services/state-container','utils/config'],function(require) {
    var state = require('services/state-container');
    var config = require('utils/config');
    return function() {
        var self = this;
        self.cmsTexts = state.cmsTexts;
        self.isMobile = window.matchMedia(config.mobileMediaMatch).matches;
    };
});

