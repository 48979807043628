define('models/dormer-form',['require'],function(require) {

    var currentSection = null;

    const header = function(text) {
        currentSection = text;
        return { type: 'header', text: text };
    };

    const check = function(desc, info) {
        var res = { type: 'check', desc: desc, key: currentSection + '--' + desc, info: info || '' };
        return res;
    };

    return [
        [
            header('Dakkapel materiaal'),
            check('Vurenhout'),
            check('Douglas'),
            check('Hardhout'),
            check('Kunststof'),
            check('PVC'),
            check('HPL'),
            check('Aluminium'),
            check('Keralit'),
            check('Trespa'),
            check('Zink'),
            check('Staal '),
            check('Composietmaterialen'),
            check('Anders'),
            check('Adviseer mij'),

            header('Breedte'),
            check('Onbekend'),
            check('Minder dan 2 meter'),
            check('2-3 meter'),
            check('3-4 meter'),
            check('4-5 meter'),
            check('5-7 meter'),
            check('Groter dan 7 meter'),
            check('Anders'),

            header('Kleur'),
            check('Wit'),
            check('Zwart'),
            check('Bruin'),
            check('Grijs '),
            check('Beige'),
            check('Blauw'),
            check('Anders'),
            
            header('Budget'),
            check('€0 tot €3.000'),
            check('€3.000 tot €5.000'),
            check('€5.000 tot €7.000'),
            check('€7.000 tot €9.000'),
            check('€9.000 tot €12.000'),
            check('€12.000 tot €15.000'),
            check('Anders'),
            check('Adviseer mij'),

            header('Type dakkapel'),
            check('Platdak dakkapel'),
            check('Schilddakkapel'),
            check('Zijdakkapel'),
            check('Sandwichdakkapel'),
            check('Nokverhoging'),
            check('Dakkapel met balkon'),
            check('Inval dakkapel'),
            check('Anders'),
            check('Adviseer mij'),

            header('Esthetische voorkeur'),
            check('Modern'),
            check('Klassiek'),
            check('Landelijk'),
            check('Tradtitioneel'),
            check('Minimalistisch'),
            check('Kader'),
            check('Anders'),
            check('Adviseer mij'),

            header('Vergunningsvereisten'),
            check('Vergunning nodig'),
            check('Geen vergunning nodig'),
            check('Adviseer mij'),

            header('Isolatie behoefte'),
            check('Standaard isolatie'),
            check('Hoogwaardige isolatie '),
            check('Thermische isolatie (optioneel)'),
            check('PIR'),
            check('PUR'),
            check('EPS'),
            check('Glaswol'),
            check('Steenwol'),
            check('Akoestische isolatie'),
            check('Adviseer mij'),
            
            header('Tijdlijn voor uitvoering '),
            check('ZSM'),
            check('Binnen 2 maanden'),
            check('Binnen 4 maanden'),
            check('Later dan 4 maanden'),
        ],
        [
            header('Soort ramen '),
            check('Vaste ramen'),
            check('Draai-kiepramen'),
            check('Dakramen '),
            check('Verduisterende ramen'),
            check('Speciale vormen'),
            check('Schuiframen'),
            check('Ronde ramen '),
            check('Boogvormige ramen'),
            check('Anders'),
            check('Adviseer mij'),

            header('Soorten glas'),
            check('Enkel glas'),
            check('Dubbel glas'),
            check('Driedubbel glas'),
            check('Veiligheids of gelaagd glas'),

            header('Aantal ramen'),
            check('2+'),
            check('3+'),
            check('4+'),
            check('Anders'),

            header('Locatie dakkapel'),
            check('Voorkant '),
            check('Achterkant'),
            check('Zijkant '),
            check('Op een plat dak'),
            check('Boven garage of aanbouw '),

            header('Functies'),
            check('Brandwerend'),
            check('Ingebouwde verlichting'),
            check('Zonnepanelen'),
            check('Slimme raamopener'),
            check('Zonwering'),
            check('Dakkapel airco'),
            check('Anders'),
            check('Adviseer mij'),

            header('Balkon opties'),
            check('Ingebouwde railing'),
            check('Frans balkon'),
            check('Dakterras'),
            check('Verlichting op balkon'),
            check('Smart home integraties'),
            check('Ventilatie'),
            check('Anders'),
            check('Adviseer mij'),

            header('Hellingshoek'),
            check('Onbekend'),
            check('0-5 graden (plat dak)'),
            check('5-15 graden zacht hellend dak'),
            check('15-30 graden standaard hellend dak'),
            check('30-45 steil hellend dak'),
            check('Meer dan 45 graden (bijna verticaal)'),
            check('Anders'),

            header('Dakbedekking'),
            check('Bitumen'),
            check('EPDM (ethyleen propyleen dieen monomeer)'),
            check('Zink'),
            check('PVC'),
            check('Koper'),

            header('Dakpannen'),
            check('Dakpannen keramisch'),
            check('Dakpannen betton'),

            header('Leien dakbedekking'),
            check('Natuur leien'),
            check('Kunst leien'),
            check('Asfalt ahingles'),
            check('Tegeldak'),
            check('Pannendak'),
            check('Grindbedekking'),
            check('Anders'),
            check('Adviseer mij'),

            header('Afwerkingsdetails'),
            check('Sierlijsten'),
            check('Rozetten'),
            check('Anders'),
            check('Adviseer mij'),
        ]
    ];
});
