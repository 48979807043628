define('models/vendor',['require','knockout','lodash','utils/parsing','models/validation'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var parsing = require('utils/parsing');
    var validation = require('models/validation');
    
    var Vendor = function() {
        var self = this;

        self.isValidating = ko.observable(false);
        
        self.id = ko.observable();
        self.companyName = ko.observable();
        //self.emailAddress = ko.observable();
        self.emailAddress = ko.observable().extend(validation.requiredEmailAddress(self.isValidating));
        self.postalCode = ko.observable();
        self.houseNumber = ko.observable();
        self.website = ko.observable();
        self.phoneNumber = ko.observable().extend(validation.phoneNumber(self.isValidating));
        self.description = ko.observable();
        self.credits = ko.observable(10);
        self.transactions = ko.observableArray([]);

        self.purchaseOffer = function() {
            self.credits(self.credits() - 1);
        };
        self.undoPurchaseOffer = function() {
            self.credits(self.credits() + 1);
        };
        
        self.toDTO = ko.pureComputed(function() {
            return {
                companyName: self.companyName(),
                emailAddress: self.emailAddress(),
                postalCode: self.postalCode(),
                houseNumber: self.houseNumber(),
                website: self.website(),
                phoneNumber: self.phoneNumber(),
                description: self.description()
            };
        });
        
        self.fromDTO = function(dto) {
            self.id(dto.id);
            self.companyName(dto.companyName);
            self.emailAddress(dto.emailAddress);
            self.postalCode(dto.postalCode);
            self.houseNumber(dto.houseNumber);
            self.website(dto.website);
            self.phoneNumber(dto.phoneNumber);
            self.description(dto.description);                                                                                                
        };
        
        self.fromVendor = function(vendor) {
            self.id(vendor.id());
            self.companyName(vendor.companyName());
            self.emailAddress(vendor.emailAddress());
            self.postalCode(vendor.postalCode());
            self.houseNumber(vendor.houseNumber());
            self.website(vendor.website());
            self.phoneNumber(vendor.phoneNumber());
            self.description(vendor.description());
            self.credits(vendor.credits());
            self.transactions(vendor.transactions());
        };
        
    };
    
    Vendor.fromDTO = function(dto) {
        var vendor = new Vendor();
        vendor.fromDTO(dto);
        return vendor;
    };
    
    Vendor.clone = function(vendor) {
        var clonedVendor = new Vendor();
        clonedVendor.fromVendor(vendor);
        return clonedVendor;
    };
    
    return Vendor;
});
