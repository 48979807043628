define('viewmodels/pages/what-is-woopan-page',['require','lodash','plugins/dialog','viewmodels/mixins/static-page','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var dialog = require('plugins/dialog');
    var StaticPageMixin = require('viewmodels/mixins/static-page');
    var BaseViewModel = require('viewmodels/base');

    var WhatIsWhoopanPageViewModel = function() {
        var self = this;
        _.extend(self, new StaticPageMixin());

        self.vendorSignup = function() {
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/vendor-sign-up-dialog');
        };

    };

    WhatIsWhoopanPageViewModel.prototype = new BaseViewModel();
    return new WhatIsWhoopanPageViewModel();
});

