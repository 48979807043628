define('viewmodels/pages/edit-woop-page',['require','lodash','knockout','async','plugins/router','durandal/app','toastr','services/state-container','utils/string','services/customer','viewmodels/components/match-options','viewmodels/components/lead-form-data','viewmodels/components/offers-overview','viewmodels/pages/not-supported-mobile-page','models/woop','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var ko = require('knockout');
    var async = require('async');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var toastr = require('toastr');
    var state = require('services/state-container');
    var stringUtils = require('utils/string');
    var customerService = require('services/customer');
    var MatchOptionsViewModel = require('viewmodels/components/match-options');
    var LeadFormDataViewModel = require('viewmodels/components/lead-form-data');
    var OffersOverviewViewModel = require('viewmodels/components/offers-overview');
    var NotSupportedMobilePageViewModel = require('viewmodels/pages/not-supported-mobile-page');
    var Woop = require('models/woop');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var EditWoopPageViewModel = function(id) {
        var self = this;
        _.assign(self, new BusyMixin());
        self.notSupportedMobile = new NotSupportedMobilePageViewModel();

        self.finishProfile = function() {
            router.navigate('profile');
        };

        self.markOffersRead = function(woop) {
            self.isBusy(true);
            customerService.markOffersForWoopRead(woop, function(err, callback) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
            });
        };


        self.save = function() {
            self.woop().isValidating(true);
            if (self.showFormData) {
                self.woop().formData(self.formData().serialized());
            }
            if (self.woop.isValid()) {
                if (self.woop().maxPrice() === '') self.woop().maxPrice(null);
                self.isBusy(true);
                async.series([
                    function(callback) {
                        if (!self.matchOptions().showPhoneNumber() || self.matchOptions().phoneNumber() === state.user().phoneNumber()) return callback(null);
                        customerService.updatePhoneNumber(self.matchOptions().phoneNumber(), callback);
                    },
                    function(callback) {
                        customerService.updateWoop(self.woop().id(), self.woop().toDTO(), callback);
                    }
                ], function(err, results) {
                    self.isBusy(false);
                    if (err) return toastr.error(err);
                    state.selectedWoop().fromWoop(self.woop());
                    toastr.success('Woop is bijgewerkt');
                    router.navigate('dashboard');
                });
            }
            else {
                toastr.error('Niet alle velden zijn juist ingevuld', 'Fout');
            }
        };

        self.showFinishProfileButton = state.introductionMode;

        self.canSave = ko.pureComputed(function() {
            return !stringUtils.isEmpty(self.woop().keywords());
        });

        self.activate = function(woopId) {
            var woop = state.findWoopById(woopId);
            state.selectedWoop(woop);
            state.shellMode('customer');

            self.woop = ko.validatedObservable(Woop.clone(woop));

            self.offersOverview = new OffersOverviewViewModel(null, self.woop());

            self.matchOptions = ko.observable(new MatchOptionsViewModel(self.woop()));
            self.formData = ko.observable(new LeadFormDataViewModel(self.woop(), false, false));
            self.showFormData = !!self.woop().formData();

            self.keywords = self.woop().keywords;
            if (woop) self.markOffersRead(woop);
        };

        self.deactivate = function() {
            state.introductionMode(false);
        };

        self.compositionComplete = function() {
            $('#applicationHost').css('overflow-y', 'hidden');
        };
    };

    EditWoopPageViewModel.prototype = new BaseViewModel();
    return EditWoopPageViewModel;
});

