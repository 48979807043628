define('models/airco-form',['require'],function(require) {

    var currentSection = null;

    const header = function(text) {
        currentSection = text;
        return { type: 'header', text: text };
    };

    const check = function(desc, info) {
        var res = { type: 'check', desc: desc, key: currentSection + '--' + desc, info: info || '' };
        return res;
    };

    return [
        [
            header('Type airco'),
            check('Mobiele airco '),
            check('Split airco'),
            check('Multi split airco'),
            check('Raam airco'),
            check('Plafondairco'),
            check('Zuilmodel airco'),
            check('Vloermodel airco'),
            check('Wandmodel airco'),
            check('Inverter airco'),
            check('Warmtepomp airco'),
            check('Ducted airco'),
            check('VRF/VRV airco'),
            check('Hybride airco'),
            check('Cassete airco'),
            check('Console airco'),
            check('Kanaal airco'),
            check('Watergekoelde airco'),
            check('Weggewerkte kanaal modellen'),
            check('Anders'),
            check('Adviseer mij'),

            header('Merk'),
            check('Daikan'),
            check('Mitsubishi electric'),
            check('Mitsubishi heavy modellen'),
            check('Panasonic'),
            check('LG'),
            check('Samsung'),
            check('Toshiba'),
            check('Hitachi'),
            check('Fujitsu'),
            check('Hisense'),
            check('Carrier'),
            check('Sharp'),
            check('Trane'),
            check('Rinnai'),
            check('Gree'),
            check('Midea'),
            check('Whirlpool '),
            check('Sylvania'),
            check('Vortice'),
            check('Stiebel Eltron'),
            check('AquaVent'),
            check('Koldfront'),
            check('Dimplex'),
            check('Kompakt'),
            check('Climastar'),
            check('Neff'),
            check('Lennox '),
            check('Zenair '),
            check('Senville'),
            check('Kigili'),
            check('Aermac'),
            check('Clivet'),
            check('Aqua Air'),
            check('Bard'),
            check('Stulz'),
            check('Frigidaire'),
            check('Dometic'),
            check('Soleus Air'),
            check('Sanyo'),
            check('Breezair'),
            check('Goodman'),
            check('Emerson'),
            check('Fujitsu general'),
            check('Karcher'),
            check('Trotec'),
            check('Anders'),
            check('Adviseer mij'),

            header('Capaciteit vermogen in BTU'),
            check('7.000-9.000 BTU tot 25m2'),
            check('9.000-12.000 BTU 25-40m2'),
            check('12.000-18.000 BTU 40-60m2'),
            check('18.0000-24.000 BTU 60-80m2'),
            check('24.000-30.000 BTU 80-100 m2'),
            check('30.000-36.000 BTU 100-120m2'),
            check('Anders'),
            check('Adviseer mij'),

            header('Energieklasse '),
            check('A+++'),
            check('A++ '),
            check('A+ '),
            check('A '),
            check('B'),
            check('C'),
            check('Adviseer mij'),

   
            header('Functies'),
            check('Koelen'),
            check('Verwarmen'),
            check('Ontvochtigen'),
            check('Ventileren'),
            check('Luchtfilteren'),
            check('Ionisatie'),
            check('Slimme bediening'),
            check('Timerfunctie'),
            check('Stille werking'),
            check('Bewegingssensoren '),
            check('Luchtstroomregeling'),
            check('Anders'),
            check('Adviseer mij'),
        ],
        [  
            header('Kleur'),
            check('Wit'),
            check('Zwart'),
            check('Grijs/zilver'),
            check('Zuilmodellen '),
            check('Anders'),
 
            header('Geluidsniveau'),
            check('19-25 DB(A)'),
            check('25-30 DB(A)'),
            check('30-40 DB(A)'),
            check('40-50 DB(A)'),
            check('Adviseer mij'),

            header('Prijsklasse'),
            check('200-700'),
            check('700/1.500'),
            check('1.500-2.000'),
            check('2.000-2,500'),
            check('2.500-3.500'),
            check('3.500+'),
            check('Adviseer mij'),
       
            header('Bediening'),
            check('Afstandsbediening'),
            check('Smartphone bediening'),
            check('Spraakbesturing'),
            check('Handmatige bediening'),
            check('Adviseer mij'),

            header('Luchtstroom regeling'),
            check('Automatische lamellen'),
            check('Handmatige lamellen'),
            check('3-D luchtstroom'),
            check('Adviseer mij'),

            header('Gebruikscatogorie'),
            check('Thuis'),
            check('Kantoor'),
            check('Winkel'),
            check('Horeca'),
            check('Onbelangrijk'),
            check('Adviseer mij'),

            header('Particulier of zakelijk'),
            check('particulier'),
            check('zakelijk'),

            header('Oppervlakte ruimte in M3'),
            check('Vul in'),
            check('Onbekend'),

            header('In welke ruimte komt de airco'),
            check('Woonkamer'),
            check('Keuken'),
            check('Slaapkamer'),
            check('Gang'),
            check('Anders'),

            header('Op welke verdieping word de airco geplaats'),
            check('Kelder'),
            check('Begane grond'),
            check('1e verdieping'),
            check('2e verdieping'),
            check('3e verdieping '),
            check('Hoger'),
            check('Adviseer mij'),

            header('Waar moet de externe aparatuur geplaatst worden'),
            check('Dak'),
            check('Balkon'),
            check('Buitenmuur'),
            check('Adviseer mij'),

            header('Wanneer wilt u dat de klus uitgevoerd moet worden'),
            check('Spoed'),
            check('Binnen een maand'),
            check('Binnen 2 maanden'),
            check('Binnen een half jaar'),
            check('Zelf installaren'),

            header('In hoeveel ruimtes moet aircondtionning komen?'),
            check('1'),
            check('2'),
            check('3 of meer'),
        ]
    ];
});
