define('viewmodels/mixins/busy',['require','knockout','durandal/app'],function(require) {
    var ko = require('knockout');
    var app = require('durandal/app');
    
    var BusyMixin = function() {
        var self = this;
        
        self.isBusy = ko.observable(false);
        
        self.busyListener = self.isBusy.subscribe(function(isBusy) {
            if (isBusy) app.trigger('spinner:show');
            else app.trigger('spinner:hide'); 
        });
        
        self.disposeBusyListener = function() {
            self.busyListener.dispose();
        };
    };

    return BusyMixin;
});
