define('viewmodels/pages/blocked-page',['require','lodash','viewmodels/mixins/static-page','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var StaticPageMixin = require('viewmodels/mixins/static-page');
    var BaseViewModel = require('viewmodels/base');

    var BlockedPageViewModel = function() {
        var self = this;
        _.extend(self, new StaticPageMixin());
    };

    BlockedPageViewModel.prototype = new BaseViewModel();
    return BlockedPageViewModel;
});

