define('viewmodels/pages/offers-page',['require','knockout','toastr','plugins/router','durandal/app','services/state-container','services/customer','viewmodels/components/offers-overview','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var state = require('services/state-container');
    var customerService = require('services/customer');
    var OffersOverviewViewModel = require('viewmodels/components/offers-overview');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var OffersPageViewModel = function() {
        var self = this;
        _.assign(self, new BusyMixin());

        self.isGrey = true;
        self.searchTerm = ko.observable('');

        self.markOffersRead = function(woop) {
            self.isBusy(true);
            customerService.markOffersForWoopRead(woop, function(err, callback) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
            });
        };

        self.activate = function(woopId) {
            var woop = null;
            if (woopId !== null) woop = state.findWoopById(woopId);
            // if (woop && woop.isArchived()) // activate archive filter
            self.mode = state.shellMode;
            state.selectedWoop(woop);
            self.offersOverview = new OffersOverviewViewModel(self.searchTerm, woop);
            if (woop) self.markOffersRead(woop);
        };

        self.compositionComplete = function() {
            $('#applicationHost').css('overflow-y', 'hidden');
        };

    };

    OffersPageViewModel.prototype = new BaseViewModel();
    return new OffersPageViewModel();
});

