define('viewmodels/components/patterns-overview',['require','jquery','lodash','knockout','toastr','plugins/router','durandal/app','plugins/dialog','services/state-container','utils/formatting','services/vendor','text!settings.json','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var $ = require('jquery');
    var _ = require('lodash');
    var ko = require('knockout');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var dialog = require('plugins/dialog');
    var state = require('services/state-container');
    var formatting = require('utils/formatting');
    var vendorService = require('services/vendor');
    var settings = JSON.parse(require('text!settings.json'));
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var filterSorts = {
        'most-recent': {
            sort: function(pattern) { return -pattern.date(); },
            filter: function(pattern) { return !pattern.isArchived(); },
        },
        'most-matches': {
            sort: function(pattern) { return -pattern.matches.length; },
            filter: function(pattern) { return !pattern.isArchived(); },
        },
        'archived': {
            sort: function(pattern) { return -pattern.date(); },
            filter: function(pattern) { return pattern.isArchived(); },
        },
    };

    var PatternsOverviewItemViewModel = function(pattern, isDefault) {
        var self = this;
        _.assign(self, pattern);
        self.source = pattern;
        self.dateString = formatting.date(pattern.date());
        self.matchingPercentageString = formatting.percentage(pattern.matchingPercentage());
        self.matchesString = ko.pureComputed(function() {
            if (!self.areMatchesLoaded()) return '…';
            return self.matches().length;
        });
        self.matchesLabel = ko.pureComputed(function() {
            if (!self.areMatchesLoaded()) return '&nbsp;&nbsp;';
            if (self.matches().length === 0) return 'verfijn';
            return self.matches().length;
        });
        self.isSelected = ko.pureComputed(function() {
            return state.selectedPattern() === pattern;
        });
        self.isDefault = ko.pureComputed(function() {
            return isDefault && !state.selectedPattern();
        });
        self.isViewing = ko.pureComputed(function() {
           return router.activeItem().__moduleId__ === 'viewmodels/pages/matches-page';
        });
        self.isEditing = ko.pureComputed(function() {
           return self.isSelected() &&  router.activeItem().__moduleId__ === 'viewmodels/pages/pattern-page';
        });
    };

    PatternsOverviewItemViewModel.create = function(pattern, index) {
        return new PatternsOverviewItemViewModel(pattern, index === 0);
    };

    var testPatternAgainstSearchTerm = function(searchTerm, pattern) {
        if (!searchTerm || searchTerm.length < settings.searchTermMinLength) return true;
        if (pattern.name().toLowerCase().indexOf(searchTerm) > -1) return true;
        if (pattern.keywords().toLowerCase().indexOf(searchTerm) > -1) return true;
        return false;
    };

    var PatternsOverviewViewModel = function() {
        var self = this;
        _.assign(self, new BusyMixin());

        self.filterSort = ko.observable(filterSorts[settings.defaultPatternFilter]);
        self.searchTerm = ko.observable('');

        self.patterns = ko.pureComputed(function() {
            var searchTermFilter = _.partial(testPatternAgainstSearchTerm, self.searchTerm().toLowerCase());
            return _.chain(state.patterns())
                .filter(searchTermFilter)
                .filter(self.filterSort().filter)
                .sortBy(self.filterSort().sort)
                .map(PatternsOverviewItemViewModel.create)
                .value();
        });

        self.selectPattern = function(pattern) {
            state.selectedPattern(pattern.source);
            router.navigate('patterns/' + pattern.id() + '/matches');
        };

        self.editPattern = function(pattern) {
            state.selectedPattern(pattern.source);
            router.navigate('patterns/' + pattern.id());
        };

        self.archivePattern = function(pattern) {
            var dialogOptions = {
                title: 'Aanbieding archiveren',
                text: 'Weet je zeker dat je deze aanbieding wil archiveren? Je kan de actie altijd ongedaan maken.',
                confirmButton: 'Archiveren',
                cancelButton: 'Annuleren',
                hasCancelButton: true,
                callback: function() {
                    self.isBusy(true);
                    vendorService.archivePattern(pattern.id(), function(err) {
                        self.isBusy(false);
                        if (err) return toastr.error(err, 'Fout');
                        toastr.success('Aanbieding gearchiveerd');
                    });
                }
            };
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/confirmation-dialog', dialogOptions);
        };

        self.restorePattern = function(woop) {
            self.isBusy(true);
            vendorService.restorePattern(woop.id(), function(err) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
                toastr.success('Aanbieding hersteld');
            });
        };

        self.patternsFilterSubscription = app.on('patterns:filter').then(function(tag) {
            self.filterSort(filterSorts[tag]);
        });

        self.deactivate = function() {
            self.patternsFilterSubscription.off();
        };

        self.fadeIn = function(element, index, data) {
            $(element).filter('li').css('opacity', '1');
        };
    };

    PatternsOverviewViewModel.prototype = new BaseViewModel();
    return new PatternsOverviewViewModel();
});

