define('viewmodels/pages/search-page',['require','knockout','lodash','plugins/router','durandal/app','plugins/dialog','toastr','plugins/http','utils/parsing','services/state-container','utils/string','services/customer','services/user','models/woop','viewmodels/mixins/busy','viewmodels/components/panda','text!settings.json','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var dialog = require('plugins/dialog');
    var toastr = require('toastr');
    var http = require('plugins/http');
    var parsing = require('utils/parsing');
    var state = require('services/state-container');
    var stringUtils = require('utils/string');
    var customerService = require('services/customer');
    var userService = require('services/user');
    var Woop = require('models/woop');
    var BusyMixin = require('viewmodels/mixins/busy');
    var PandaViewModel = require('viewmodels/components/panda');
    var settings = JSON.parse(require('text!settings.json'));
    var BaseViewModel = require('viewmodels/base');

    var SearchPageViewModel = function() {
        console.log('SearchPageViewModel');
        var self = this;
        _.assign(self, new BusyMixin());
        self.isFront = true;
        self.opensDialog = false;

        self.targetKeyword = ko.observable(null);
        self.keywords = ko.observable();
        self.panda = new PandaViewModel(self.keywords, self.targetKeyword);

        self.userWantsToInteractWithSearchBox = ko.observable(false);
        self.isPlaceHolderVisible = ko.pureComputed(function() {
            return !self.userWantsToInteractWithSearchBox() && stringUtils.isEmpty(self.keywords());
        });

        self.placeHolder = ko.pureComputed(function() {
            var keyword = self.targetKeyword() || 'default';
            var placeHolders = self.cmsTexts['search']['placeholders'] || settings.placeHolders;
            if (!_.has(placeHolders, keyword)) keyword = 'default';
            return placeHolders[keyword];
        });

        self.isGoButtonEnabled = ko.pureComputed(function() {
            return !stringUtils.isEmpty(self.keywords());
        });

        self.selectSearchBox = function() {
            self.userWantsToInteractWithSearchBox(true);
            self.panda.animatePandaHead();
            $('#woop-box').focus();
        };

        self.deselectSearchBox = function() {
            self.userWantsToInteractWithSearchBox(false);
        };

        var addWoop = function(keywords, targetKeyword, isMobile) {
            self.isBusy(true);
            customerService.addWoop({ keywords: keywords, targetKeyword: targetKeyword }, function(err, woopId) {
                self.isBusy(false);
                if (err) return toastr.error(err);
                toastr.success('Nieuwe woop is geplaatst');
                /*if (!isMobile) {
                  router.navigate('edit-woop/' + woopId);
                }*/
            });
        };

        var showEmailDialog = function(keywords, targetKeyword) {
            ga('send', 'event', 'New user', 'Post woop', keywords);
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/email-dialog', { keywords: keywords, targetKeyword: targetKeyword });
        };


        self.go = function() {
            if (state.isLoggedIn()) addWoop(self.keywords(), self.targetKeyword());
            else {
                state.firstWoop.keywords(self.keywords());
                state.firstWoop.targetKeyword(self.targetKeyword());
                state.woops.push(Woop.createNew(self.keywords(), self.targetKeyword()));
                state.user().isUnregistered(true);
                router.navigate('welcome');
            }
        };

        self.goMobile = function() {
          if (state.isLoggedIn()) addWoop(self.keywords(), self.targetKeyword(), true);
          else showEmailDialog(self.keywords(), self.targetKeyword());
          self.keywords("");
        };

        self.activate = function(context) {
            if (context && 'access_token' in context && 'src' in context) {
                //if (context.src === 'vendor') return self.verifyVendorAccount(context.access_token);
                if (context.src === 'forgotpass') return self.forgotPassword(context.access_token);
            }
            else if (context && 'src' in context) {
                if (context.src === 'newpass') return self.askForNewPassword();
            }
            else if (context && 'login' in context) return self.needsToLogin();

            if (context && 'q' in context) {
                self.targetKeyword(context.q);
            }

            state.selectedWoop(null);
            state.selectedPattern(null);
        };

        self.deactivate = function () {
            app.trigger('dialogs:close');
            clearInterval(self.messageTimer);
        };

        self.compositionComplete = function() {
            if (!self.opensDialog) setTimeout(function() { $('#woop-box').focus(); }, 200);
        };

        /*
        self.verifyVendorAccount = function(accessToken) {
            self.isBusy(true);
            userService.verifyVendor(accessToken, function(err) {
                self.isBusy(false);
                if (err) return toastr.error(err);
                toastr.success('Je account is goedgekeurd');

                dialog.getContext().blockoutOpacity = 0.66;
                dialog.show('viewmodels/dialogs/sign-in-dialog', { fromVendorVerification: true });
            });
        };
        */

        self.forgotPassword = function(accessToken) {
            userService.useForgotPasswordSession(accessToken);
            self.opensDialog = true;
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/new-password-dialog');
        };

        self.askForNewPassword = function() {
            self.opensDialog = true;
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/forgot-password-dialog');
        };

        self.needsToLogin = function() {
            self.opensDialog = true;
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/sign-in-dialog');
        };
    };

    SearchPageViewModel.prototype = new BaseViewModel();
    return SearchPageViewModel;
});

