define('viewmodels/dialogs/new-password-dialog',['require','knockout','jquery','durandal/app','plugins/router','plugins/dialog','toastr','utils/string','services/user','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var app = require('durandal/app');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var toastr = require('toastr');
    var stringUtils = require('utils/string');
    var userService = require('services/user');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var NewPasswordDialog = function() {
        var self = this;
        self.autoclose = false;
        if (self.isMobile) self.autoclose = true;
        _.assign(self, new BusyMixin());

        self.password = ko.observable();
        self.repeatPassword = ko.observable();
        self.isDone = ko.observable(false);

        self.go = function() {
            self.isBusy(true);
            userService.createNewPassword(self.password(), self.repeatPassword(), function(err, result) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');

                self.isDone(true);
                ga('send', 'event', 'User', 'Save new password');
                toastr.success('Je wachtwoord is opgeslagen', 'Opgeslagen');
            });
        };

        self.login = function() {
            dialog.close(this);
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/sign-in-dialog');
        };

        self.keydown = function(sender, e) {
            if (e.keyCode === 27 && self.isMobile) self.close();
            if (e.keyCode === 13 && self.isGoButtonEnabled()) self.go();
            return true;
        };

        self.isGoButtonEnabled = ko.pureComputed(function() {
            if (self.isBusy()) return false;
            if (stringUtils.isEmpty(self.password())) return false;
            if (stringUtils.isEmpty(self.repeatPassword())) return false;
            return true;
        });

        self.compositionComplete = function() {
            setTimeout(function() { $('#password').focus(); }, 100);
        };

        self.closeDialogSubscription = app.on('dialogs:close').then(function() {
            dialog.close(self);
        });

        self.blockoutClass = 'brown';
    };

    NewPasswordDialog.prototype = new BaseViewModel();
    return NewPasswordDialog;
});

