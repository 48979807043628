define('models/pattern',['require','knockout','lodash','utils/parsing','utils/formatting','models/validation','text!settings.json'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var parsing = require('utils/parsing');
    var formatting = require('utils/formatting');
    var validation = require('models/validation');
    var settings = JSON.parse(require('text!settings.json'));

    var Pattern = function() {
        var self = this;
        
        self.isValidating = ko.observable(false);
        
        self.id = ko.observable();
        self.isArchived = ko.observable(false);
        self.name = ko.observable().extend(validation.required(self.isValidating));
        self.keywords = ko.observable().extend(validation.required(self.isValidating));
        self.matchingPercentage = ko.observable(settings.defaultMatchingPercentage).extend(validation.requiredPercentage(self.isValidating));
        self.date = ko.observable();
        self.maxPrice = ko.observable().extend(validation.price(self.isValidating));
        self.maxDistance = ko.observable();
        self.postalCode = ko.observable();
        self.colors = ko.observable();
        self.requirePhoneNumber = ko.observable(false);
        self.matches = ko.observableArray();
        self.areMatchesLoaded = ko.observable(false);
        
        self.toDTO = ko.pureComputed(function() {
            return {
                name: self.name(),
                keywords: self.keywords(),
                showPhone: self.requirePhoneNumber(),
                color: self.colors(),
                matchingPercentage: _.parseInt(self.matchingPercentage())
            };
        });
        
        self.toSearchDTO = ko.pureComputed(function() {
            return {
                keywords: self.keywords(),
                showPhone: self.requirePhoneNumber(),
                colors: self.colors(),
                matchingPercentage: _.parseInt(self.matchingPercentage())
            };            
        });
        
        self.fromPattern = function(pattern) {
            self.fromPatternWithoutMatches(pattern);
            self.matches(pattern.matches());
            self.areMatchesLoaded(pattern.areMatchesLoaded());
        };
        
        self.fromPatternWithoutMatches = function(pattern) {
            self.id(pattern.id());
            self.isArchived(pattern.isArchived());
            self.name(pattern.name());
            self.keywords(pattern.keywords());
            self.matchingPercentage(pattern.matchingPercentage());
            self.date(pattern.date());
            self.maxPrice(formatting.priceValue(pattern.maxPrice()));
            self.maxDistance(pattern.maxDistance());
            self.postalCode(pattern.postalCode());
            self.requirePhoneNumber(pattern.requirePhoneNumber());
        };
        
        self.findMatchById = function(id) {
            id = _.isString(id) ? _.parseInt(id) : id; 
            return _.find(self.matches(), function(match) { return match.id() === id; });
        };
    };
    
    Pattern.clone = function(pattern) {
        var clonedPattern = new Pattern();
        clonedPattern.fromPattern(pattern);
        return clonedPattern;
    };
    
    Pattern.fromDTO = function(dto) {
        var pattern = new Pattern();
        pattern.id(dto.id);
        pattern.isArchived(dto.isArchived);
        pattern.name(dto.name);
        pattern.keywords(dto.keywords);
        pattern.colors(dto.color);
        pattern.requirePhoneNumber(dto.showPhone);
        pattern.matchingPercentage(dto.matchingPercentage);
        pattern.date(dto.timestamp ? new Date(dto.timestamp*1000) : parsing.date(dto.created_at));
        return pattern;
    };
    
    return Pattern;
});
