define('viewmodels/components/dormer-explainer',['require','jquery','lodash','knockout','toastr','plugins/router','perfect-scrollbar','durandal/app','plugins/dialog','services/state-container','utils/formatting','services/customer','text!settings.json','viewmodels/mixins/busy','viewmodels/mixins/popup','viewmodels/base'],function(require) {
    var $ = require('jquery');
    var _ = require('lodash');
    var ko = require('knockout');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var Ps = require('perfect-scrollbar');
    var app = require('durandal/app');
    var dialog = require('plugins/dialog');
    var state = require('services/state-container');
    var formatting = require('utils/formatting');
    var customerService = require('services/customer');
    var settings = JSON.parse(require('text!settings.json'));
    var BusyMixin = require('viewmodels/mixins/busy');
    var PopupMixin = require('viewmodels/mixins/popup');
    var BaseViewModel = require('viewmodels/base');

    var filterSorts = {
        'most-recent': {
            sort: function(woop) { return -woop.date(); },
            filter: function(woop) { return !woop.isArchived(); },
        },
        'most-offers': {
            sort: function(woop) { return -woop.offerCount(); },
            filter: function(woop) { return !woop.isArchived(); },
        },
        'archived': {
            sort: function(woop) { return -woop.date(); },
            filter: function(woop) { return woop.isArchived(); },
        }
    };

    var WoopsOverviewItemViewModel = function(woop, isDefault) {
        var self = this;
        _.assign(self, woop);
        self.source = woop;
        self.dateString = formatting.date(woop.date());
        self.newOfferCountString = ko.pureComputed(function() {
            if (self.newOfferCount() === 0) return null;
            if (self.newOfferCount() === 1) return '1 NIEUWE AANBIEDING';
            return self.newOfferCount() + ' NIEUWE AANBIEDINGEN';
        });
        self.isSelected = ko.pureComputed(function() {
            return state.selectedWoop() === woop;
        });
        self.isDefault = ko.pureComputed(function() {
            return isDefault && !state.selectedWoop();
        });
        self.isViewing = ko.pureComputed(function() {
           return router.activeItem().__moduleId__ === 'viewmodels/pages/offers-page';
        });
        self.isEditing = ko.pureComputed(function() {
           return router.activeItem().__moduleId__ === 'viewmodels/pages/edit-woop-page';
        });
    };

    WoopsOverviewItemViewModel.create = function(woop, index) {
        return new WoopsOverviewItemViewModel(woop, index === 0);
    };

    var testWoopAgainstSearchTerm = function(searchTerm, woop) {
        if (!searchTerm || searchTerm.length < settings.searchTermMinLength) return true;
        if (woop.keywords().toLowerCase().indexOf(searchTerm) > -1) return true;
        return false;
    };

    var DormerExplainerViewModel = function() {
        var self = this;
        _.assign(self, new BusyMixin());
        _.assign(self, new PopupMixin());

        self.filterSort = ko.observable(filterSorts[settings.defaultWoopFilter]);
        self.searchTerm = ko.observable('');

        self.woops = ko.pureComputed(function() {
            var searchTermFilter = _.partial(testWoopAgainstSearchTerm, self.searchTerm().toLowerCase());
            return _.chain(state.woops())
                .filter(searchTermFilter)
                .filter(self.filterSort().filter)
                .sortBy(self.filterSort().sort)
                .map(WoopsOverviewItemViewModel.create)
                .value();
        });

        self.selectWoop = function(woop) {
            if (state.user().isUnregistered()) return toastr.warning('Maak eerst een account in het systeem aan door je e-mailadres in te vullen.');
            state.selectedWoop(woop.source);
            router.navigate('offers/' + woop.id());
        };

        self.editWoop = function(woop) {
            if (state.user().isUnregistered()) return toastr.warning('Maak eerst een account in het systeem aan door je e-mailadres in te vullen.');
            state.selectedWoop(woop.source);
            router.navigate('edit-woop/' + woop.id());
        };

        self.archiveWoop = function(woop) {
            if (state.user().isUnregistered()) return toastr.warning('Maak eerst een account in het systeem aan door je e-mailadres in te vullen.');
            var dialogOptions = {
                title: 'Woop archiveren',
                text: 'Weet je zeker dat je deze woop wil archiveren? Je kan de actie altijd ongedaan maken.',
                confirmButton: 'Archiveren',
                cancelButton: 'Annuleren',
                hasCancelButton: true,
                callback: function() {
                    self.isBusy(true);
                    customerService.archiveWoop(woop.id(), function(err) {
                        self.isBusy(false);
                        if (err) return toastr.error(err, 'Fout');
                        toastr.success('Woop gearchiveerd');
                        router.navigate('offers/' + woop.id());
                    });
                }
            };
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/confirmation-dialog', dialogOptions);
        };

        self.restoreWoop = function(woop) {
            self.isBusy(true);
            customerService.restoreWoop(woop.id(), function(err) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
                toastr.success('Woop hersteld');
            });
        };

        self.onSearchTermInput = function() {
            self.searchTerm('');
        };
        self.searchClick = function() {
            self.maybeShowPopup('search');
        };
        self.showPopup = ko.observable(null);
            $('body').on('click', function() { 
            self.showPopup(null); 
        });
        self.closePopup = function() {
            self.showPopup(null);
        }
        self.maybeShowPopup = function(section) {
            if (state.shellMode() !== 'sauna' && 
                state.shellMode() !== 'bike' && 
                state.shellMode() !== 'dormer' &&
                state.shellMode() !== 'airco') {
                return false;
            }
            if (state.isLoggedIn()) return false;
            self.showPopup('popup popup-' + section);
            return true;
        }        

        self.woopsFilterSubscription = app.on('woops:filter').then(function(tag) {
            self.filterSort(filterSorts[tag]);
        });

        self.deactivate = function() {
            self.woopsFilterSubscription.off();
        };

        self.fadeIn = function(element, index, data) {
            $(element).filter('li').css('opacity', '1');
        };

        self.moreInfo = function() {
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/reverse-ad-info-dialog');
        };
    };

    DormerExplainerViewModel.prototype = new BaseViewModel();
    return new DormerExplainerViewModel();
});

