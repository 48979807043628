define('viewmodels/mixins/profile-pic',['require','knockout','lodash','durandal/app','services/state-container'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var app = require('durandal/app');
    var state = require('services/state-container');
    
    var ProfilePicMixin = function() {
        var self = this;
        
        self.hasProfilePic = ko.pureComputed(function() {
            return !_.isUndefined(state.user().profilePicUri()) && !_.isNull(state.user().profilePicUri());
        });
        
        self.profilePicUri = ko.pureComputed(function() {
            if (self.hasProfilePic()) return state.user().profilePicUri();
            return 'img/default-profile-pic.png';
        });

        self.profilePicUpdatedSubscription = app.on('customer:profile-pic-updated').then(function(uri) {
            state.user().profilePicUri(uri);
        }, self);

        self.disposeProfilePicListener = function() {
            self.profilePicUpdatedSubscription.off();
        };
    };
    
    return ProfilePicMixin;
});
