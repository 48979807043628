/* Woopan API Customer Service */
define('services/customer-api',['require','utils/config','services/generic-api'],function(require) {

    var config = require('utils/config');
    var genericApi = require('services/generic-api');

    var missingParametersErrorMessage = 'Parameters voor request ontbreken';

    return {

    	oauth: config.oauth,
    	api: config.api,

		/* Request access token */
        getToken: function(data, callback) {

        	var required_keys = ['username','password'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);

        	var user = data['username'];
        	var pass = data['password'];
        	var c_id = config.client_id;
        	var c_sec = config.client_secret;
        	var data1= { grant_type: "password", username: user, password: pass, client_id: c_id, client_secret: c_sec };

			genericApi.doRequest('POST', config.server + this.oauth + "access_token", data1, callback);
        	},

        /* Register a customer */
		addCustomer: function(data, callback) {

        	var required_keys = ['emailAddress'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);

			var email = data['emailAddress'];
			var facebook = data['facebookId'];
			var googleplus = data['googlePlusId'];
			var name1 = data['name'];
			var postalCode1 = data['postalCode'];
			var isPremium = data['isPremiumMember'];
			var status1 = data['status'];
			var pass = data['password'];
			var pass_confirm = data['password_confirmation'];

        	var c_id = config.client_id;
        	var c_sec = config.client_secret;

			data = { emailAddress : email , facebookId : facebook , googlePlusId : googleplus,
				  name : name1 , postalCode : postalCode1 ,isPremiumMember : isPremium , status : status1,
				  password : pass, password_confirmation : pass_confirm,
				  grant_type : 'new_customer', client_id: c_id, client_secret: c_sec  };

			genericApi.doRequest('POST', config.server + this.api + "customers", data, callback);

	    },

		/* Edit a customer */
		editCustomer: function(data, callback) {

			var required_keys = ['access_token', 'customerId' ];

 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var customerID = data['customerId'];
			genericApi.doRequest('PUT', config.server + this.api + "customers/" + customerID, data, callback);
		},

		/* Get the customer list */
		getCustomers : function(data, callback) {

			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
			genericApi.doRequest('GET', config.server + this.api + "customers?access_token="+data['access_token'], null, callback);

		},

		/* Get the one specific customer */
		getMyProfile : function (data, callback){

			var required_keys = ['customerId', 'access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var customerID = data['customerId'];
			delete data['customerId'];
			genericApi.doRequest('POST', config.server + this.api + "customers/myprofile/" + customerID, data, callback);

		},

		/* Delete a customer */
		deleteCustomer: function(data, callback) {

			var required_keys = ['customerId', 'access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			var customerID = data['customerId'];
			delete data['customerId'];
			genericApi.doRequest('DELETE', config.server + this.api + "customers/" + customerID, data, callback);

		},

		/* Change a password */
		changePassword: function(data, callback) {

			var required_keys = ['new_password', 'access_token', 'customerId'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var customerID = data['customerId'];
			delete data['customerId'];
			genericApi.doRequest('POST', config.server + this.api + "customers/password/" + customerID, data, callback);

		},

		/* Add a woop */
		addWoop: function(data, callback) {

			var required_keys = ['keywords', 'access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
			genericApi.doRequest('POST', config.server + this.api + "customers/leads", data, callback);

		},

		/* Edit a woop
		Possible arguments:
		keywords=val,maximunPrice=val,
		locationCoordinates=val,locationRadius= val,minimumVendorRating=val,
		publishStatus=('concept','published',location =('national','international','specified'),
		status=('active','sold','revoked'),type=('exclusive','limited',minimal') , access_token=<access_token>
		*/
		editWoop: function(data, callback) {

			var required_keys = ['keywords', 'access_token', 'woopId'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var woopID = data['woopId'];
			delete data['woopId'];
			genericApi.doRequest('PUT', config.server + this.api + "customers/leads/" + woopID, data, callback);

		},

		/* Delete a woop */
		deleteWoop: function(data, callback) {

			var required_keys = ['access_token', 'woopId'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var woopID = data['woopId'];
			delete data['woopId'];
			genericApi.doRequest('DELETE', config.server + this.api + "customers/leads/" + woopID, data, callback);

		},

        /* Restore a woop */
		restoreWoop: function(data, callback) {

			var required_keys = ['access_token', 'woopId'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var woopID = data['woopId'];
			delete data['woopId'];
			genericApi.doRequest('POST', config.server + this.api + "customers/leads/restore/" + woopID, data, callback);

		},

		/* Get woops */
		getWoops: function(data, callback) {
			var required_keys = ['access_token', 'page'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
			genericApi.doRequest('GET', config.server + this.api + "customers/leads?access_token="+data['access_token']+"&page="+data['page'], null, callback);
		},

		/* Get offers to this customer */
		getOffers: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
			genericApi.doRequest('GET', config.server + this.api + "customers/offers?access_token="+data['access_token'], null, callback);
		},

		/* Respond to an offer */
		respondOffer: function(data, callback) {
			var required_keys = ['response', 'access_token', 'offerId'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var offerID = data['offerId'];
			delete data['offerId'];
			genericApi.doRequest('POST', config.server + this.api + "customers/offers/" + offerID, data, callback);
		},

        /* Mark an offer as read */
        markOfferRead: function(data, callback) {
			var required_keys = ['access_token', 'offerId'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var offerID = data['offerId'];
			delete data['offerId'];
			genericApi.doRequest('PUT', config.server + this.api + "customers/offers/" + offerID + '/read', data, callback);
        },

		/* Mark an offer as declined ("archived") */
		markOfferDeclined: function(data, callback) {
			var required_keys = ['response', 'access_token', 'offerId'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
 			var offerID = data['offerId'];
			delete data['offerId'];
			genericApi.doRequest('PUT', config.server + this.api + "customers/offers/" + offerID + '/decline', data, callback);
        },

		/* Forgot our password, request a mail */
		forgotPassword: function(data, callback) {
			var required_keys = ['username'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
			data['grant_type'] = "forgot_pass";
			data['client_id'] = config.client_id;
			data['client_secret'] = config.client_secret;
			genericApi.doRequest('POST', config.server + this.api + "forgotpass", data, callback);
		},

		/* Create a new password, using token received per email */
		createNewPassword: function(data, callback) {
			var required_keys = ['access_token', 'new_password', 'new_password_confirmation'];
 			if ( !genericApi.keysExist(required_keys, data) ) return callback(missingParametersErrorMessage);
			data['grant_type'] = "forgot_pass";
			data['client_id'] = config.client_id;
			data['client_secret'] = config.client_secret;
			genericApi.doRequest('POST', config.server + this.api + "newpass", data, callback);
		},

        getProfilePicUploadOptions: function() {
            return {
                url: config.server + this.api + 'upload/profile-pic',
                headers: { 'access_token': 'test_access_token' }
            };
        },

	    };

});

