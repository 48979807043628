define('viewmodels/pages/pattern-page',['require','knockout','lodash','plugins/router','toastr','durandal/app','services/state-container','models/pattern','viewmodels/components/create-pattern','viewmodels/pages/not-supported-mobile-page','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var router = require('plugins/router');
    var toastr = require('toastr');
    var app = require('durandal/app');
    var state = require('services/state-container');
    var Pattern = require('models/pattern');
    var CreatePatternViewModel = require('viewmodels/components/create-pattern');
    var NotSupportedMobilePageViewModel = require('viewmodels/pages/not-supported-mobile-page');
    var BaseViewModel = require('viewmodels/base');

    var PatternPageViewModel = function() {
        var self = this;
        self.notSupportedMobile = new NotSupportedMobilePageViewModel();

        self.activate = function(patternId) {
            var pattern;
            var isNew;

            if (patternId) {
                isNew = false;
                pattern = state.findPatternById(patternId);
                state.selectedPattern(pattern);
            }
            else {
                isNew = true;
                pattern = new Pattern();
                state.selectedPattern(null);
            }

            state.shellMode('vendor');
            self.createPattern = new CreatePatternViewModel(pattern, isNew);
        };

        self.compositionComplete = function() {
            $('#applicationHost').css('overflow-y', 'hidden');
        };

    };

    PatternPageViewModel.prototype = new BaseViewModel();
    return PatternPageViewModel;
});

