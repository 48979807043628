define('utils/parsing',['require','lodash'],function(require) {
    var _ = require('lodash');
    
    var Parsing = function() {
        var self = this;
        
        self.date = function(date) {
            if (_.isDate(date)) return date;
            var parsed = /(\d\d\d\d)-(\d\d)-(\d\d) (\d\d):(\d\d):(\d\d)/gi.exec(date);
            return new Date(parsed[1], parsed[2] - 1, parsed[3], parsed[4], parsed[5], parsed[6]);
        };
        
        self.countWords = function(s){
            s = s.replace(/(^\s*)|(\s*$)/gi, '');
            s = s.replace(/[ ]{2,}/gi, ' ');
            s = s.replace(/\n /gi, '\n');
            s = s.replace(/\n/gi, ' ');
            return s.split(' ').length; 
        };
        
        self.keywords = function(description) {
            var keywords = description.replace(/\n|\r|\t/gi, ' ').split(' ');
            return _.map(keywords, function(keyword) {
                return keyword.replace(/\.|\,|\;|\:|\*|\‘|\'|\`|\"/gi, '').toLowerCase();
            });
        };
    };
    
    return new Parsing();
});
