define('services/customer',['require','lodash','async','services/customer-api','services/generic-api','services/state-container','utils/repository','utils/dropzone','models/woop','models/offer'],function(require) {
    var _ = require('lodash');
    var async = require('async');
    var customerApi = require('services/customer-api');
    var genericApi = require('services/generic-api');
    var state = require('services/state-container');
    var repository = require('utils/repository');
    var dropzoneUtils = require('utils/dropzone');
    var Woop = require('models/woop');
    var Offer = require('models/offer');

    var CustomerService = function() {
        var self = this;

        var withErrorParsing = genericApi.withErrorParsing;
        var parseError = genericApi.parseError;

        self.loadUser = function(callback) {
            async.series(
                [
                    _.bind(self.getMyProfile, self),
                    _.bind(self.getWoops, self),
                    _.bind(self.getOffers, self),
                ],
                withErrorParsing(callback)
            );
        };

        self.getWoops = function(callback) {
            customerApi.getWoops({ access_token: state.accessToken(), page: 1 }, function(err, data) {
                if (!err) {
                    if (data && data.data) {
                        var woops = _.map(data.data, Woop.fromDTO);
                        repository.diff(state.woops, woops, 'fromWoop');
                        return callback(null, data);
                    }
                    else {
                        return callback('Onverwachte data van server');
                    }
                }
                callback(parseError(err));
            });
        };

        self.addWoop = function(woop, callback) {
            if (typeof woop === 'string' || woop instanceof String) woop = { keywords: woop };
            if (!woop.targetKeyword) delete woop.targetKeyword;
            woop.access_token = state.accessToken();

            var woopId = null;
            async.waterfall(
                [
                    function(callback) {
                        customerApi.addWoop(woop, callback);
                    },
                    function(data, callback) {
                        woopId = data.lead_id;
                        self.getWoops(callback);
                    }
                ],
                function(err, data) {
                    callback(parseError(err), woopId);
                }
            );
        };

        self.updateWoop = function(woopId, woop, callback) {
            woop.access_token = state.accessToken();
            woop.woopId = woopId;

            async.waterfall(
                [
                    function(callback) {
                        customerApi.editWoop(woop, withErrorParsing(callback));
                    },
                    function(data, callback) {
                        self.getWoops(callback);
                    }
                ],
                withErrorParsing(callback)
            );
        };

        self.archiveWoop = function(woopId, callback) {
            var woop = {
                access_token: state.accessToken(),
                woopId: woopId
            };

            async.waterfall(
                [
                    function(callback) {
                        customerApi.deleteWoop(woop, callback);
                    },
                    function(data, callback) {
                        self.getWoops(callback);
                    }
                ],
                withErrorParsing(callback)
            );
        };

        self.restoreWoop = function(woopId, callback) {
            var woop = {
                access_token: state.accessToken(),
                woopId: woopId
            };

            async.waterfall(
                [
                    function(callback) {
                        customerApi.restoreWoop(woop, callback);
                    },
                    function(data, callback) {
                        self.getWoops(callback);
                    }
                ],
                withErrorParsing(callback)
            );
        };

        self.getMyProfile = function(callback) {
            var profile = {
                access_token: state.accessToken(),
                customerId: state.session()['user_id']
            };

            async.waterfall(
                [
                    function(callback) {
                        customerApi.getMyProfile(profile, callback);
                    },
                    function(data, callback) {
                        if (data && data.customer) {
                            state.user().fromDTO(data);
                            callback(null, data);
                        }
                        else {
                            callback('Onverwachte data van server');
                        }
                    }
                ],
                withErrorParsing(callback)
            );
        };

        self.updatePhoneNumber = function(phoneNumber, callback) {
            self.updateProfile({ phone: phoneNumber }, function(err) {
                if (err) return callback(err);
                state.user().phoneNumber(phoneNumber);
                callback();
            });
        };

        self.updateProfile = function(profile, callback) {
            var data = _.omit(profile, 'oldPassword', 'password', 'repeatPassword');
            if (data.postalCode === null) delete data.postalCode;

            data.access_token = state.accessToken();
            data.customerId = state.session()['user_id'];
            customerApi.editCustomer(data, withErrorParsing(callback));
        };

        self.getOffers = function(callback) {
            customerApi.getOffers({ access_token : state.accessToken() }, function(err, data) {
                if (!err) {
                    if (data && data.data) {
                        var offers = _.map(data.data, Offer.fromDTO);
                        var hasNewOffers = repository.diff(state.offers, offers, 'fromOffer');
                        return callback(null, hasNewOffers);
                    }
                    else {
                        return callback('Onverwachte data van server');
                    }
                }
                callback(parseError(err));
            });
        };

        self.respondOffer = function(offerId, response, callback) {
            var data = {
                'access_token': state.accessToken(),
                offerId: offerId,
                response: response
            };
            customerApi.respondOffer(data, function(err, result) {
                if (err) return callback(parseError(err));
                var offer = state.findOfferById(offerId);
                offer.responseCount(offer.responseCount()+1);
                callback(null);
            });
        };

        self.markOffersForWoopRead = function(woop, callback) {
            var offers =_.filter(state.offers(), function(offer) { return offer.isNew() && offer.woopId() === woop.id(); });
            var requests = _.map(offers, function(offer) { return { 'access_token': state.accessToken(), offerId: offer.id() }; });
            async.eachSeries(requests, _.bind(customerApi.markOfferRead, customerApi), function(err) {
                if (err) return callback(parseError(err));
                _.each(offers, function(offer) { offer.isNew(false); woop.newOfferCount(woop.newOfferCount()-1); });
                callback(null);
            });
        };

        self.markOfferDeclined = function(offerId, callback) {
            var data = {
                'access_token': state.accessToken(),
                offerId: offerId,
            };
            customerApi.markOfferDeclined(data, withErrorParsing(callback));
        };

        self.initProfilePicUpload = function(dropzoneId, buttonId, callback) {
            dropzoneUtils.createImageDropZone(dropzoneId, buttonId, customerApi.getProfilePicUploadOptions(), null, callback);
        };
    };

    return new CustomerService();
});

