define('utils/formatting',['require','lodash'],function(require) {
    var _ = require('lodash');

    var Formatting = function() {
        var self = this;

        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        var isSameDay = function(lhs, rhs) {
            if (_.isUndefined(lhs) || _.isUndefined(rhs)) return false;
            var date1 = new Date(lhs.getFullYear(), lhs.getMonth(), lhs.getDate());
            var date2 = new Date(rhs.getFullYear(), rhs.getMonth(), rhs.getDate());
            return date1.valueOf() == date2.valueOf();
        };

        var addLeadingZero = function(n) {
            if (n < 10) return '0' + n;
            return n;
        };

        self.date = function(date) {
            if (_.isNull(date)) return null;
            if (_.isUndefined(date)) return null;
            if (_.isString(date)) return date;
            if (isSameDay(date, today)) return 'Vandaag';
            if (isSameDay(date, yesterday)) return 'Gisteren';
            return addLeadingZero(date.getDate()) + '-' + addLeadingZero(date.getMonth()+1) + '-' + date.getFullYear();
        };

        self.plainDate = function(date) {
            if (_.isNull(date)) return null;
            if (_.isUndefined(date)) return null;
            if (_.isString(date)) return date;
            return addLeadingZero(date.getDate()) + '-' + addLeadingZero(date.getMonth()+1) + '-' + date.getFullYear();
        };

        self.timestamp = function(date) {
            return self.plainDate(date) + ' ' + self.time(date);
        };

        self.time = function(date) {
            if (_.isNull(date)) return null;
            if (_.isUndefined(date)) return null;
            if (_.isString(date)) return date;
            return addLeadingZero(date.getHours()) + ':' + addLeadingZero(date.getMinutes());
        };

        self.price = function(price) {
            priceStr = parseFloat(Math.round(price * 100) / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');
            return '€' + priceStr;
        };

        self.priceValueString = function(price) {
            if (_.isNull(price) || _.isUndefined(price)) return price;
            if (_.isNumber(price)) price = price.toString();
            if (_.isString(price)) return price.replace('.', ',');
        };

        self.priceValue = function(price) {
            if (_.isNull(price) || _.isUndefined(price)) return price;
            if (_.isNumber(price)) return price;
            if (_.isString(price)) return parseFloat(price.replace(',', '.'));
            return price;
        };

        self.percentage = function(percentage) {
            return Math.round(percentage) + '%';
        };

        self.percentageFromFraction = function(fraction) {
            return Math.round(fraction*100) + '%';
        };

        // http://stackoverflow.com/questions/280793/case-insensitive-string-replacement-in-javascript
        var preg_quote = function(str) {
            return (str+'').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1");
        };

        self.highlightKeywords = function(text, keywords, wrapper) {
            var result = text;
            _.each(keywords, function(keyword) {
                result = result.replace(new RegExp('(' + preg_quote(keyword) + ')', 'gi'), wrapper.open + '$1' + wrapper.close);
            });
            return result;
        };
    };

    return new Formatting();
});

