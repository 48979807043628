define('services/generic-api',['require','lodash','plugins/http'],function(require) {
    var _ = require('lodash');
    var http = require('plugins/http');

    var GenericApi = function() {
        var self = this;

        self.doRequest = function(requestType, url, data, callback) {
            var req;
            if      (requestType === 'GET')    req = _.bind(http.get, http, url);
            else if (requestType === 'PUT')    req = _.bind(http.put, http, url, data);
            else if (requestType === 'DELETE') req = _.bind(http.remove, http, url, data);
            else if (requestType === 'POST')   req = _.bind(http.post, http, url, data);

            req()
            .done(function(data) { 
                callback(null, data); 
            })
            .fail(function(err)  {
                try {
                    var errorMessage = JSON.parse(err.responseText);
                    callback(errorMessage); 
                } catch(e) {
                    callback('Serverfout');
                }
            });
        };
        
        self.keysExist = function(requiredKeys, data) {
            var missingKeys =_.difference(requiredKeys, _.keys(data));
            if (missingKeys.length === 0) return true;
            _.each(missingKeys, function(key) {
                console.log('missing key: ' + key);
            });
            return false;
        };

        self.parseError = function(err) {
            if (err === null) return null;
            if (_.isString(err)) return err;
            else if (_.isString(err.error.message)) return err.error.message;
            return err.error.message[0];
        };

        self.withErrorParsing = _.partial(_.modArgs, _, self.parseError, _.identity);
    };

    return new GenericApi();
}); 
