define('viewmodels/mixins/static-page',['require','jquery','plugins/router','services/state-container'],function(require) {
    var $ = require('jquery');
    var router = require('plugins/router');
    var state = require('services/state-container');

    var StaticPageMixin = function() {
        var self = this;
        
        self.isFront = true;
        self.isLoggedIn = state.isLoggedIn;
        
        self.goHome = function() {
            router.navigate('/');
        };
        
        self.goToDashboard = function() {
            router.navigate('dashboard');
        };
    };

    return StaticPageMixin;
});
