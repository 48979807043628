define('viewmodels/components/matches-overview',['require','jquery','lodash','knockout','toastr','plugins/router','durandal/app','services/state-container','utils/formatting','utils/string','services/customer','text!settings.json','viewmodels/base'],function(require) {
    var $ = require('jquery');
    var _ = require('lodash');
    var ko = require('knockout');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var state = require('services/state-container');
    var formatting = require('utils/formatting');
    var stringUtils = require('utils/string');
    var customerService = require('services/customer');
    var settings = JSON.parse(require('text!settings.json'));
    var BaseViewModel = require('viewmodels/base');

    var filterSorts = {
        'most-recent': {
            sort: function(match) { return -match.date(); },
            filter: function(match) { return true; },
        },
        'most-offers': {
            sort: function(match) { return -match.date(); },
            filter: function(match) { return true; },
        },
        'archived': {
            sort: function(match) { return -match.date(); },
            filter: function(match) { return true; },
        }
    };

    var MatchesOverviewItemViewModel = function(match) {
        var self = this;
        _.assign(self, match);
        self.source = match;
        self.customerName = stringUtils.isEmpty(self.customer.name()) ? 'Anonieme gebruiker' : self.customer.name();
        self.customerProfilePicUrl = ko.pureComputed(function() {
            if (self.customer.profilePicUrl()) return self.customer.profilePicUrl();
            return 'img/default-profile-pic.png';
        });
        self.numOffersString = match.numOffers() + ' AANBIEDINGEN GEHAD';
        self.dateString = formatting.date(match.date());
        self.matchingPercentageString = formatting.percentage(match.matchingPercentage()) + ' MATCH';
        self.isSelected = ko.pureComputed(function() {
            return state.selectedMatch() === match;
        });
    };

    MatchesOverviewItemViewModel.create = function(match) {
        return new MatchesOverviewItemViewModel(match);
    };

    var testMatchAgainstSearchTerm = function(searchTerm, match) {
        if (!searchTerm || searchTerm.length < settings.searchTermMinLength) return true;
        if (match.keywords().toLowerCase().indexOf(searchTerm) > -1) return true;
        return false;
    };

    var MatchesOverviewViewModel = function(pattern) {
        var self = this;

        self.filterSort = ko.observable(filterSorts[settings.defaultMatchFilterSort]);
        self.searchTerm = ko.observable('');

        self.matches = ko.pureComputed(function() {
            return _.chain(pattern.matches())
                .map(MatchesOverviewItemViewModel.create)
                .value();
        });

        self.editPattern = function() {
            router.navigate('patterns/' + pattern.id());
        };

        self.newPattern = function() {
            router.navigate('patterns');
        };

        self.selectMatch = function(match) {
            window.scrollTo(0, 0);
            router.navigate('patterns/' + state.selectedPattern().id() + '/matches/' + match.id(), false);
            state.selectedMatch(match.source);
            $('.matches-page').animate({ scrollTop: 0 });
        };

        self.matchesFilterSubscription = app.on('matches:filter').then(function(tag) {
            self.filterSort(filterSorts[tag]);
        });

        self.deactivate = function() {
            self.matchesFilterSubscription.off();
        };
    };

    MatchesOverviewViewModel.prototype = new BaseViewModel();
    return MatchesOverviewViewModel;
});

