define('viewmodels/components/panda',['require','lodash','knockout','plugins/router','toastr','durandal/app','utils/parsing','text!settings.json','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var ko = require('knockout');
    var router = require('plugins/router');
    var toastr = require('toastr');
    var app = require('durandal/app');
    var parsing = require('utils/parsing');
    var settings = JSON.parse(require('text!settings.json'));
    var BaseViewModel = require('viewmodels/base');

    var PandaViewModel = function(keywords, targetKeyword, forSaunaPage) {
        var self = this;
        self.keywords = keywords;
        self.targetKeyword = targetKeyword;
        self.pandaMessages = settings.saunaPandaMessages;//self.cmsTexts['search']['pandamessages'] || settings.pandaMessages;
        self.tagLineClass = ko.observable(forSaunaPage ? 'tagline tagline-sauna' : 'tagline');
        self.containerClass = ko.observable(forSaunaPage ? 'panda-container panda-container-sauna' : 'panda-container');

        self.animatePandaHead = function(delay) {
            var doAnimation = function() {
                $('.panda-head').removeClass('panda-head-animation');
                setTimeout(function() { $('.panda-head').addClass('panda-head-animation'); }, 10);
            };
            if (delay) setTimeout(doAnimation, delay);
            else doAnimation();
        };

        var computeMessageTier = function() {
            if (!self.keywords()) return 0;
            var numberOfWords = parsing.countWords(self.keywords());
            var result = _.findLastIndex(settings.pandaMessageTransitions, function(x) {
                return x <= numberOfWords;
            });
            return result;
        };
        self.currentMessageTier = ko.observable(0);
        self.currentMessage = ko.observable('');
        self.targetMessage = ko.observable(self.pandaMessages[0]);

        // var updateMessage = function() {
        //     if (self.currentMessageTier() === 0 && self.targetKeyword() !== null) {
        //         self.targetMessage(settings.targetMessage.replace('$q', self.targetKeyword()));
        //     }
        //     else {
        //         var m = self.pandaMessages[self.currentMessageTier()];
        //         self.targetMessage(m[_.random(0, m.length-1)]);
        //     }
        //     self.currentMessage('');
        //     self.animatePandaHead();
        // };
        var animateMessage = function() {
            if (!self.targetMessage()) return;
            if (self.targetMessage() === self.currentMessage()) return;
            var newLength = self.currentMessage() ? self.currentMessage().length + 1 : 1;
            self.currentMessage(self.targetMessage().substring(0, newLength));
        };
        var nextMessage = function() {
            self.currentMessageTier((self.currentMessageTier() + 1) % self.pandaMessages.length);
            self.targetMessage(self.pandaMessages[self.currentMessageTier()]);
            self.currentMessage('');
        };

        // self.keywords.subscribe(function() {
        //     var tier = computeMessageTier();
        //     if (tier !== self.currentMessageTier()) self.currentMessageTier(tier);
        // });
        // self.currentMessageTier.subscribe(updateMessage);

        self.showAnimatedPanda = true;//self.isMobile || forSaunaPage;

        self.compositionComplete = function() {
            self.animatePandaHead();
            var animationIntervals = [400, 1200, 1600, 2000];
            _.each(animationIntervals, _.partial(setTimeout, self.animatePandaHead.bind(self)));
            // updateMessage();
        };

        self.activate = function() {
            self.messageTimer = setInterval(animateMessage, 5);

            clearInterval(self.nextMessageTimer);
            self.nextMessageTimer = setInterval(nextMessage, 4500);
        };

    };

    PandaViewModel.prototype = new BaseViewModel();
    return PandaViewModel;
});

