/* CMS Service */
define('services/cms-api',['require','utils/config','services/generic-api'],function(require) {

  var config = require('utils/config');
  var genericApi = require('services/generic-api');

  var CmsService = function() {
      var self = this;

      self.getTexts = function(callback) {
        genericApi.doRequest('GET', config.server + config.api + "translations/" + config.locale, null, callback);
      };
  };

  return new CmsService();

});

