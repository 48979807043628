/* Social Service (not that one) */
define('services/social-api',['require','utils/config','services/generic-api'],function(require) {

  var config = require('utils/config');
  var genericApi = require('services/generic-api');

  var SocialService = function() {
      var self = this;

      self.getGoogleAuthenticationUrl = function(callback) {
        genericApi.doRequest('GET', config.server + config.oauth + "googleplus", null, callback);
      };

      self.getFacebookAuthenticationUrl = function(callback) {
        genericApi.doRequest('GET', config.server + config.oauth + "facebook", null, callback);
      };

      self.signInGoogleUser = function(params, callback) {
          genericApi.doRequest('GET', config.server + config.oauth + "googleplus/callback" + params, null, callback);
      };

      self.signInFacebookUser = function(params, callback) {
          genericApi.doRequest('GET', config.server + config.oauth + "facebook/callback" + params, null, callback);
      };

  };

  return new SocialService();

});

