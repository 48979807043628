define('models/offer',['require','knockout','lodash','utils/parsing','utils/formatting','models/validation'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var parsing = require('utils/parsing');
    var formatting = require('utils/formatting');
    var validation = require('models/validation');
    
    var Offer = function() {
        var self = this;

        self.isValidating = ko.observable(false);

        self.id = ko.observable();
        self.woopId = ko.observable();
        self.isNew = ko.observable(true);
        self.vendor = {
            id: ko.observable(),
            name: ko.observable(),
            description: ko.observable(),
            streetAddress: ko.observable(),
            postalCode: ko.observable(),
            country: ko.observable(),
            website: ko.observable(),
            emailAddress: ko.observable(),
            phoneNumber: ko.observable(),
        };
        self.lead = {
            text: ko.observable(),
            customerName: ko.observable(),
            customerProfilePicUrl: ko.observable()
        };
        self.description = ko.observable().extend(validation.required(self.isValidating));
        self.price = ko.observable().extend(validation.price(self.isValidating));
        self.directOrderLink = ko.observable().extend(validation.optionalUrl(self.isValidating));
        self.isArchived = ko.observable();
        self.date = ko.observable();
        self.picUrls = ko.observableArray();
        self.vendorProfilePicUri = ko.observable();
        self.leadText = ko.observable();
        self.responseCount = ko.observable();
        
        self.fromOffer = function(offer) {
            self.id(offer.id());
            self.woopId(offer.woopId());
            self.isNew(offer.isNew());

            self.vendor.id(offer.vendor.id());
            if (offer.vendor) {
                self.vendor.name(offer.vendor.name());
                self.vendor.description(offer.vendor.description());
                self.vendor.streetAddress(offer.vendor.streetAddress());
                self.vendor.postalCode(offer.vendor.postalCode());
                self.vendor.country(offer.vendor.country());
                self.vendor.website(offer.vendor.website());
                self.vendor.emailAddress(offer.vendor.emailAddress());
                self.vendor.phoneNumber(offer.vendor.phoneNumber());
            }
            self.description(offer.description());
            self.price(formatting.priceValue(offer.price()));
            self.directOrderLink(offer.directOrderLink());
            self.isArchived(offer.isArchived());
            self.picUrls(_.cloneDeep(offer.picUrls()));
            self.vendorProfilePicUri(offer.vendorProfilePicUri());
            self.leadText(offer.leadText());
            self.responseCount(offer.responseCount());
        };
        
        self.toDTO = ko.pureComputed(function() {
            return {
                id: self.id(),
                lead_id: self.woopId(),
                vendor_id: self.vendor.id(),
                price: formatting.priceValue(self.price()),
                content: self.description(),
                isArchived: self.isArchived(),
                date: self.date(),
                orderUrl: self.directOrderLink()
            };
        });
    };
    
    Offer.fromOffer = function(offer) {
        var clonedOffer = new Offer();
        clonedOffer.fromOffer(offer);
        return clonedOffer;
    };
    
    Offer.fromDTO = function(dto) {
        
        var offer = new Offer();
        offer.id(dto.id);
        offer.woopId(dto.lead_id);
        offer.isNew(dto.status === 'open');
        
        offer.vendor.id(dto.vendor_id);
        if (_.has(dto, 'vendor')) {
            offer.vendor.name(dto.vendor.companyName ? dto.vendor.companyName : dto.vendor.name);
            offer.vendor.description(dto.vendor.description);
            offer.vendor.streetAddress(dto.vendor.streetAddress);
            offer.vendor.postalCode(dto.vendor.postalCode);
            offer.vendor.country(dto.vendor.country);
            offer.vendor.emailAddress(dto.vendor.emailAddress);
            offer.vendor.website(dto.vendor.website);
            offer.vendor.phoneNumber(dto.vendor.phoneNumber);
            offer.vendorProfilePicUri(dto.vendor.profilePicUrl);
        }
        if (_.has(dto, 'lead')) {
            offer.lead.text(dto.lead.leadTxt);
            offer.lead.customerName(dto.lead.name);
            offer.lead.customerProfilePicUrl(dto.lead.profilePicUrl);
        }
        offer.responseCount(dto.responseCount);
        offer.price(formatting.priceValue(dto.price));
        offer.description(dto.content);
        offer.isArchived(dto.isArchived);
        offer.date(dto.timestamp ? new Date(dto.timestamp*1000) : parsing.date(dto.created_at) );        
        offer.directOrderLink(dto.orderUrl);
		if (dto.photoUrl1) offer.picUrls.push(dto.photoUrl1);
		if (dto.photoUrl2) offer.picUrls.push(dto.photoUrl2);
		if (dto.photoUrl3) offer.picUrls.push(dto.photoUrl3);
		if (dto.photoUrl4) offer.picUrls.push(dto.photoUrl4);

        return offer;
    };
    
    Offer.fromLead = function(lead) {
        var offer = new Offer();
        offer.woopId(lead.id());
        offer.isArchived(false);
        return offer;
    };
    
    return Offer;
});
