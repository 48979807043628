define('viewmodels/components/header',['require','knockout','jquery','lodash','toastr','services/state-container','durandal/app','plugins/router','plugins/dialog','viewmodels/components/filter-options','services/customer','services/user','services/vendor','text!settings.json','viewmodels/mixins/busy','viewmodels/mixins/popup','viewmodels/mixins/profile-pic','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var _ = require('lodash');
    var toastr = require('toastr');
    var state = require('services/state-container');
    var app = require('durandal/app');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var FilterOptionsViewModel = require('viewmodels/components/filter-options');
    var customerService = require('services/customer');
    var userService = require('services/user');
    var vendorService = require('services/vendor');
    var settings = JSON.parse(require('text!settings.json'));
    var BusyMixin = require('viewmodels/mixins/busy');
    var PopupMixin = require('viewmodels/mixins/popup');
    var ProfilePicMixin = require('viewmodels/mixins/profile-pic');
    var BaseViewModel = require('viewmodels/base');

    var createOfferFilterOptions = function(router) {
        return {
            name: 'offers',
            options: {
                'most-recent':  { name: 'Meest recent', icon: 'clock-o' },
                'lowest-price': { name: 'Laagste prijs', icon: 'euro' },
                'archived':     { name: 'Gearchiveerd', icon: 'archive' }
            },
            defaultOption: settings.defaultOfferFilter,
            shouldShow: ko.pureComputed(function() {
                if (!router.activeInstruction()) return false;
                return router.activeInstruction().config.moduleId === 'viewmodels/pages/offers-page' && state.shellMode() === 'customer';
            }),
            isOnLeftSide: false
        };
    };

    var createPatternFilterOptions = function(router) {
        return {
            name: 'patterns',
            options: {
                'most-recent':  { name: 'Meest recent', icon: 'clock-o' },
                'most-matches': { name: 'Meeste matches', icon: 'sort-amount-desc' },
                'archived':     { name: 'Gearchiveerd', icon: 'archive' }
            },
            defaultOption: settings.defaultPatternFilter,
            shouldShow: ko.pureComputed(function() { return state.shellMode() === 'vendor'; }),
            isOnLeftSide: true
        };
    };

    var createWoopFilterOptions = function(router) {
        return {
            name: 'woops',
            options: {
                'most-recent':  { name: 'Meest recent', icon: 'clock-o' },
                'most-offers':  { name: 'Meeste aanbiedingen', icon: 'shopping-cart' },
                'archived':     { name: 'Gearchiveerd', icon: 'archive' }
            },
            defaultOption: settings.defaultWoopFilter,
            shouldShow: ko.pureComputed(function() { return state.shellMode() === 'customer'; }),
            isOnLeftSide: true
        };
    };


    var HeaderViewModel = function(router) {
        var self = this;
        _.assign(self, new ProfilePicMixin());
        _.assign(self, new BusyMixin());
        _.assign(self, new PopupMixin());

        // Filter options
        self.woopFilterOptions = new FilterOptionsViewModel(createWoopFilterOptions(router));
        self.patternFilterOptions = new FilterOptionsViewModel(createPatternFilterOptions(router));
        self.offerFilterOptions = new FilterOptionsViewModel(createOfferFilterOptions(router));

        // Modals
        self.isLeftBlockoutVisible = ko.pureComputed(function() {
            return self.woopFilterOptions.isOpen() || self.patternFilterOptions.isOpen();
        });
        self.isRightBlockoutVisible = ko.pureComputed(function() {
            return self.offerFilterOptions.isOpen();
        });
        self.cancelModal = function() {
            self.woopFilterOptions.close();
            self.offerFilterOptions.close();
            self.patternFilterOptions.close();
        };

        // Vendor/customer mode
        self.mode = state.shellMode;
        self.numberOfCustomerNotifications = state.numberOfCustomerNotifications;

        self.toVendorMode   = function() { 
            if (self.maybeShowPopup('vendor')) return;
            self.mode('vendor'); 
            self.newPattern(); 
        };
        self.toCustomerMode = function() { 
            if (self.maybeShowPopup('customer')) return;
            self.mode('customer'); 
            self.newWoop(); 
        };

        // Credits
        self.credits = ko.pureComputed(function() {
            if (!state.user().isVendor()) return '';
            return state.user().vendor().credits();
        });
        self.purchaseCredits = function() {
            if (self.maybeShowPopup('credits')) return;
            ga('send', 'event', 'User', 'Open purchase credits window', 'Header');
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/purchase-credits-dialog');
        };

        // Navigation
        var navigate = function(page) {
            if (state.user().isUnregistered()) {
                toastr.warning('Maak eerst een account in het systeem aan door je e-mailadres in te vullen.');
                return;
            }

            router.navigate(page);
            self.cancelModal();
        };

        // Buttons
        self.woopCount = ko.pureComputed(function() {
            return _.size(_.filter(state.woops(), function(o){ return !o.isArchived(); }));
        });
        self.patternCount = ko.pureComputed(function() {
            return _.size(state.patterns());
        });
        self.responseCount = ko.pureComputed(function() {
            var arr = _.map(state.offers(), function(o) {
                return o.responseCount();
            });
            var totalResponseCount = _.sum(arr);
            return totalResponseCount;
        });
        self.woopButtonText = ko.pureComputed(function() {
            return 'Mijn zoekopdrachten: '+self.woopCount()+' | Aanbiedingen: '+ state.numberOfCustomerNotifications();
        });

        self.numberOfCustomerNotifications = ko.pureComputed(function() {
            return state.numberOfCustomerNotifications();
        });

        self.patternButtonText = ko.pureComputed(function() {
            return 'Mijn advertenties: '+self.patternCount()+' | Reacties: '+self.responseCount();
        });

        self.toHome = function() { 
            if (self.maybeShowPopup('home')) return;
            navigate('dashboard'); 
        };
        self.toProfile = function() { 
            if (self.maybeShowPopup('profile')) return;
            navigate('profile'); 
        };
        self.toOffers = function() { 
            if (self.maybeShowPopup('offers')) return;
            navigate('offers'); 
        };
        self.newWoop = function() { 
            if (self.maybeShowPopup('new-woop')) return;
            navigate('new-woop'); 
        };
        self.newPattern = function() { 
            if (self.maybeShowPopup('patterns')) return;
            navigate('patterns'); 
        };

        self.activate = function () {

        };
    };

    HeaderViewModel.prototype = new BaseViewModel();
    return HeaderViewModel;
});

