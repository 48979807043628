define('viewmodels/components/benefits',['require','lodash','knockout','viewmodels/base','viewmodels/mixins/popup'],function(require) {
    var _ = require('lodash');
    var ko = require('knockout');
    var BaseViewModel = require('viewmodels/base');
    var PopupMixin = require('viewmodels/mixins/popup');

    var BenefitsViewModel = function(isSmall) {
        var self = this;

        _.assign(self, new PopupMixin());
        
        self.popupHeader = ko.observable('');
        self.popupText = ko.observable('');
        self.showLinks = ko.observable(true);
        self.containerClass = ko.observable(isSmall ? 'benefits benefits-small' : 'benefits');

        self.hover1 = function() {
            self.maybeShowPopup('hover1');
            self.popupHeader('Goedkoper');
            self.popupText('<p>Grote advertentieplatformen zoals Google, Amazon en Facebook verdienen miljarden aan advertentiekosten.<p>' +
            '<p>Deze miljarden worden uiteindelijk door u als klant betaald omdat producten gemiddeld tot wel 50% duurder zijn door deze hoge advertentiekosten.</p>' + 
            '<p>WOOPAN maakt deze kosten overbodig en daarmee uw aankoop goedkoper dankzij omgekeerd adverteren.</p>')
        };
        self.hover2 = function() {
            self.maybeShowPopup('hover2');
            self.popupHeader('Sneller');
            self.popupText('<p>Normaal gesproken kost een aankoopproces van wat duurdere producten zoals een sauna u veel tijd. U gaat hiervoor verschillende aanbieders bezoeken met wie u allemaal afzonderlijk een gesprekswisseling aan gaat bestaande uit verschillende emailcontacten en bezoeken waarna de aanbieder u een voorstel doet waarna een onderhandeling s proces gestart word over de prijs.<p>' +
            '<p>Met WOOPAN doet u 1 keer een specifieke aanvraag waarna alle aanbieders u een voorstel kunnen doen waarbij zij u direct hun beste voorstel doen. De standaard kortingen samen met de reductie van de marketing kost brengt de totaalkorting vaak op wel 50% van de geadverteerde prijzen omdat leveranciers al uit gaan van een onderhandelingstraject. WOOPAN spreekt af met leveranciers dat direct de beste prijs geboden word.</p>')
        };
        self.hover3 = function() {
            self.maybeShowPopup('hover3');
            self.popupHeader('Veiliger');
            self.popupText('<p>U ontvangt de aanbiedingen van het WOOPAN systeem. Dat betekend dat u dus niet aan alle leveranciers waarmee u communiceert uw email telefoon en overige contactgegevens hoeft te delen. U communiceert met de leverancier via WOOPAN en bepaalt zelf wanneer u direct contact op wil nemen met een leverancier die u een goede aanbieding heeft gedaan en waarbij u een goed gevoel heeft.</p>')
            console.log('sp', self.showPopup());
        };                
        self.hoverOff = function() {
            self.showPopup('');
        }

    };

    BenefitsViewModel.prototype = new BaseViewModel();
    return BenefitsViewModel;
});

