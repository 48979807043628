define('viewmodels/dialogs/photo-gallery-dialog',['require','knockout','plugins/router','plugins/dialog','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var BaseViewModel = require('viewmodels/base');

    var PhotoGalleryDialog = function() {
        var self = this;

        self.autoclose = true;
        self.uri = ko.observable('img/test-offer-photo.jpg');

        self.close = function() {
            dialog.close(self);
        };

        self.activate = function(uri) {
            self.uri(uri);
        };
    };

    PhotoGalleryDialog.prototype = new BaseViewModel();
    return PhotoGalleryDialog;
});

