define('viewmodels/pages/not-supported-mobile-page',['require','lodash','viewmodels/mixins/static-page','viewmodels/base','viewmodels/components/not-supported-mobile-panda'],function(require) {
    var _ = require('lodash');
    var StaticPageMixin = require('viewmodels/mixins/static-page');
    var BaseViewModel = require('viewmodels/base');
    var NotSupportedMobilePandaViewModel = require('viewmodels/components/not-supported-mobile-panda');

    var NotSupportedMobilePageViewModel = function() {
        var self = this;
        // _.extend(self, new StaticPageMixin());
        self.panda = new NotSupportedMobilePandaViewModel();
    };

    NotSupportedMobilePageViewModel.prototype = new BaseViewModel();
    return NotSupportedMobilePageViewModel;
});

