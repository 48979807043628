define('viewmodels/dialogs/forgot-password-dialog',['require','knockout','jquery','durandal/app','plugins/router','plugins/dialog','toastr','utils/string','services/user','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var app = require('durandal/app');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var toastr = require('toastr');
    var stringUtils = require('utils/string');
    var userService = require('services/user');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var ForgotPasswordDialog = function() {
        var self = this;
        self.autoclose = false;
        if (self.isMobile) self.autoclose = true;
        _.assign(self, new BusyMixin());

        self.emailAddress = ko.observable();
        self.isDone = ko.observable(false);

        self.go = function() {
            self.isBusy(true);
            userService.forgotPassword(self.emailAddress(), function(err, data) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
                self.isDone(true);
                ga('send', 'event', 'User', 'Forgot password', self.emailAddress());
                toastr.success('E-mail is verstuurd');
            });
        };

        self.keydown = function(sender, e) {
            if (e.keyCode === 27 && self.isMobile) self.close();
            if (e.keyCode === 13 && self.isGoButtonEnabled()) self.go();
            return true;
        };

        self.isGoButtonEnabled = ko.pureComputed(function() {
            if (self.isBusy()) return false;
            if (self.isDone()) return false;
            if (stringUtils.isEmpty(self.emailAddress())) return false;
            return true;
        });

        self.activate = function(keywords) {
            self.keywords = keywords;
        };

        self.compositionComplete = function() {
            setTimeout(function() { $('#email').focus(); }, 200);
        };

        self.close = function() {
            dialog.close(self);
        };

        self.closeDialogSubscription = app.on('dialogs:close').then(function() {
            dialog.close(self);
        });

        self.deactivate = function() {
            self.closeDialogSubscription.off();
        };

        self.blockoutClass = 'brown';
    };

    ForgotPasswordDialog.prototype = new BaseViewModel();
    return ForgotPasswordDialog;
});

