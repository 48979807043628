define('models/user',['require','knockout','lodash','utils/parsing','utils/string','models/vendor','models/validation'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var parsing = require('utils/parsing');
    var stringUtils = require('utils/string');
    var Vendor = require('models/vendor');
    var validation = require('models/validation');
    
    var User = function() {
        var self = this;

        self.isValidating = ko.observable(false);
        self.id = ko.observable();
        self.isNew = ko.observable(true);
        
        self.oldPassword = ko.observable();
        self.password = ko.observable();
        self.repeatPassword = ko.observable();
        
        self.firstName = ko.observable().extend(validation.required(self.isValidating));
        self.lastName = ko.observable().extend(validation.required(self.isValidating));
        self.emailAddress = ko.observable().extend(validation.requiredEmailAddress(self.isValidating));
        self.phoneNumber = ko.observable().extend(validation.phoneNumber(self.isValidating));
        self.postalCode = ko.observable();
        self.profilePicUri = ko.observable(null);
        self.facebook = ko.observable();
        self.twitter = ko.observable();
        self.googlePlus = ko.observable();
        self.instagram = ko.observable();
        self.website = ko.observable();
        self.oldPassword.isValidCustom = ko.observable(true);
        self.password.isValidCustom = ko.observable(true);
        self.repeatPassword.isValidCustom = ko.observable(true);
        self.vendor = ko.validatedObservable(new Vendor());
        self.isVendor = ko.observable(false);
        self.fullName = ko.pureComputed(function() {
            return self.firstName() + ' ' + self.lastName();
        });
        self.isUnregistered = ko.observable(false);
        
        self.validatePassword = function(oldPassword, newPassword, repeatPassword) {
            return validation.password(self.isNew, oldPassword, newPassword, repeatPassword);
        };
        
        self.profileCompletion = ko.pureComputed(function() {
            var score = 0;
            
            if (!stringUtils.isEmpty(self.firstName())) score += 25;
            if (!stringUtils.isEmpty(self.lastName())) score += 25;
            if (!stringUtils.isEmpty(self.phoneNumber())) score += 25;
            if (!stringUtils.isEmpty(self.postalCode())) score += 25;
            
            if (self.isVendor()) {
                score /= 2;
                if (!stringUtils.isEmpty(self.vendor().companyName())) score +=7;
                if (!stringUtils.isEmpty(self.vendor().emailAddress())) score +=7;
                if (!stringUtils.isEmpty(self.vendor().postalCode())) score +=7;
                if (!stringUtils.isEmpty(self.vendor().houseNumber())) score +=7;
                if (!stringUtils.isEmpty(self.vendor().website())) score +=7;
                if (!stringUtils.isEmpty(self.vendor().phoneNumber())) score +=7;
                if (!stringUtils.isEmpty(self.vendor().description())) score += 8;
            } 
            
            return score / 100;
        });
        
        self.upgradeToVendor = function() {
            if (self.isVendor()) return;
            self.isVendor(true);
            // pre-fill vendor models based on customer data
            self.vendor().emailAddress(self.emailAddress());
            self.vendor().postalCode(self.postalCode());
            self.vendor().phoneNumber(self.phoneNumber());
        };
        
        self.toDTO = ko.pureComputed(function() {
            return {
                oldPassword: self.oldPassword,
                password: self.password,
                repeatPassword: self.repeatPassword,
                name: self.firstName(),
                lastname: self.lastName(),
                emailAddress: self.emailAddress(),
                phone: self.phoneNumber(),
                postalCode: self.postalCode(),
                facebook: self.facebook(),
                twitter: self.twitter(),
                googleplus: self.googlePlus(),
                instagram: self.instagram(),
                website: self.website()                
            };
        });
        
        self.fromDTO = function(dto) {
            self.id(dto.id);
            self.isVendor(dto.isVendor);
            self.isNew(dto.isNew);
            self.firstName(dto.name);
            self.lastName(dto.lastname);						
            self.emailAddress(dto.emailAddress);
            self.phoneNumber(dto.customer.phone);
            self.postalCode(dto.customer.postalCode);
            self.profilePicUri(dto.customer.profilePicUrl);
            self.facebook(dto.customer.facebook);
            self.twitter(dto.customer.twitter);
            self.instagram(dto.customer.instagram);
            self.googlePlus(dto.customer.googleplus);
            self.website(dto.customer.website);
        };
        
        self.fromUser = function(user) {
            self.id(user.id());
            self.isUnregistered(user.isUnregistered());
            self.isNew(user.isNew());
            self.oldPassword(user.oldPassword());
            self.password(user.password());
            self.repeatPassword(user.repeatPassword());
            self.firstName(user.firstName());
            self.lastName(user.lastName());
            self.emailAddress(user.emailAddress());
            self.phoneNumber(user.phoneNumber());
            self.postalCode(user.postalCode());
            self.profilePicUri(user.profilePicUri());
            self.facebook(user.facebook());
            self.twitter(user.twitter());
            self.googlePlus(user.googlePlus());
            self.instagram(user.instagram());
            self.website(user.website());
            self.isVendor(user.isVendor());
            self.vendor(Vendor.clone(user.vendor()));
        };
    };
    
    User.fromDTO = function(dto) {
        var user = new User();
        user.fromDTO(dto);
        return user;
    };
    
    User.clone = function(user) {
        var clonedUser = new User();
        clonedUser.fromUser(user);
        return clonedUser;
    };
    
    return User;
});
