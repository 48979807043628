define('viewmodels/dialogs/purchase-credits-dialog',['require','knockout','lodash','plugins/router','plugins/dialog','services/state-container','services/vendor','utils/string','toastr','utils/formatting','viewmodels/mixins/busy','text!settings.json','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var state = require('services/state-container');
    var vendorService = require('services/vendor');
    var stringUtils = require('utils/string');
    var toastr = require('toastr');
    var formatting = require('utils/formatting');
    var BusyMixin = require('viewmodels/mixins/busy');
    var settings = JSON.parse(require('text!settings.json'));
    var BaseViewModel = require('viewmodels/base');

    var PurchaseCreditsDialog = function() {
        var self = this;
        _.assign(self, new BusyMixin());
        self.autoclose = true;

        self.parameters = null;
        self.credits = ko.observable(10);
        self.currentCredits = state.user().vendor().credits();
        self.price = ko.pureComputed(function() {
            return formatting.price(self.credits() * settings.creditPrice);
        });

        self.close = function() {
            dialog.close(self);
        };

        self.keydown = function(sender, e) {
            if (e.keyCode === 27 && self.isMobile) self.close();
            if (e.keyCode === 13 && self.canPurchase()) self.purchase();
            return true;
        };

        self.purchase = function() {
            var credits = _.parseInt(self.credits());
            var data = { credits: credits, parameters: self.parameters };
            self.close();

            self.isBusy(true);
            ga('send', 'event', 'User', 'Purchase credits');
            vendorService.buyCredits(data, function(err, data) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
                document.location = data.paymentUrl;
            });
        };

        self.canPurchase = ko.pureComputed(function() {
            if (_.isNumber(self.credits())) return true;
            if (stringUtils.isEmpty(self.credits())) return false;
            var credits = _.parseInt(self.credits());
            if (credits === 0) return false;
            return true;
        });

        self.activate = function(data) {
        	self.parameters = data;
        };

        self.compositionComplete = function() {
            setTimeout(function() { $('#credits').focus(); }, 100);
        };
    };

    PurchaseCreditsDialog.prototype = new BaseViewModel();
    return PurchaseCreditsDialog;
});

