define('utils/dropzone',['require','jquery','lodash','dropzone','services/state-container'],function(require) {
    var $ = require('jquery');
    var _ = require('lodash');
    var Dropzone = require('dropzone');
    var state = require('services/state-container');
    
    var DropzoneUtils = function() {
        var self = this;
    
        self.createImageDropZone = function(dropzoneId, buttonId, options, appendFunction, callback) {
            
            $('.dz-hidden-input').remove();
            
            options = _.merge(options, {
                clickable: '#' + buttonId,
                acceptedFiles: '.png,.jpg,.gif',
                previewTemplate: '<div></div>',            
            });
            
            var dz = new Dropzone('#' + dropzoneId, options);
            
            dz.on('sending', function(file, xhr, formData) {
                var token = state.accessToken();
                formData.append('access_token', token);
                if (appendFunction) appendFunction(formData);
            });         
            
            dz.on('complete', function(result, res) {
                var response;
                try {
                    response = JSON.parse(result.xhr.responseText);
                }
                catch (e) {
                    return callback('Onverwachte data');
                }                    

                if (result.status === 'error') {
                    if (!result.accepted) return callback('Ongeldig bestandsformaat. Gebruik een JPG, PNG of GIF bestand.');
                    if (!result.xhr.response) return callback('Serverfout');
                    try {
                        var errorMessage = response.error.message ? response.error.message : 'Onverwachte data'; 
                        return callback(errorMessage);
                    }
                    catch (e) {
                        return callback('Onverwachte data');
                    }                    
                }
                else {
                    return callback(null, response);
                }
            });
        };
    };
    
    return new DropzoneUtils();
});
