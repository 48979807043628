define('viewmodels/components/offers-overview',['require','knockout','lodash','toastr','durandal/app','plugins/router','plugins/dialog','utils/string','services/state-container','utils/formatting','text!settings.json','services/customer','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var toastr = require('toastr');
    var app = require('durandal/app');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var stringUtils = require('utils/string');
    var state = require('services/state-container');
    var formatting = require('utils/formatting');
    var settings = JSON.parse(require('text!settings.json'));
    var customerService = require('services/customer');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var filterSorts = {
        'most-recent': {
            sort: function(offer) { return -offer.date(); },
            filter: function(offer) { return true; },
        },
        'lowest-price': {
            sort: function(offer) { return offer.price(); },
            filter: function(offer) { return true; },
        },
        'archived': {
            sort: function(offer) { return -offer.date(); },
            filter: function(offer) { return offer.isArchived(); },
        }
    };

    var OffersOverviewItemViewModel = function(offer) {
        var self = this;
        _.assign(self, new BusyMixin());
        _.assign(self, offer);

        var defaultReply = 'Hey' + (!stringUtils.isEmpty(self.vendor.name()) ? ' ' + self.vendor.name() : '') + ',\n\n' +
            'Ik heb interesse in uw aanbieding!\n\nTyp hier uw eigen bericht.\n\nMet hartelijke groet,';


        self.isOpen = ko.observable(true);

        self.isSentOffer = ko.pureComputed(function() {
            return state.shellMode() === 'vendor';
        });

        self.priceString = formatting.price(offer.price());

        self.archive = function() {
        };

        self.name = ko.pureComputed(function() {
            if (self.isSentOffer()) return offer.lead.customerName();
            else return offer.vendor.name();
        });

        self.profilePicUrl = ko.pureComputed(function() {
            if (self.isSentOffer() && offer.lead.customerProfilePicUrl()) return offer.lead.customerProfilePicUrl();
            else if (!self.isSentOffer() && offer.vendorProfilePicUri()) return offer.vendorProfilePicUri();
            return 'img/default-profile-pic.png';
        });

        self.images = ko.pureComputed(function() {
            return _.map(self.picUrls(), function(url) {
                return {
                    uri: url,
                    thumbUri: url
                };
            });
        });

        self.openGallery = function(image) {
            dialog.getContext().blockoutOpacity = 0.87;
            dialog.show('viewmodels/dialogs/photo-gallery-dialog', image.uri);
        };

        self.sendReply = function() {
            self.isBusy(true);
            customerService.respondOffer(offer.id(), self.reply(), function(err) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
                toastr.success('Je bericht is verzonden naar de aanbieder', 'Verzonden');
                self.isOpen(false);
                self.reply(null);
            });
        };

        self.canSendReply = ko.pureComputed(function() {
            return !self.isBusy() && !stringUtils.isEmpty(self.reply());
        });

        self.canEditReply = ko.pureComputed(function() {
            return !self.isBusy();
        });

        self.reply = ko.observable(defaultReply);

    };

    OffersOverviewItemViewModel.create = function(offer) {
        return new OffersOverviewItemViewModel(offer);
    };

    var testOfferAgainstSearchTerm = function(searchTerm, offer) {
        if (!searchTerm || searchTerm.length < settings.minSearchTermLength) return true;
        if (offer.description().toLowerCase().indexOf(searchTerm) > -1) return true;
        if (offer.vendor.name().toLowerCase().indexOf(searchTerm) > -1) return true;
        return false;
    };

    var testOfferAgainstWoop = function(woop, offer) {
         if (!woop) return true;
         return offer.woopId() === woop.id();
    };

    var OffersOverviewViewModel = function(searchTerm, woop) {
        var self = this;
        self.woop = woop;
        self.currentFilteredOffers = [];
        self.searchTerm = searchTerm || ko.observable('');
        self.limit = ko.observable(null);
        self.mode = state.shellMode;

        self.totalOfferCount = ko.pureComputed(function() {
            var offers = state.shellMode() === 'customer' ? state.offers() : state.sentOffers();
            var woopFilter = _.partial(testOfferAgainstWoop, self.woop);
            if (!woop) return offers.length;
            return _.filter(offers, woopFilter).length;
        });

        self.showOffersForAllWoops = ko.pureComputed(function() {
            return woop ? false : true;
        });

        self.toggleOffer = function(offerToToggle) {
            _.each(self.currentFilteredOffers, function(offer) { if (offer !== offerToToggle) offer.isOpen(false); });
            offerToToggle.isOpen(!offerToToggle.isOpen());
        };

        self.filterSort = ko.observable(filterSorts[settings.defaultOfferFilter]);

        self.filteredOffers = ko.pureComputed(function() {
            var offers = state.shellMode() === 'customer' ? state.offers() : state.sentOffers();
            var searchTermFilter = _.partial(testOfferAgainstSearchTerm, self.searchTerm().toLowerCase());
            var woopFilter = _.partial(testOfferAgainstWoop, self.woop);
            var limit = self.limit() ? self.limit() : offers.length;
            self.currentFilteredOffers = _.chain(offers)
                .filter(woopFilter)
                .filter(searchTermFilter)
                .filter(self.filterSort().filter)
                .sortBy(self.filterSort().sort)
                .take(limit)
                .map(OffersOverviewItemViewModel.create)
                .value();
            return self.currentFilteredOffers;
        });

        self.offersFilterSubscription = app.on('offers:filter').then(function(tag) {
            self.filterSort(filterSorts[tag]);
        });

        self.deactivate = function() {
            self.offersFilterSubscription.off();
        };

        self.newWoop = function() {
            router.navigate('new-woop');
        };

        self.editWoop = function() {
            router.navigate('edit-woop/' + self.woop.id());
        };
    };

    OffersOverviewViewModel.prototype = new BaseViewModel();
    return OffersOverviewViewModel;
});

