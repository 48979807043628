define('services/state-container',['require','knockout','lodash','knockout.validation','models/user','models/vendor','models/woop','models/offer','models/pattern'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    require('knockout.validation');
    var User = require('models/user');
    var Vendor = require('models/vendor');
    var Woop = require('models/woop');
    var Offer = require('models/offer');
    var Pattern = require('models/pattern');

    var StateContainer = function() {
        var self = this;

        // Session state
        self.session = ko.observable(null);
        self.forgotPasswordSession = ko.observable(null);
        self.accessToken = ko.pureComputed(function() {
            if (!self.session()) return null;
            return self.session().access_token;
        });
        self.isLoggedIn = ko.pureComputed(function() {
            return self.session() !== null;
        });
        try { self.session(JSON.parse(localStorage.getItem('session'))); }
        catch(e) { }
        self.session.subscribe(function(data) {
            if (data === null) localStorage.removeItem('session');
            else localStorage.setItem('session', JSON.stringify(data));
        });
        self.firstWoop = {
            keywords: ko.observable(),
            targetKeyword: ko.observable()
        };
        self.isSaunaSession = ko.observable(false);

        // User data
        self.user = ko.validatedObservable(new User());
        self.woops = ko.observableArray([]);
        self.patterns = ko.observableArray([]);
        self.offers = ko.observableArray([]);
        self.sentOffers = ko.observableArray([]);

        // UI state
        self.newWoopReferrer = ko.observable(null);
        self.shellMode = ko.observable('vendor');
        self.introductionMode = ko.observable(false);
        self.selectedWoop = ko.observable(null);
        self.selectedPattern = ko.observable(null);
        self.selectedOffer = ko.observable(null);
        self.selectedMatch = ko.observable(null);
        self.loginReferrer = ko.observable(null);
        self.authUrls = {};

        // CMS texts
        self.cmsTexts = [];

        // Derived data
        self.numberOfCustomerNotifications = ko.pureComputed(function() {
            var newOfferCounts = _.map(self.woops(), function(woop) { return woop.newOfferCount(); });
            return _.sum(newOfferCounts);
        });

        // Helper methods
        self.clear = function() {
            self.firstWoop.keywords(null);
            self.firstWoop.targetKeyword(null);
            self.woops.removeAll();
            self.offers.removeAll();
            self.sentOffers.removeAll();
            self.patterns.removeAll();
            self.user(new User());

            self.newWoopReferrer(null);
            console.log('---customer');

            self.shellMode('customer');
            self.introductionMode(false);
            self.selectedWoop(null);
            self.selectedPattern(null);
            self.selectedOffer(null);
            self.selectedMatch(null);
        };

        self.findWoopById = function(id) {
            id = _.isString(id) ? _.parseInt(id) : id;
            return _.find(self.woops(), function(woop) {
                return woop.id() === id;
            });
        };

        self.findMatchById = function(id) {
            id = _.isString(id) ? _.parseInt(id) : id;
            return _.find(self.woops(), function(woop) {
                return woop.id() === id;
            });
        };

        self.findPatternById = function(id) {
            id = _.isString(id) ? _.parseInt(id) : id;
            return _.find(self.patterns(), function(pattern) {
                return pattern.id() === id;
            });
        };

        self.findOfferById = function(id) {
            id = _.isString(id) ? _.parseInt(id) : id;
            return _.find(self.offers(), function(offer) {
                return offer.id() === id;
            });
        };
    };

    return new StateContainer();
});

