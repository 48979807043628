define('models/validation',['require','knockout','utils/string','knockout.validation'],function(require) {
    var ko = require('knockout');
    var stringUtils = require('utils/string');
    require('knockout.validation');
   
    ko.validation.init({
        insertMessages: false
    });
    
    ko.validation.rules['confirmPasswordMatches'] = {
        validator: function (val, otherValue) {
            return val === ko.validation.utils.getValue(otherValue);
        },
        message: 'Wachtwoorden komen niet overeen',
    };
    
    ko.validation.registerExtenders();
    
    var ValidationRules = function() {
        var self = this;
        
        self.required = function(onlyIf) {
            return { 
                required: { onlyIf:onlyIf } 
            };
        };

        self.requiredEmailAddress = function(onlyIf) {
                return {
                    required: { onlyIf:onlyIf },
                    email: { onlyIf:onlyIf, message:'Ongeldig e-mailadres' }
                };
        };
        
        self.requiredNumber = function(onlyIf) {
            return { 
                required: { onlyIf:onlyIf },
                number: { onlyIf:onlyIf } 
            };
        };
        
        self.requiredPercentage = function(onlyIf) {
            return { 
                required: { onlyIf:onlyIf },
                number: { onlyIf:onlyIf },
                min: { params: 0, onlyIf:onlyIf },
                max: { params: 100, onlyIf:onlyIf }
            };
        };
        
        self.price = function(onlyIf) {
            return { 
                pattern: { params: '^[0-9]+([\.\,][0-9][0-9])?$', onlyIf:onlyIf } 
            };
        };
        
        self.phoneNumber = function(onlyIf) {
            return { 
                validation: [{
                    validator: function(val, params) {
                        if (!onlyIf()) return true;
                        if (stringUtils.isEmpty(val)) return true;
                        var phoneNumberExpression = /[0-9\-\+\ ]+/gi;
                        return val.match(new RegExp(phoneNumberExpression));
                    },
                    message: function(params) {
                        return 'Geen geldig telefoonnumer';
                    }                     
                }]
            };
        };
        
        self.optionalUrl = function(onlyIf) {
            return { 
                validation: [{
                    validator: function(val, params) {
                        if (!onlyIf()) return true;
                        if (stringUtils.isEmpty(val)) return true;
                        var urlExpression = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
                        return val.match(new RegExp(urlExpression));
                    },
                    message: function(params) {
                        return 'Geen geldige URL';
                    }                     
                }]
            };
        };
        
        self.password = function(isNew, oldPassword, newPassword, repeatPassword) {
            
            var result = null;
            oldPassword.isValidCustom(true);
            newPassword.isValidCustom(true);
            repeatPassword.isValidCustom(true);
            
            if (!isNew() && stringUtils.isEmpty(oldPassword())) {
                oldPassword.isValidCustom(false);
                if (!result) result = { isOk: false, errorMessage: 'Vul je oude wachtwoord in' };
            }
            if (stringUtils.isEmpty(newPassword())) {
                newPassword.isValidCustom(false);
                if (!result) result = { isOk: false, errorMessage:'Vul een nieuw wachtwoord in' };
            }
            if (stringUtils.isEmpty(repeatPassword())) {
                repeatPassword.isValidCustom(false);
                if (!result) result = { isOk: false, errorMessage:'Vul de herhaling van je nieuwe wachtwoord in ter controle' };
            }
            if (newPassword() !== repeatPassword()) {
                repeatPassword.isValidCustom(false);
                if (!result) result = { isOk: false, errorMessage:'Herhaling van je wachtwoord komt niet overeen' };
            }
            return result ? result : { isOk: true };
        };
    };
    
    return new ValidationRules();
    
});
