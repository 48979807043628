define('viewmodels/pages/matches-page',['require','knockout','plugins/router','durandal/app','services/state-container','viewmodels/components/matches-overview','viewmodels/components/lead-details','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var state = require('services/state-container');
    var MatchesOverviewViewModel = require('viewmodels/components/matches-overview');
    var LeadDetailsViewModel = require('viewmodels/components/lead-details');
    var BaseViewModel = require('viewmodels/base');

    var MatchesPageViewModel = function() {
        var self = this;

        self.searchTerm = ko.observable('');
        self.leadDetails = ko.observable(null);

        state.selectedMatch.subscribe(function(match) {
            self.leadDetails(match ? new LeadDetailsViewModel(self.pattern, match) : null);
        });

        self.activate = function(patternId, matchId) {
            self.pattern = null;
            if (patternId !== null) self.pattern = state.findPatternById(patternId);
            if (matchId !== null) state.selectedMatch(self.pattern.findMatchById(matchId));
            else state.selectedMatch(null);
            state.shellMode('vendor');
            state.selectedPattern(self.pattern);
            self.matchesOverview = new MatchesOverviewViewModel(self.pattern);
        };

        self.compositionComplete = function() {
            $('#applicationHost').css('overflow-y', 'hidden');
        };

    };

    MatchesPageViewModel.prototype = new BaseViewModel();
    return new MatchesPageViewModel();
});

