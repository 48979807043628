define('viewmodels/components/match-options',['require','lodash','knockout','plugins/router','durandal/app','services/state-container','utils/formatting','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var ko = require('knockout');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var state = require('services/state-container');
    var formatting = require('utils/formatting');
    var BaseViewModel = require('viewmodels/base');

    var MatchOptionsViewModel = function(woopOrPattern, isForPattern) {
        var self = this;
        self.isForPattern = isForPattern;

        self.activate = function() {
            if (self.phoneNumber()) self.showPhoneNumber(true);
        };

        self.toggleCheckbox = function() {
            self.showPhoneNumber(!self.showPhoneNumber());
        };

        woopOrPattern.maxPrice(formatting.priceValueString(woopOrPattern.maxPrice()));
        self.maxPrice = woopOrPattern.maxPrice;
        self.maxDistance = woopOrPattern.maxDistance;
        self.showPhoneNumber = isForPattern ? woopOrPattern.requirePhoneNumber : woopOrPattern.showPhoneNumber;
        self.useLocation = woopOrPattern.useLocation;
        self.postalCode = woopOrPattern.postalCode;
        self.phoneNumber = ko.observable(state.user().phoneNumber());

    };

    MatchOptionsViewModel.prototype = new BaseViewModel();
    return MatchOptionsViewModel;
});

