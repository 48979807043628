define('viewmodels/pages/social-landing-page',['require','lodash','plugins/router','toastr','viewmodels/mixins/static-page','viewmodels/mixins/busy','services/user','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var router = require('plugins/router');
    var toastr = require('toastr');
    var StaticPageMixin = require('viewmodels/mixins/static-page');
    var BusyMixin = require('viewmodels/mixins/busy');
    var userService = require('services/user');
    var BaseViewModel = require('viewmodels/base');

    var SocialLandingPageViewModel = function() {
        var self = this;

        _.assign(self, new BusyMixin());
        self.provider = '';
        self.activate = function(context) {
            self.isBusy(true);
            if (context && 'queryString' in context) {
                if ('google' in context) { self.provider = 'Google'; }
                else if ('facebook' in context) { self.provider = 'Facebook'; }
                else { return false; }
                var loginCallback = function(err, data) {
                    self.isBusy(false);
                    if (err) { toastr.error(err, 'Fout'); }
                    else {
                        toastr.success('Je bent ingelogd');
                        if (context.isMobile) { router.navigate('search'); }
                        else { router.navigate('dashboard'); }
                    }
                };
                userService.signIn(context, loginCallback);
            }
        };
        _.extend(self, new StaticPageMixin());
    };

    SocialLandingPageViewModel.prototype = new BaseViewModel();
    return new SocialLandingPageViewModel();
});

