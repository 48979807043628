define('models/match',['require','knockout','lodash','utils/parsing','models/validation'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var parsing = require('utils/parsing');
    require('models/validation');

    var Match = function() {
        var self = this;
        self.id = ko.observable();
        self.name = ko.observable();
        self.keywords = ko.observable();
        self.formData = ko.observable();
        self.numOffers = ko.observable();
        self.matchingPercentage = ko.observableArray();
        self.date = ko.observable();
        self.isArchived = ko.observable(false);
        self.phoneNumberAvailable = ko.observable(false);
        self.emailAvailable = ko.observable(false);
        self.customer = {
            name: ko.observable(),
            profilePicUrl: ko.observable()
        };
        
        self.getMatchingKeywords = function(pattern) {
            var patternKeywords = parsing.keywords(pattern.keywords());
            var matchKeywords = parsing.keywords(self.keywords());
            return _.intersection(patternKeywords, matchKeywords);
        };

        self.fromMatch = function(match) {

        };
        
        self.toDTO = ko.pureComputed(function() {
            return {

            };
        });
    };
    
    Match.clone = function(match) {
        var clonedMatch = new Match();
        clonedMatch.fromMatch(match);
        return clonedMatch;
    };
    
    Match.fromDTO = function(dto) {
        var match = new Match();
        match.id(dto.id);
        match.name(dto.name);
        match.keywords(dto.keywords);
        match.formData(dto.formData);
        match.matchingPercentage(dto.score);
        match.numOffers(dto.numOffers);
        match.date(dto.timestamp ? new Date(dto.timestamp*1000) : parsing.date(dto.created_at));
        match.phoneNumberAvailable(dto.showPhone);
        match.customer.name(dto.customer.name);
        match.customer.profilePicUrl(dto.customer.profilePicUrl);
        // match.isArchived(dto.isArchived);
        // match.emailAvailable(dto.emailAvailable);
        return match;
    };
    
    return Match;
});
