define('services/vendor',['require','knockout','lodash','async','services/state-container','utils/repository','services/vendor-api','services/generic-api','services/customer','utils/dropzone','models/pattern','models/woop','models/match','models/offer','models/transaction'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var async = require('async');
    var state = require('services/state-container');
    var repository = require('utils/repository');    
    var vendorApi = require('services/vendor-api');
    var genericApi = require('services/generic-api');    
    var customerService = require('services/customer');    
    var dropzoneUtils = require('utils/dropzone');

    var Pattern = require('models/pattern');
    var Woop = require('models/woop');
    var Match = require('models/match');
    var Offer = require('models/offer');
    var Transaction = require('models/transaction');

    var withErrorParsing = genericApi.withErrorParsing;
    var parseError = genericApi.parseError;

    var VendorService = function() {
        var self = this;
        
        self.loadUser = function(callback) {
            async.series(
                [
                    _.bind(self.getMyProfile, self),
                    _.bind(self.getPatterns, self),
                    _.bind(self.getOffers, self),
                    _.bind(self.getTransactions, self),
                    _.bind(self.getBalance, self)
                ], 
                withErrorParsing(callback)
            );
        };
        
        self.loadMatchesForPattern = function(pattern, callback) {
            self.searchLeads(pattern.toSearchDTO(), function(err, result) {
                var matchesWereUpdated = false;
                if (err) return callback(err);
                if (result) {
                    var matches = _.map(result.data, function(dto) { return Match.fromDTO(dto); });
                    matchesWereUpdated = repository.diff(pattern.matches, matches, 'fromMatch');
                }
                pattern.areMatchesLoaded(true);
                callback(null, matchesWereUpdated);
            });
        };
        
        self.loadMatches = function(callback) {
            async.mapSeries(
                state.patterns(),
                function(pattern, callback) {
                    self.loadMatchesForPattern(pattern, callback);
                },
                function(err, results) {
                    if (_.isUndefined(results)) return callback(err, false);
                    callback(err, _.any(results));
                }
            );
        };
        
        self.refreshPatterns = function(callback) {
            self.getPatterns(function(err) {
                if (err) return callback(withErrorParsing(err));
                callback(null);
                self.loadMatches(_.noop);
            });
        };
        
        self.updateProfile = function(data, callback) {
            data.access_token = state.accessToken();
            vendorApi.updateProfile(data, withErrorParsing(callback));
        };
        
        self.getMyProfile = function(callback) {
            async.waterfall(
                [
                    function(callback) {
                        vendorApi.getMyProfile({ access_token: state.accessToken() }, callback);    
                    },
                    function(data, callback) {
                        if (data) {
                            state.user().vendor().fromDTO(data);
                            callback(null, data);
                        }
                        else {
                            callback('Onverwachte data van server');
                        }
                    }
                ], 
                withErrorParsing(callback)
            );  
        };    
    
        self.getPatterns = function(callback) {
            var data = { };
            data.access_token = state.accessToken();
            vendorApi.getPatterns(data, function(err, data) {
                if (!err) {
                    if (data && data.data) {
                        var patterns = _.map(data.data, Pattern.fromDTO);
                        repository.diff(state.patterns, patterns, 'fromPatternWithoutMatches');
                        return callback(null, data);
                    }
                    else {
                        return callback('Onverwachte data van server');
                    }
                }
                callback(genericApi.parseError(err));            
            });
        };
    
        self.addPattern = function(data, callback) {
            data.access_token = state.accessToken();
            
            var patternId;
            
            async.waterfall(
                [
                    function(callback) {
                        vendorApi.addPattern(data, withErrorParsing(callback));
                    },
                    function(data, callback) {
                        patternId = data.pattern_id;
                        self.refreshPatterns(callback);
                    },
                ], 
                function(err, data) {
                    callback(parseError(err), patternId);
                }
            );           
        };    
    
        self.updatePattern = function(patternId, data, callback) {
            data.access_token = state.accessToken();
            data['patternId'] = patternId;
            
            var pattern = state.findPatternById(patternId);
            
            async.waterfall(
                [
                    function(callback) {
                        vendorApi.updatePattern(data, withErrorParsing(callback));
                    },
                    function(data, callback) {
                        pattern.matches([]);
                        pattern.areMatchesLoaded(false);
                        self.refreshPatterns(callback);
                    }
                ], 
                withErrorParsing(callback)
            );   
        };
    
        self.archivePattern = function(patternId, callback) {
            var data = { patternId : patternId };
            data.access_token = state.accessToken();
    
            async.waterfall(
                [
                    function(callback) {
                        vendorApi.removePattern(data, withErrorParsing(callback));
                    },
                    function(data, callback) {
                        self.refreshPatterns(callback);
                    }
                ], 
                withErrorParsing(callback)
            );           
        };    
        
        self.restorePattern = function(patternId, callback) {
            var data = { patternId : patternId };
            data.access_token = state.accessToken();
    
            async.waterfall(
                [
                    function(callback) {
                        vendorApi.restorePattern(data, withErrorParsing(callback));
                    },
                    function(data, callback) {
                        self.refreshPatterns(callback);
                    }
                ], 
                withErrorParsing(callback)
            );           
        };   
        
        self.initOfferPicUpload = function(dropzoneId, buttonId, getPicNumberFunction, callback) {
            var appendPicNumber = function(formData) { 
                formData.append('picNumber', getPicNumberFunction()); 
            };
            dropzoneUtils.createImageDropZone(dropzoneId, buttonId, vendorApi.getOfferPicUploadOptions(), appendPicNumber, callback);
        };
    
        self.getOffers = function(callback) {
            vendorApi.getOffers({ access_token: state.accessToken() }, function(err, result) {
                if (err) return callback(err);
                if (result && result.data) {
                    
                    var offers = _.map(result.data, Offer.fromDTO);
                    repository.diff(state.sentOffers, offers, 'fromOffer');
                    callback(null);
                }
                else {
                    return callback('Onverwachte data van server');
                }
            });
        };           
        
        self.addOffer = function(data, callback) {
            data.access_token = state.accessToken();
            async.series(
                [
                    function(callback) {
                        vendorApi.addOffer(data, callback);
                    },
                    function() {
                        self.getOffers(callback);
                    }
                ], 
                withErrorParsing(callback)
            );
        };    
        
        self.removeOffer = function(offerId, callback) {
            var data = { offerId : offerId };
            data.access_token = state.accessToken();
            vendorApi.removeOffer(data, withErrorParsing(callback));
        };       
        
        self.searchLeads = function(data, callback) {
            data.access_token = state.accessToken();
            vendorApi.searchLeads(data, withErrorParsing(callback));
        };
    
        self.buyLead = function(leadId, callback) {
            var data = { leadId: leadId };
            data.access_token = state.accessToken();
            vendorApi.buyLead(data, withErrorParsing(callback));
        };       
        
        self.buyCredits = function(data, callback) {
        	// some rearranging
        	var data2 = {};        	
        	if ( data.parameters ) {
	        data2 = data.parameters;
	        }
			data2.credits = data.credits;
            data2.access_token = state.accessToken();
           	vendorApi.buyCredits(data2, withErrorParsing(callback));            
        };    
        
        self.getBalance = function(callback) {
            var data = { };
            data.access_token = state.accessToken(); 
            vendorApi.getBalance(data, function(err, result) {
                if (err) return callback(parseError(err));
                if (result && _.has(result, 'creditBalance')) {
                    state.user().vendor().credits(result.creditBalance);
                    return callback(null);
                }
                else {
                    return callback('Onverwachte data van server');
                }
            });         
        };
        
        self.getTransactionStatus = function(id, callback) {
            var data = { transactionId : id };
            data.access_token = state.accessToken();
            vendorApi.getTransactionStatus(data, withErrorParsing(callback));              
        };
        
        self.getTransactions = function(callback) {
            var data = { access_token: state.accessToken() };
            vendorApi.getTransactions(data, function(err, result) {
                if (err) return callback(parseError(err));
                var transactions = _.map(result.transactions, Transaction.fromDTO);
                
                var oldClosedTransactions = _.filter(state.user().vendor().transactions(), function(t) { return t.isClosed(); }); 
                var newClosedTransactions = _.filter(transactions, function(t) { return t.isClosed(); });
                var transactionsWereClosed = newClosedTransactions.length > oldClosedTransactions.length; 
                
                var oldAbortedTransactions = _.filter(state.user().vendor().transactions(), function(t) { return t.isAborted(); }); 
                var newAbortedTransactions = _.filter(transactions, function(t) { return t.isAborted(); });
                var transactionsWereAborted = newAbortedTransactions.length > oldAbortedTransactions.length; 
    
                state.user().vendor().transactions(transactions);
                callback(null, transactionsWereClosed, transactionsWereAborted);
            });
        };
        
        self.updateTransactionsIfNeeded = function(callback) {
            if (!state.user().isVendor()) return callback(null);
            var hasOpenTransaction = _.find(state.user().vendor().transactions(), function(transaction) {
                return transaction.isOpen();
            });
            if (!hasOpenTransaction) return callback(null);
            self.getTransactions(callback); 
        };
    };

    return new VendorService();
});
