define('viewmodels/pages/refine-search-page',['require','lodash','knockout','plugins/router','durandal/app','toastr','services/state-container','utils/string','services/customer','viewmodels/components/match-options','models/woop','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var ko = require('knockout');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var toastr = require('toastr');
    var state = require('services/state-container');
    var stringUtils = require('utils/string');
    var customerService = require('services/customer');
    var MatchOptionsViewModel = require('viewmodels/components/match-options');
    var Woop = require('models/woop');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var RefineSearchPageViewModel = function(id) {
        var self = this;
        _.assign(self, new BusyMixin());

        self.save = function() {
            self.isBusy(true);
            state.selectedWoop().fromWoop(self.woop);
            customerService.updateWoop(self.woop.id(), self.woop.toDTO(), function(err, result) {
                self.isBusy(false);
                if (err) return toastr.error('Kan woop niet bijwerken', 'Fout!');
                toastr.success('Woop is bijgewerkt');
            });
        };

        self.canSave = ko.pureComputed(function() {
            return !stringUtils.isEmpty(self.woop.keywords());
        });

        self.activate = function(woopId) {
            var woop = state.findWoopById(woopId);
            state.selectedWoop(woop);
            state.shellMode('customer');

            self.woop = Woop.clone(woop);
            self.matchOptions = ko.observable(new MatchOptionsViewModel(self.woop));
            self.keywords = self.woop.keywords;
        };

        self.compositionComplete = function() {
            $('#applicationHost').css('overflow-y', 'hidden');
        };

    };

    RefineSearchPageViewModel.prototype = new BaseViewModel();
    return RefineSearchPageViewModel;
});

