define('models/sauna-form',['require'],function(require) {

    var currentSection = null;

    const header = function(text) {
        currentSection = text;
        return { type: 'header', text: text };
    };

    const check = function(desc, info) {
        var res = { type: 'check', desc: desc, key: currentSection + '--' + desc, info: info || '' };
        return res;
    };

    return [
        [
            header('Sauna soort'),
            check('Infrarood sauna'),
            check('Finse sauna'),
            check('Combinatie sauna'),
            check('Meer info over de verschillen'),

            header('Sauna doel'),
            check('Ontspannen'),
            check('Ontgiften'),
            check('Pijnverlichting'),
            check('Weerstand verhogen'),
            check('Snelle infraroodsessies'),
            check('Combinatie van bovenstaande'),

            header('Straler opties'),
            check('Keramische stralers (IR-C)'),
            check('Carbon stralers (IR-C)'),
            check('Magnesium oxide stralers (IR-B)'),
            check('Full spectrum stralers (IR-A)'),
            check('Combinatie DUO heaters (IR A+B)'),
            check('Combinatie TRIPLE heaters (IR A+B+C) TIP!'),
            check('Meer uitleg over stralers'),

            header('Overige accessoires'),
            check('Red ceder hout'),
            check('LED-kleurentherapie'),
            check('Sterrenhemel'),
            check('Zijwand heater'),
            check('Zoutwand'),
            check('Ergonomische ruggensteun'),
            check('Aromatherapie'),
            check('Zout vernevelaar'),
            check('Meer uitleg & prijzen accessoires'),
        ],
        [
            header('Aantal personen'),
            check('1 persoon'),
            check('2 personen'),
            check('3 personen'),
            check('4 personen'),
            check('5 personen'),
            check('6 personen'),
            check('Overige / weet ik nog niet'),

            header('Maximale breedte'),
            check('90 cm'),
            check('110 cm'),
            check('120 cm'),
            check('130 cm'),
            check('150 cm'),
            check('180 cm'),
            check('210 cm'),
            check('Overig / weet ik nog niet'),

            header('Maximale diepte'),
            check('90 cm'),
            check('100 cm'),
            check('110 cm'),
            check('120 cm'),
            check('130 cm'),
            check('150 cm'),
            check('180 cm'),
            check('210 cm'),
            check('Overig / weet ik nog niet'),
        ]
    ];
});
