define('viewmodels/dialogs/sign-in-dialog',['require','knockout','toastr','plugins/router','plugins/dialog','utils/string','services/user','services/state-container','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var stringUtils = require('utils/string');
    var userService = require('services/user');
    var state = require('services/state-container');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var SignInDialog = function() {
        var self = this;
        _.assign(self, new BusyMixin());
        self.autoclose = false;
        if (self.isMobile) self.autoclose = true;

        self.close = function() {
            dialog.close(this);
        };

        self.emailAddress = ko.observable();
        self.password = ko.observable();
        self.titleHtml = ko.pureComputed(function(){
            if (self.inRegisterMode()) {
                if (self.isDoneRegistering()) return '<strong>Welkom!</strong>';
                return '<strong>Welkom!</strong> registreer hieronder';
            }
            return '<strong>Hey wooper!</strong> log maar gauw in';
        });
        self.registerEmailAddress = ko.observable();
        self.registerPassword = ko.observable();
        self.registerPasswordRepeated = ko.observable();
        self.registerButtonLabel = ko.pureComputed(function(){
            if (self.inRegisterMode()) return 'welkom!';
            return 'ik ben nieuw';
        });
        self.inRegisterMode = ko.observable(false);
        self.isDoneRegistering = ko.observable(false);

        self.googleAuthUrl = state.authUrls['google'];
        self.facebookAuthUrl = state.authUrls['facebook'];

        self.forgotPassword = function() {
            dialog.close(this);
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/forgot-password-dialog');
        };

        self.go = function() {
            self.isBusy(true);
            var params = { emailAddress : self.emailAddress(), password : self.password() };
            userService.signIn(params,
                function(err, data) {
                    self.isBusy(false);
                    if (err) return toastr.error(err, 'Fout');
                    dialog.close(self);
                    //if ( self.isMobile !== true ) router.navigate(state.loginReferrer() ? state.loginReferrer() : 'dashboard');
                    toastr.success('Je bent ingelogd');
                    if (self.callback) self.callback();
                }
            );
        };

        self.isGoButtonEnabled = ko.pureComputed(function() {
            if (self.isBusy()) return false;
            if (self.inRegisterMode()) {
                if (stringUtils.isEmpty(self.registerEmailAddress())) return false;
                if (stringUtils.isEmpty(self.registerPassword())) return false;
                if (stringUtils.isEmpty(self.registerPasswordRepeated())) return false;
            }
            else {
                if (stringUtils.isEmpty(self.emailAddress())) return false;
                if (stringUtils.isEmpty(self.password())) return false;
            }
            return true;
        });

        self.keydown = function(sender, e) {
            if (e.keyCode === 27 && self.isMobile) self.close();
            if (e.keyCode === 13) {
                if (e.currentTarget.id === 'email') $('#password').focus();
                else if (e.currentTarget.id === 'password' && self.inRegisterMode()) $('#password-repeated').focus();
                else if (self.isGoButtonEnabled()) {
                    if (self.inRegisterMode()) { self.register(); }
                    else { self.go(); }
                }
            }
            return true;
        };

        self.compositionComplete = function() {
            setTimeout(function() { $('#email').focus(); }, 100);
        };

        self.activate = function(context) {
            self.fromVerification = (context && 'fromVerification' in context);
            if (context && context.callback) self.callback = context.callback;
        };

        self.onRegisterClick = function() {
            self.inRegisterMode(true);
        };

        self.register = function() {
            self.isBusy(true);
            userService.registerVendor(self.registerEmailAddress(), self.registerPassword(),
                self.registerPasswordRepeated(),
                state.firstWoop.keywords(),
                state.firstWoop.targetKeyword(),
                function(err, data) {
                    self.isBusy(false);
                    if (err) {
                        self.inRegisterMode(false);
                        return toastr.error(err, 'Fout');
                    }
                    toastr.success('Welkom!', 'Account is aangemaakt');
                    dialog.close(self);
                    self.isDoneRegistering(true);
                }
            );
        };

        self.blockoutClass = 'brown';
    };

    SignInDialog.prototype = new BaseViewModel();
    return SignInDialog;
});

