define('utils/repository',['require','knockout','lodash'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    
    var Repository = function() {
        var self = this;
        
        var cmp = function(lhs, rhs) {
            if (_.isNull(lhs.id()) || _.isNull(rhs.id())) return false;
            if (_.isUndefined(lhs.id()) || _.isUndefined(rhs.id())) return false;
            return lhs.id() === rhs.id();
        };
        
        self.diff = function(currentItems, newItems, updateFunctionName) {
            if (!_.isArray(currentItems())) throw 'expected currentItems() to be an array';
            if (!_.isArray(newItems)) throw 'expected newItems to be an array';
            
            var itemsToAdd = _.filter(newItems, function(newItem) {
                return _.isUndefined(_.find(currentItems(), _.partial(cmp, newItem)));
            });
            
            var itemsToRemove = _.filter(currentItems(), function(currentItem) {
                if (_.isUndefined(currentItem.id()) || _.isNull(currentItem.id())) return true; 
                return _.isUndefined(_.find(newItems, _.partial(cmp, currentItem)));
            });
            
            var itemsToUpdate = _.filter(newItems, function(newItem) {
                return !_.isUndefined(_.find(currentItems(), _.partial(cmp, newItem)));
            });
            
            _.each(itemsToRemove, function(itemToRemove) {
                currentItems.remove(itemToRemove);
            }); 
            
            _.each(itemsToAdd, function(item) {
                currentItems.push(item);
            }); 
            
            _.each(itemsToUpdate, function(itemToUpdate) {
                var currentItem = _.find(currentItems(), _.partial(cmp, itemToUpdate)); 
                var newItem = _.find(newItems, _.partial(cmp, itemToUpdate));
                currentItem[updateFunctionName](newItem);
            }); 
            
            return itemsToAdd.length > 0;
        };
    };
    
    return new Repository();
});
