define('viewmodels/pages/dashboard-page',['require','knockout','jquery','lodash','text!settings.json','toastr','plugins/router','services/state-container','models/woop','viewmodels/mixins/busy','viewmodels/base','viewmodels/pages/not-supported-mobile-page','plugins/dialog'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var _ = require('lodash');
    var settings = JSON.parse(require('text!settings.json'));
    var toastr = require('toastr');
    var router = require('plugins/router');
    var state = require('services/state-container');
    var Woop = require('models/woop');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');
    var NotSupportedMobilePageViewModel = require('viewmodels/pages/not-supported-mobile-page');
    var dialog = require('plugins/dialog');


    var DashboardPageViewModel = function() {

        var self = this;
        _.assign(self, new BusyMixin());
        self.notSupportedMobile = new NotSupportedMobilePageViewModel();

        state.shellMode('customer');

        self.activate = function() {
            if (!state.isLoggedIn()) {
                // self.createDummyWoops();
            }
            state.selectedWoop(null);
            state.selectedPattern(null);

            // add dummy divs with all background images. this prevents flickering in background transitions later.
            // var images = [];
            // for(var i=self.bgMin; i<=self.bgMax; i++) {
            //     var bgUrl = 'img/dashboard-backgrounds/bg0'+i+'.jpg';
            //     $('body').append('<img src='+bgUrl+' class="dummy-image dummy-image-'+i+'" />');
            // }
            // self.addBackgroundTimeout();
        };

        self.bgIndex = 1;
        self.bgMin = 1;
        self.bgMax = 3;
        self.bgChangeTimeout = null;
        self.changeBackground = function () {
            var bgDiv = $('#dashboard-bg');
            if (bgDiv) {
                var bgUrl = 'img/dashboard-backgrounds/bg0'+self.bgIndex+'.jpg';
                var newIndex = self.bgIndex;
                while (newIndex === self.bgIndex){
                    newIndex = Math.floor((Math.random() * self.bgMax) + self.bgMin);
                }
                self.bgIndex = newIndex;

                bgDiv.css('background-image', 'url('+bgUrl+')');
                bgDiv.css('transition', 'background 1s ease-in');

                self.addBackgroundTimeout();
            }
        };

        self.addBackgroundTimeout = function() {
            clearTimeout(self.bgChangeTimeout);
            self.bgChangeTimeout = setTimeout(function () {
                self.changeBackground();
            }, 60*1000);
        };

        self.fadeIn = function(element, index, data) {
            $(element).filter('li').css('opacity', '1');
        };

        self.deactivate = function() {
            clearTimeout(self.bgChangeTimeout);
        };

        self.newWoop = function() {
            router.navigate('new-woop');
        };

        self.newPattern = function() {
            router.navigate('patterns');
        };

        self.createDummyWoops = function() {
            if (self.cmsTexts && self.cmsTexts['dashboard'] && self.cmsTexts['dashboard']['examplewoops']){
                _.each(self.cmsTexts['dashboard']['examplewoops'], function(d){ state.woops.push(Woop.createNew(d, null)); });
            }
            else {
                _.each(settings.dummyWoops, function(d){ state.woops.push(Woop.createNew(d, null)); });
            }
        };

        self.isActive = ko.observable(false);
        self.compositionComplete = function() {
            self.isActive(true);
        }

    };

    DashboardPageViewModel.prototype = new BaseViewModel();
    return DashboardPageViewModel;
});

