/* Landing Service
 * Decides the fate of unknown routes, on landing.
 */
define('services/landing',['require','utils/config'],function(require) {

  var config = require('utils/config');

  var LandingService = function() {
      var self = this;

      self.decideModule = function(instruction) {
          instruction.config.moduleId = 'viewmodels/pages/not-found-page';

          // default routes for mobile and desktop
          var mq = window.matchMedia(config.mobileMediaMatch);
          if (mq.matches) {
              instruction.config.moduleId = 'viewmodels/pages/search-page';
          }
          else {
              instruction.config.moduleId = 'viewmodels/pages/dashboard-page';
          }

          // social media landing page
          if (window.location.search) {
              var params = { queryString : window.location.search, isMobile: mq.matches };
              if (typeof history.pushState == 'function') history.pushState(null, "", location.href.split("?")[0]);
              if (params.queryString.indexOf("code") !== -1) {
                  // facebook puts #_=_ after its oauth parameters (why, no one knows)
                  if (params.queryString.indexOf("code") < 4 && instruction.fragment.indexOf("_=_") !== -1) {
                      params['facebook'] = true;
                      instruction.fragment = '';
                      instruction.config.title = "Facebook login";
                  }
                  else {
                      params['google'] = true;
                      instruction.config.title = "Google login";
                  }
                  instruction.params = params;
                  instruction.config.moduleId = 'viewmodels/pages/social-landing-page';
              }
          }
          return instruction;
      };

  };

  return new LandingService();

});

