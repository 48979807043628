define('viewmodels/pages/welcome-page',['require','knockout','lodash','toastr','plugins/router','services/user','services/customer','services/vendor','durandal/app','utils/string','services/state-container','models/user','viewmodels/base','viewmodels/mixins/busy'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var toastr = require('toastr');
    var router = require('plugins/router');
    var userService = require('services/user');
    var customerService = require('services/customer');
    var vendorService = require('services/vendor');
    var app = require('durandal/app');
    var stringUtils = require('utils/string');
    var state = require('services/state-container');
    var User = require('models/user');
    var BaseViewModel = require('viewmodels/base');

    var BusyMixin = require('viewmodels/mixins/busy');

    var WelcomePageViewModel = function() {
        var self = this;
        _.assign(self, new BusyMixin());



        //self.text = self.cmsTexts['dashboard']['welcome']['text'];
        //self.text2 = self.cmsTexts['dashboard']['welcome']['text2'];

        self.emailAddress = ko.observable();
        self.oldPassword = ko.observable();
        self.password = ko.observable();
        self.repeatPassword = ko.observable();
        self.oldPassword.isValidCustom = ko.observable(true);
        self.password.isValidCustom = ko.observable(true);
        self.repeatPassword.isValidCustom = ko.observable(true);
        self.isPasswordFormVisible = ko.observable(false);
        self.isEmailFormVisible = ko.pureComputed(function() {
            return !self.isPasswordFormVisible();
        });
        self.isRegistering = ko.observable(false);
        self.isSavingPassword = ko.observable(false);
        self.canSaveEmail = ko.pureComputed(function() {
            return !self.isRegistering() && !stringUtils.isEmpty(self.emailAddress());
        });
        self.canSavePassword = ko.pureComputed(function() {
            return !self.isSavingPassword() && !stringUtils.isEmpty(self.password()) && !stringUtils.isEmpty(self.repeatPassword());
        });
        self.canSkip = ko.pureComputed(function() {
            return !self.isSavingPassword();
        });

        self.emailKeydown = function(sender, e) {
            if (e.keyCode === 13 && self.canSaveEmail()) self.saveEmailAddress();
            return true;
        };

        self.passwordKeydown = function(sender, e) {
            if (e.keyCode === 13 && !stringUtils.isEmpty(self.password())) $('#woopan-password-repeat').focus();
            return true;
        };

        self.repeatPasswordKeydown = function(sender, e) {
            if (e.keyCode === 13 && !stringUtils.isEmpty(self.repeatPassword())) self.savePassword();
            return true;
        };

        self.saveEmailAddress = function() {
            self.isBusy(true);
            self.isRegistering(true);
            userService.registerCustomer(
                self.emailAddress(),
                null, null,
                state.firstWoop.keywords(),
                state.firstWoop.targetKeyword(),
                null,
                null,
                function(err, data) {
                    self.isBusy(false);
                    self.isRegistering(false);
                    if (err) return toastr.error(err, 'Fout');
                    state.user().isUnregistered(false);
                    ga('send', 'event', 'New user', 'Create account', self.emailAddress());
                    toastr.success('Profiel aangemaakt');
                    router.navigate('edit-woop/' + state.woops()[0].id());
                }
            );
        };

        self.activate = function(context) {
            state.introductionMode(true);
            state.selectedWoop(null);
            state.selectedPattern(null);
        };

        self.compositionComplete = function() {
            $('#applicationHost').css('overflow-y', 'hidden');
            setTimeout(function() { $('#email').focus(); }, 200);
        };
    };

    WelcomePageViewModel.prototype = new BaseViewModel();
    return WelcomePageViewModel;
});

