define('viewmodels/dialogs/reverse-ad-info-dialog',['require','knockout','durandal/app','lodash','plugins/router','plugins/dialog','services/state-container','toastr','utils/string','models/woop','services/customer','services/user','viewmodels/mixins/busy','viewmodels/components/panda','viewmodels/components/lead-form-data','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var app = require('durandal/app');
    var _ = require('lodash');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var state = require('services/state-container');
    var toastr = require('toastr');
    var stringUtils = require('utils/string');
    var Woop = require('models/woop');
    var customerService = require('services/customer');
    var userService = require('services/user');
    var BusyMixin = require('viewmodels/mixins/busy');
    var PandaViewModel = require('viewmodels/components/panda');
    var LeadFormDataViewModel = require('viewmodels/components/lead-form-data');
    var BaseViewModel = require('viewmodels/base');

    var ReverseAdDialogViewModel = function() {
        var self = this;
        self.autoclose = false;
        if (self.isMobile) self.autoclose = true;
        _.assign(self, new BusyMixin());
        
        self.blockoutClass = 'brown';

        self.close = function() {
            dialog.close(self);
        };

        self.closeDialogSubscription = app.on('dialogs:close').then(function() {
            dialog.close(self);
        });
    };

    ReverseAdDialogViewModel.prototype = new BaseViewModel();
    return ReverseAdDialogViewModel;
});

