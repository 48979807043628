define('models/transaction',['require','knockout','lodash','utils/parsing'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var parsing = require('utils/parsing');
    
    var Transaction = function() {
        var self = this;
        self.id = ko.observable();
        self.credits = ko.observable();
        self.price = ko.observable();
        self.timestamp = ko.observableArray();
        self.status = ko.observable();
        self.isOpen = ko.pureComputed(function() { return self.status() === 'open'; });
        self.isClosed = ko.pureComputed(function() { return self.status() === 'closed'; });
        self.isAborted = ko.pureComputed(function() { return self.status() === 'aborted'; });
    };
    
    Transaction.fromDTO = function(dto) {
        var transaction = new Transaction();
        transaction.id(dto.id);
        transaction.credits(dto.amountInCredits);
        transaction.price(dto.amountInCurrency);
        transaction.timestamp(dto.timestamp ? new Date(dto.timestamp*1000) : parsing.date(dto.created_at));
        transaction.status(dto.status);
        return transaction;
    };
    
    return Transaction;
});
