define('viewmodels/dialogs/email-dialog',['require','knockout','jquery','durandal/app','plugins/router','plugins/dialog','toastr','utils/string','services/user','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var app = require('durandal/app');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var toastr = require('toastr');
    var stringUtils = require('utils/string');
    var userService = require('services/user');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var EmailDialog = function() {
        var self = this;
        this.autoclose = true;
        _.assign(self, new BusyMixin());

        self.emailAddress = ko.observable();
        self.password = ko.observable();
        self.repeatPassword = ko.observable();

        self.go = function() {
            self.isBusy(true);
            userService.registerCustomer(
                self.emailAddress(),
                self.password(),
                self.repeatPassword(),
                self.keywords,
                self.targetKeyword,
                null,
                function(err, data) {
                    self.isBusy(false);
                    if (err) return toastr.error(err, 'Fout');
                    self.close();
                    ga('send', 'event', 'New user', 'Create account', self.emailAddress());
                    //router.navigate('welcome');
                    toastr.success('Profiel aangemaakt');

                    var dialogOptions = {
                        title: 'Dankjewel',
                        text: 'Dankjewel',
                        confirmButton: 'Okee',
                        hasCancelButton: false,
                        callback: function() { console.log("I'm not here. This isn't happening."); }
                    };
                    dialog.getContext().blockoutOpacity = 0.66;
                    dialog.show('viewmodels/dialogs/confirmation-dialog', dialogOptions);

                }
            );
        };

        self.keydown = function(sender, e) {
            if (e.keyCode === 27 && self.isMobile) self.close();
            if (e.keyCode === 13 && self.isGoButtonEnabled()) self.go();
            return true;
        };

        self.isGoButtonEnabled = ko.pureComputed(function() {
            if (self.isBusy()) return false;
            if (stringUtils.isEmpty(self.emailAddress())) return false;
            if (stringUtils.isEmpty(self.password())) return false;
            if (stringUtils.isEmpty(self.repeatPassword())) return false;
            return true;
        });

        self.activate = function(params) {
            self.keywords = params.keywords;
            self.targetKeyword = params.targetKeyword;
        };

        self.deactivate = function() {
            self.closeDialogSubscription.off();
        };

        self.compositionComplete = function() {
            setTimeout(function() { $('#email').focus(); }, 100);
        };

        self.close = function() {
            dialog.close(self);
        };

        self.closeDialogSubscription = app.on('dialogs:close').then(function() {
            dialog.close(self);
        });

        self.blockoutClass = 'brown';
    };

    EmailDialog.prototype = new BaseViewModel();
    return EmailDialog;
});

