define('utils/toast-callbacks',['require','toastr'],function(require) {
    var toastr = require('toastr');
    
    var ToastCallbacks = function() {
        var self = this;
        
        self.reportErrors = function(err) {
            if (err) toastr.error(err, 'Fout');
        };
        
        self.matches = self.reportErrors;

        self.update = function(err, result) {
            if (err) return toastr.error(err, 'Fout');
            if (result.offersWereUpdated) toastr.info('Er zijn nieuwe aanbiedingen');
            if (result.matchesWereUpdated) toastr.info('Er zijn nieuwe matches');
            if (result.transactionsWereApproved) toastr.info('Betaling goedgekeurd');
            if (result.transactionsWereAborted) toastr.warning('Betaling afgekeurd');
        };

    };
    
    return new ToastCallbacks();
});
