define('viewmodels/pages/about-page',['require','lodash','viewmodels/mixins/static-page','viewmodels/base'],function(require) {
    var _ = require('lodash');
    var StaticPageMixin = require('viewmodels/mixins/static-page');
    var BaseViewModel = require('viewmodels/base');

    var AboutPageViewModel = function() {
        var self = this;
        _.extend(self, new StaticPageMixin());
    };

    AboutPageViewModel.prototype = new BaseViewModel();
    return new AboutPageViewModel();
});

