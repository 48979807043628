define('viewmodels/pages/new-woop-page',['require','knockout','lodash','async','plugins/router','durandal/app','toastr','services/state-container','utils/string','services/customer','models/woop','viewmodels/components/match-options','viewmodels/components/lead-form-data','viewmodels/pages/not-supported-mobile-page','viewmodels/components/panda','viewmodels/mixins/busy','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var async = require('async');
    var router = require('plugins/router');
    var app = require('durandal/app');
    var toastr = require('toastr');
    var state = require('services/state-container');
    var stringUtils = require('utils/string');
    var customerService = require('services/customer');
    var Woop = require('models/woop');
    var MatchOptionsViewModel = require('viewmodels/components/match-options');
    var LeadFormDataViewModel = require('viewmodels/components/lead-form-data');
    var NotSupportedMobilePageViewModel = require('viewmodels/pages/not-supported-mobile-page');
    var PandaViewModel = require('viewmodels/components/panda');
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');

    var NewWoopPageViewModel = function() {
        var self = this;
        _.assign(self, new BusyMixin());
        self.notSupportedMobile = new NotSupportedMobilePageViewModel();

        self.referrer = ko.observable();
        self.woop = ko.validatedObservable(new Woop());
        self.woop().postalCode(_.isString(state.user().postalCode()) ? state.user().postalCode().replace(' ', '') : null);

        self.keywords = self.woop().keywords;
        self.targetKeyword = ko.observable(null);
        self.panda = new PandaViewModel(self.keywords, self.targetKeyword);

        self.add = function() {
            self.woop().isValidating(true);
            if (self.useFormData()) {
                self.woop().formData(self.formData.serialized());
            }
            if (self.woop.isValid()) {
                self.isBusy(true);
                async.series([
                    function(callback) {
                        if (!self.matchOptions.showPhoneNumber() || self.matchOptions.phoneNumber() === state.user().phoneNumber()) return callback(null);
                        customerService.updatePhoneNumber(self.matchOptions.phoneNumber(), callback);
                    },
                    function(callback) {
                        customerService.addWoop(self.woop().toDTO(), callback);
                    }
                ], function(err, results) {
                    self.isBusy(false);
                    if (err) return toastr.error(err);
                    toastr.success('Nieuwe woop is geplaatst');
                    state.newWoopReferrer('new-woop');
                    router.navigate('dashboard');
                });
            }
            else {
                 toastr.error('Niet alle velden zijn juist ingevuld', 'Fout');
            }
        };

        self.canAdd = ko.pureComputed(function() {
            return !stringUtils.isEmpty(self.keywords());
        });

        self.matchOptions = new MatchOptionsViewModel(self.woop());
        self.formData = new LeadFormDataViewModel(self.woop(), false, true);
        self.useFormData = ko.pureComputed(function() {
            return false;
        });

        self.compositionComplete = function() {
            console.log('comp complete');
            $('#applicationHost').css('overflow-y', 'hidden');
            setTimeout(function() { $('#keywords').focus(); }, 100);
        };

        self.activate = function() {
            self.referrer(state.newWoopReferrer());
            state.newWoopReferrer(null);
            state.selectedWoop(null);
            state.shellMode('customer');
        };
    };

    NewWoopPageViewModel.prototype = new BaseViewModel();
    return NewWoopPageViewModel;
});

