define('utils/config',['require'],function(require) {
	//return JSON.parse(atob(window._c));	
	return {
    "server": "https://woopan.com/api/index.php/",
    // "server": "https://woopan-api.lndo.site/",
    "oauth" : "oauth/",
    "api" : "api/v1/",
    "locale" : "nl",
    "client_id": "blits1",
    "client_secret": "blits@123",
    "updateInterval": 60,
    "autoLogoutInterval": 999999999,
    "rerouteToHash": false,
    "mobileMediaMatch":  "screen and (max-width: 1200px)"
	}
});
