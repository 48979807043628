define('viewmodels/components/create-pattern',['require','lodash','knockout','plugins/router','toastr','durandal/app','services/vendor','viewmodels/components/match-options','models/pattern','text!settings.json','viewmodels/mixins/busy','viewmodels/base','viewmodels/components/panda'],function(require) {
    var _ = require('lodash');
    var ko = require('knockout');
    var router = require('plugins/router');
    var toastr = require('toastr');
    var app = require('durandal/app');
    var vendorService = require('services/vendor');
    var MatchOptionsViewModel = require('viewmodels/components/match-options');
    var Pattern = require('models/pattern');
    var settings = JSON.parse(require('text!settings.json'));
    var BusyMixin = require('viewmodels/mixins/busy');
    var BaseViewModel = require('viewmodels/base');
    var PandaViewModel = require('viewmodels/components/panda');

    var CreatePatternViewModel = function(pattern, isNew) {
        var self = this;
        self.isNew = isNew;
        pattern = ko.validatedObservable(Pattern.clone(pattern));
        _.assign(self, pattern());
        _.assign(self, new BusyMixin());
        
        self.panda = new PandaViewModel(ko.observable(), ko.observable(), false);
        self.matchOptions = new MatchOptionsViewModel(pattern(), true);
        self.showExtras = ko.observable(false);
        self.moreOptions = function() {
            self.showExtras(true);
        };

        var showWarning = _.throttle(function() {
            toastr.warning('Gebruik een enkel woord per regel', 'Let op');
        }, settings.warningThrottle, { trailing: false });

        self.keywords.subscribe(function(keywords) {
            if (keywords.indexOf(' ') !== -1) keywords = keywords.replace(/\ /gi, '');
            if (keywords.indexOf('\t') !== -1) keywords = keywords.replace(/\t/gi, '');
            if (self.keywords() !== keywords) showWarning();
            self.keywords(keywords);
        });

        var addNewPattern = function() {
            self.isBusy(true);
            vendorService.addPattern(pattern().toDTO(), function(err, patternId) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
                toastr.success('Nieuwe aanbieding geplaatst', 'Opgeslagen');
                router.navigate('patterns/' + patternId + '/matches');
            });
        };

        var updatePattern = function() {
            self.isBusy(true);
            vendorService.updatePattern(pattern().id(), pattern().toDTO(), function(err, result) {
                self.isBusy(false);
                if (err) return toastr.error(err, 'Fout');
                toastr.success('Aanbieding is aangepast', 'Opgeslagen');
                router.navigate('patterns/' + pattern().id() + '/matches');
            });
        };

        self.save = function() {
            pattern().isValidating(true);
            if (pattern.isValid()) {
                if (isNew) addNewPattern();
                else updatePattern();
            }
            else {
                toastr.error('Niet alle velden zijn juist ingevuld', 'Fout');
            }
        };
    };

    CreatePatternViewModel.prototype = new BaseViewModel();
    return CreatePatternViewModel;
});

