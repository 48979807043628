define('services/user',['require','knockout','lodash','async','services/customer-api','services/vendor-api','services/customer','services/vendor','services/social-api','services/generic-api','services/state-container','models/woop','models/user','models/offer','utils/config'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
	var async = require('async');
    var customerApi = require('services/customer-api');
    var vendorApi = require('services/vendor-api');
    var customerService = require('services/customer');
    var vendorService = require('services/vendor');
    var socialService = require('services/social-api');
    var genericApi = require('services/generic-api');
    var state = require('services/state-container');
    var Woop = require('models/woop');
    var User = require('models/user');
    var Offer = require('models/offer');
    var config = require('utils/config');
    var withErrorParsing = genericApi.withErrorParsing;
    var parseError = genericApi.parseError;

    var UserService = function() {
        var self = this;
        self.updateTimer = null;
        self.logoutTimer = null;
        self.callbacks = {
            matches: _.noop,
            update: _.noop,
            logout: _.noop
        };

        var withLogoutOnError = function(callback) {
            return _.wrap(callback, function(func, err, data) {
                if (err) self.logout();
                func(err, data);
            });
        };

        self.loadUser = function(options, callback) {
            customerService.loadUser(function(err, data) {
                if (err) {
                    self.logout();
                    return callback(err);
                }
                if (state.user().isVendor()) {
                    //state.shellMode('vendor');
                    vendorService.loadUser(function(err, data) {
                        if (err) {
                            self.logout();
                            return callback(err);
                        }

                        // Called when all user info preloading is completed.
                        var done = function(err) {
                            if (err) {
                                self.logout();
                                return callback(err);
                            }
                            vendorService.loadMatches(self.callbacks.matches);
                            self.startTimers();
                            callback(null);
                        };

                        // If a pattern id is specified in the options, make sure this pattern is loaded before completing.
                        if (options.patternId) {
                            var pattern = state.findPatternById(options.patternId);
                            vendorService.loadMatchesForPattern(pattern, done);
                        }
                        else done();
                    });
                }
                else {
                    state.shellMode('customer');
                    self.startTimers();
                    callback(null);
                }
            });
        };

        self.logout = function() {
            state.session(null);
            state.clear();
            self.stopTimers();
        };

        self.signIn = function(params, callback, signInType) {
            var signInFunction = function(callback) { callback("Inloggen mislukt"); };
            if (params.emailAddress && params.password) {
                signInFunction = function(callback) {
                    customerApi.getToken({ username: params.emailAddress, password: params.password }, callback);
                };
            }
            else if (params.google) {
                signInFunction = function(callback) {
                    socialService.signInGoogleUser(params.queryString, callback);
                };
            }
            else if (params.facebook) {
                signInFunction = function(callback) {
                    socialService.signInFacebookUser(params.queryString, callback);
                };
            }

            async.waterfall(
                [
                    signInFunction,
                    function(data, callback) {
                        if (!data['access_token']) return callback('No access.');
                        state.session(data);
                        self.loadUser({}, callback);
                    }
                ],
                withErrorParsing(withLogoutOnError(callback))
            );
        };

        self.registerVendor = function(emailAddress, password, repeatPassword, keywords, targetKeyword, callback) {
            async.waterfall(
                [
                    function(callback) {
                        var data = {
                            emailAddress: emailAddress,
                            password: password,
                            password_confirmation: repeatPassword,
                            keywords: keywords,
                            targetKeyword: targetKeyword
                        };
                        vendorApi.addVendor(data, withErrorParsing(callback));
                    },
                    function(data, callback) {
                        self.signIn({ emailAddress : emailAddress, password : password }, callback);
                    },
                    function (data, callback) {
                        state.user().isUnregistered(false);
                        state.user().oldPassword(password);
                        callback();
                    }
                ],
                withErrorParsing(callback)
            );
        };

        self.verifyVendor = function(accessToken, callback) {
            async.waterfall(
                [
                    function(callback) {
                        vendorApi.verifyVendor({ access_token: accessToken }, callback);
                    }
                    // function(data, callback) {
                    //     state.session({ access_token: accessToken });
                    //     self.loadUser({}, callback, matchesCallback, updatesCallback);
                    // }
                ],
                withErrorParsing(callback)
            );
        };

        self.generateRandomPassword = function() {
            return _.range(0, 10).map(function() {
                var r = Math.floor(Math.random() * 36);
                if (r < 10) return r.toString();
                return String.fromCharCode((r-10)+97);
            }).join('');
        }


        self.registerCustomer = function(emailAddress, password, password_confirmation, keywords, targetKeyword, formData, maxPrice, callback) {

            async.waterfall(
                [
                    function(callback) {
                        var data = { 
                            emailAddress: emailAddress, 
                            password: password, 
                            password_confirmation: password_confirmation,
                            
                        };
                        customerApi.addCustomer(data, callback);
                    },
                    function(data, callback) {

                        if (!data['access_token']) return callback('No access.');
                        var woop = { keywords: keywords };
                        if (targetKeyword) woop.targetKeyword = targetKeyword;
                        if (formData) woop.formData = formData;
                        if (maxPrice) woop.maximumPrice = maxPrice;
                        state.session(data);
                        state.user().isUnregistered(false);
                        state.user().oldPassword(password);
                        customerService.addWoop(woop, callback);
                    },
                    function(data, callback) {
                        self.loadUser({}, callback);
                    },
                    function(callback) {
                        self.upgradeToVendor(function(err, result) {
                            if (err) return callback(err);
                            state.user().upgradeToVendor();
                            callback();
                        });                        
                    }
                ],
                withErrorParsing(callback)
            );
        };

        self.upgradeToVendor = function(callback) {
            async.series(
                [
                    function(callback) {
                        vendorService.updateProfile({ upgrade: true }, callback);
                    },
                    function(callback) {
                        state.user().upgradeToVendor();
                        vendorService.getPatterns(callback);
                    }
                ],
                function(err) {
                    vendorService.loadMatches(_.noop);
                    callback(parseError(err));
                }
            );
        };

        self.update = function(callback) {

            // Customer updates
            async.series(
                {
                    offersWereUpdated: _.bind(customerService.getOffers, customerService),
                    woopsWereUpdated: _.bind(customerService.getWoops, customerService)
                },
                function(err, customerResults) {
                    if (err) return callback(err);
                    if (!state.user().isVendor()) return callback(null, _.pick(customerResults, 'offersWereUpdated'));

                    // Vendor updates
                    async.series(
                        {
                            matchesWereUpdated: _.bind(vendorService.loadMatches, vendorService),
                            transactionUpdates: _.bind(vendorService.updateTransactionsIfNeeded, vendorService),
                            credits: _.bind(vendorService.getBalance, vendorService)
                        },
                        function(err, vendorResults) {
                            var result = {
                                offersWereUpdated: customerResults.offersWereUpdated,
                                matchesWereUpdated: vendorResults.matchesWereUpdated,
                                transactionsWereApproved: vendorResults.transactionUpdates ? vendorResults.transactionUpdates[0] : false,
                                transactionsWereAborted: vendorResults.transactionUpdates ? vendorResults.transactionUpdates[1] : false
                            };
                            return callback(parseError(err), result);
                        }
                    );
                }
            );
        };

        self.updatePassword = function(oldpass, newpass, callback) {
            var data = { old_password : oldpass, new_password : newpass };
            data['access_token'] = state.accessToken();
            data['customerId'] = state.session()['user_id'];

            customerApi.changePassword(data, function(err, result) {
                if (err) return callback(parseError(err));
                state.user().isNew(false);
                state.user().oldPassword(newpass);
                callback(null);
            });
        };

        self.forgotPassword = function(emailAddress, callback) {
            customerApi.forgotPassword({ username: emailAddress }, withErrorParsing(callback));
        };

        self.useForgotPasswordSession = function(accessToken) {
            state.forgotPasswordSession(accessToken);
        };

        self.createNewPassword = function(newpass, newpass_confirmed, callback) {
            var data = { access_token: state.forgotPasswordSession(), new_password: newpass, new_password_confirmation: newpass_confirmed };
            customerApi.createNewPassword(data, withErrorParsing(callback));
        };

        self.startTimers = function() {
            self.stopTimers();
            self.startUpdateTimer();
            self.startLogoutTimer();
        };

        self.startUpdateTimer = function() {
            self.updateTimer = setInterval(
                function() {
                    self.update(self.callbacks.update);
                },
                config.updateInterval * 1000);
        };

        self.startLogoutTimer = function() {
            // self.logoutTimer = setInterval(
            //     function() {
            //         self.logout();
            //         self.callbacks.logout();
            //     },
            //     config.autoLogoutInterval * 1000
            // );
        };

        self.stopTimers = function() {
            self.stopUpdateTimer();
            self.stopLogoutTimer();
        };

        self.stopUpdateTimer = function() {
            if (self.updateTimer) {
                clearInterval(self.updateTimer);
                self.updateTimer = null;
            }
        };

        self.stopLogoutTimer = function() {
            // if (self.logoutTimer) {
            //     clearInterval(self.logoutTimer);
            //     self.logoutTimer = null;
            // }
        };

        self.keepAlive = function() {
            if (state.session()) {
                self.stopLogoutTimer();
                self.startLogoutTimer();
            }
        };

        self.registerCallbacks = function(callbacks) {
            self.callbacks = _.merge(self.callbacks, callbacks);
        };
    };

    return new UserService();
});

