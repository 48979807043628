requirejs.config({
    paths: {
        'text': '../lib/require/text',
        'async': '../lib/async/async',
        'durandal':'../lib/durandal/js',
        'plugins' : '../lib/durandal/js/plugins',
        'transitions' : '../lib/durandal/js/transitions',
        'knockout': '../lib/knockout/knockout-3.3.0',
        'knockout.validation': '../lib/knockout/knockout.validation-2.3.0',
        'perfect-scrollbar': '../lib/perfect-scrollbar/perfect-scrollbar',
        'nanoscroller': '../lib/nanoscroller/nanoscroller',
        'jquery': '../lib/jquery/jquery-1.9.1',
        'lodash': '../lib/lodash/lodash-3.10.1',
        'toastr': '../lib/toastr/toastr',
        'dropzone': '../lib/dropzone/dropzone',
    },
    shim: {
       'bootstrap': {
            deps: ['jquery'],
            exports: 'jQuery'
       },
       'knockout.validation': {
           deps: ['knockout']
       }
    }
});

define('bootstrap',['require','./main'],function(require) {
    require('./main');
}); 
