define('models/woop',['require','knockout','lodash','utils/parsing','utils/formatting','models/validation'],function(require) {
    var ko = require('knockout');
    var _ = require('lodash');
    var parsing = require('utils/parsing');
    var formatting = require('utils/formatting');
    var validation = require('models/validation');

    var Woop = function() {
        var self = this;
        self.isValidating = ko.observable(false);
        self.id = ko.observable();
        self.keywords = ko.observable();
        self.formData = ko.observable();
        self.date = ko.observable();
        self.offerCount = ko.observable(0);
        self.newOfferCount = ko.observable(0);
        self.isArchived = ko.observable(false);
        self.maxPrice = ko.observable().extend(validation.price(self.isValidating));
        self.maxDistance = ko.observable();
        self.postalCode = ko.observable();
        self.showPhoneNumber = ko.observable(false);
        self.useLocation = ko.observable(false);

        self.fromWoop = function(woop) {
            self.id(woop.id());
            self.keywords(woop.keywords());
            self.formData(woop.formData());
            self.date(woop.date());
            self.offerCount(woop.offerCount());
            self.newOfferCount(woop.newOfferCount());
            self.isArchived(woop.isArchived());
            self.maxPrice(formatting.priceValue(woop.maxPrice()));
            self.maxDistance(woop.maxDistance());
            self.postalCode(woop.postalCode());
            self.showPhoneNumber(woop.showPhoneNumber());
            self.useLocation(woop.useLocation());
        };

        self.toDTO = ko.pureComputed(function() {
            return {
                id: self.id(),
                keywords: self.keywords(),
                formData: self.formData(),
                offerCount: self.offerCount(),
                newOfferCount: self.newOfferCount(),
                isArchived: self.isArchived(),
                maximumPrice: formatting.priceValue(self.maxPrice()),
                locationRadius: self.maxDistance(),
                postalCode: self.postalCode(),
                showPhone: self.showPhoneNumber(),
                useLocation: self.useLocation()
            };
        });
    };

    Woop.clone = function(woop) {
        var clonedWoop = new Woop();
        clonedWoop.fromWoop(woop);
        return clonedWoop;
    };

    Woop.createNew = function(keywords, targetKeyword, formData) {
        var woop = new Woop();
        woop.keywords(keywords);
        woop.formData(formData);
        woop.date(new Date());
        return woop;
    };

    Woop.fromDTO = function(dto) {
        var woop = new Woop();
        woop.id(dto.id);
        woop.keywords(dto.keywords);
        woop.formData(dto.formData);
        woop.date(dto.timestamp ? new Date(dto.timestamp*1000) : parsing.date(dto.created_at));
        woop.offerCount(dto.offers);
        woop.newOfferCount(dto.offersNew);
        woop.isArchived(dto.isArchived);
        woop.maxPrice(formatting.priceValue(dto.maximumPrice));
        woop.maxDistance(dto.locationRadius);
        woop.postalCode(dto.postalCode);
        woop.showPhoneNumber(dto.showPhone);
        woop.useLocation(dto.useLocation);
        return woop;
    };

    return Woop;
});

