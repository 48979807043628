define('viewmodels/dialogs/new-woop-dialog',['require','knockout','durandal/app','plugins/dialog','utils/string','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var app = require('durandal/app');
    var dialog = require('plugins/dialog');
    var stringUtils = require('utils/string');
    var BaseViewModel = require('viewmodels/base');

    var NewWoopDialog = function() {
        var self = this;
        self.autoclose = false;

        self.keywords = ko.observable(null);

        self.activate = function(options) {
            _.assign(self, options);
        };

        self.go = function() {
            dialog.close(self);
            self.callback(self.keywords());
        };

        self.isGoButtonEnabled = ko.pureComputed(function() {
            if (stringUtils.isEmpty(self.keywords())) return false;
            return true;
        });

        self.blockoutClass = 'white';
    };

    NewWoopDialog.prototype = new BaseViewModel();
    return NewWoopDialog;
});

