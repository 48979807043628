define('main',['require','knockout','jquery','lodash','async','nanoscroller','toastr','durandal/system','durandal/app','durandal/viewLocator','plugins/router','services/state-container','services/user','services/cms-api','services/social-api','utils/toast-callbacks','utils/config','services/generic-api','viewmodels/base'],function(require) {
    var ko = require('knockout');
    var $ = require('jquery');
    var _ = require('lodash');
    var async = require('async');
    require('nanoscroller');
    var toastr = require('toastr');
    var system = require('durandal/system');
    var app = require('durandal/app');
    var viewLocator = require('durandal/viewLocator');
    var router = require('plugins/router');
    var state = require('services/state-container');
    var userService = require('services/user');
    var cmsService = require('services/cms-api');
    var socialService = require('services/social-api');
    var toastCallbacks = require('utils/toast-callbacks');
    var config = require('utils/config');
    var genericApi = require('services/generic-api');
    var parseError = genericApi.parseError;
    var BaseViewModel = require('viewmodels/base');

    toastr.options = _.assign(toastr.options, {
        timeOut: 2400,
        closeButton: true,
        closeMethod: 'fadeOut',
        hideDuration: 400,
        hideEasing: 'swing',
        positionClass: 'toast-top-right'
    });

    var mq = window.matchMedia(config.mobileMediaMatch);
    if (mq.matches) { toastr.options.positionClass = 'toast-bottom-center'; }

    if (config.rerouteToHash && location.pathname !== '/') {
        var route = location.pathname;
        if (route[0] === '/') route = route.substring(1);
        document.location = '/#' + route;
        return;
    }

    //>>excludeStart("build", true);
    system.debug(true);
    //>>excludeEnd("build");

    app.title = 'WOOPAN';

    app.configurePlugins({
        router: true,
        dialog: true
    });

    var startApp = function() {
        app.start().then(function() {
            viewLocator.useConvention();
            app.setRoot('viewmodels/shell');
        });
    };

    userService.registerCallbacks({
        update: toastCallbacks.update,
        matches: toastCallbacks.matches,
        logout: function() {
            router.navigate('/');
            toastr.info('Je bent automatisch uitgelogd');
        }
    });

    var googleCallback = function(err, data) {
        if (data.message){
            state.authUrls['google'] = data.message;
        }
    };
    var fbCallback = function(err, data) {
        if (data.message){
            state.authUrls['facebook'] = data.message;
        }
    };
    var cmsCallback = function(data, data2) {
        if (data2) {
            state.cmsTexts = data2;
            console.log('+++customer');
            state.shellMode('customer');
            if (state.isLoggedIn()) {
                $('#logging-in').show();
                var options = { };
                var patternMatch = /\#patterns\/(\d+)\/matches\/(\d+)/.exec(window.location.hash);
                if (patternMatch) options.patternId = _.parseInt(patternMatch[1]);
                userService.loadUser(
                    options,
                    function(err) {
                        $('#logging-in').hide();
                        if (err) {
                            toastr.error(err, 'Fout');
                            userService.logout();
                            startApp();
                            return;
                        }
                        toastr.success('Je bent ingelogd');
                        startApp();
                    }
                );
            }
            else {
                startApp();
            }
        }
        else {
            toastr.error("Kon teksten niet downloaden", 'Fout');
        }
    };

    async.waterfall(
        [
            function(googleCallback) {
                socialService.getGoogleAuthenticationUrl(googleCallback);
            },
            function(data, fbCallback) {
                googleCallback(null, data);
                socialService.getFacebookAuthenticationUrl(fbCallback);
            },
            function(data, cmsCallback) {
                fbCallback(null, data);
                cmsService.getTexts(cmsCallback);
            },
            function(data) {
                cmsCallback(null, data);
            }
        ],
        function(err) {
            toastr.error(parseError(err));
        }
    );

});

