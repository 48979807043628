/* Woopan API Vendor Service */
define('services/vendor-api',['require','utils/config','services/generic-api'],function(require) {

    var config = require('utils/config');
    var genericApi = require('services/generic-api');

    return {

    	oauth: config.oauth,
    	api: config.api,

    	/* Register a brand new vendor directly */
		addVendor: function(data, callback) {
			var required_keys = ['password', 'password_confirmation', 'emailAddress'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			data['client_id'] = config.client_id;
 			data['client_secret'] = config.client_secret;
			genericApi.doRequest('POST', config.server + this.api + "vendors", data, callback);
		},

    	/* Verify a newly created vendor. */
    	verifyVendor: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('POST', config.server + this.api + "verify", data, callback);
		},

    	/* Update profile. */
		updateProfile: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('PUT', config.server + this.api + "vendors", data, callback);
		},

		/* Get profile */
        getMyProfile: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('GET', config.server + this.api + "vendors/myprofile?access_token="+data['access_token'], null, callback);
        },

        /* Get my patterns */
        getPatterns: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('GET', config.server + this.api + "vendors/patterns?access_token="+data['access_token'], null, callback);
        },

        /* Get my offers (posted by me as a vendor) */
    	getOffers: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('GET', config.server + this.api + "vendors/offers?access_token="+data['access_token'], null, callback);
		},

		/* Add pattern */
        addPattern: function(data, callback) {
			var required_keys = ['access_token', 'name', 'keywords'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('POST', config.server + this.api + "vendors/patterns", data, callback);
        },

		/* Update pattern */
        updatePattern: function(data, callback) {
			var required_keys = ['access_token', 'name', 'keywords', 'patternId'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			var patternId = data['patternId'];
			delete data['patternId'];
			genericApi.doRequest('PUT', config.server + this.api + "vendors/patterns/"+patternId, data, callback);
        },

		/* Remove pattern */
        removePattern: function(data, callback) {
			var required_keys = ['access_token', 'patternId'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			var patternId = data['patternId'];
			delete data['patternId'];
			genericApi.doRequest('DELETE', config.server + this.api + "vendors/patterns/"+patternId, data, callback);
        },

		/* Restore pattern */
        restorePattern: function(data, callback) {
			var required_keys = ['access_token', 'patternId'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			var patternId = data['patternId'];
			delete data['patternId'];
			genericApi.doRequest('POST', config.server + this.api + "vendors/patterns/restore/"+patternId, data, callback);
        },

//         Route::post('vendors/offers', 'OfferController@store');
		/* Add an offer */
		/* Required: lead_id, content */
        addOffer: function(data, callback) {
			var required_keys = ['access_token', 'lead_id', 'content'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('POST', config.server + this.api + "vendors/offers", data, callback);
        },

//        Route::delete('vendors/offers/{offer_id}', 'OfferController@destroy');
		/* Remove an offer */
		/* required: offerId */
        removeOffer: function(data, callback) {
			var required_keys = ['access_token', 'offerId'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			var offerId = data['offerId'];
			delete data['offerId'];
			genericApi.doRequest('DELETE', config.server + this.api + "vendors/offers/"+offerId, data, callback);
        },


//         Route::match(['get', 'post'], 'vendors/leads', 'LeadController@search');
		/* Search leads */
		/* Required: keywords */
        searchLeads: function(data, callback) {
			var required_keys = ['access_token', 'keywords'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('POST', config.server + this.api + "vendors/leads", data, callback);
        },

//         Route::post('vendors/leads/buy/{lead_id}', 'LeadController@buyLead');
		/* Buy a lead */
        buyLead: function(data, callback) {
			var required_keys = ['access_token', 'leadId'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			var leadId = data['leadId'];
			delete data['leadId'];
			genericApi.doRequest('POST', config.server + this.api + "vendors/leads/buy/"+leadId, data, callback);
        },

		/* Buy credits
		Possible additional parameters: leadId & patternId (for routing to specific buying page) */
        buyCredits: function(data, callback) {
			var required_keys = ['access_token', 'credits'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('POST', config.server + this.api + "vendors/credits/buy", data, callback);
        },

        /* Get credit balance */
        getBalance: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('GET', config.server + this.api + "vendors/credits/balance?access_token="+data['access_token'], data, callback);
        },

        /* Get transaction state by ID */
        getTransactionStatus: function(data, callback) {
			var required_keys = ['access_token', 'transactionId'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
 			var transactionId = data['transactionId'];
			delete data['transactionId'];
			genericApi.doRequest('GET', config.server + this.api + "vendors/transactions/"+transactionId, data, callback);
        },

        getTransactions: function(data, callback) {
			var required_keys = ['access_token'];
 			if ( !genericApi.keysExist(required_keys, data) ) { return false; }
			genericApi.doRequest('GET', config.server + this.api + "vendors/transactions?access_token="+data['access_token'], data, callback);
        },

        getOfferPicUploadOptions: function() {
            return {
                url: config.server + this.api + 'vendors/offerpics',
                headers: { "access_token": "test_access_token" }
            };
        },



//         Route::post('vendors/reviews/customer', 'VendorController@reviewCustomer');
		/* Review customer */
        reviewCustomer: function(data, callback) {

        },


    	};

});

